import store from 'store/store';
import { privateClient } from 'helpers/client';
import { toLocaleString } from 'helpers/translator';
import { ThunkResult } from 'constants/commonTypes';
import { StoreActionType } from 'constants/storeActionType';
import { naturalSort } from 'helpers/naturalSort';
import { EGroupSubTab } from 'constants/commonEnum';
import { commonHelpers } from 'helpers/commonHelpers';

import {
  setShowModal,
  setLoading,
  setNewNotification,
} from './othersController';

export const getGroups =
  (domain: string): ThunkResult<void> =>
  (dispatch, getState, { api }) => {
    const { currentDomain } = getState().others;

    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups`)
      .then((response) => {
        const data = response.map((el: any) => ({
          ...el,
          users: {
            users: commonHelpers.findTitleDuplicatesAndFix(
              el.users.users,
              currentDomain,
            ),
          },
        }));

        dispatch({
          type: StoreActionType.PUSH_GROUPS,
          data: naturalSort(data, 'title'),
          domain,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getGroups error', error));
  };

export const getGroupGeofencingConfig =
  (
    domain: string,
    groupName: string,
    successCallBack: Function = () => {},
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/${groupName}/geofencing`)
      .then((response) => {
        dispatch({
          type: StoreActionType.PUSH_GEOFENCING_CONFIG,
          data: response,
          domain,
          groupName,
        });

        return response;
      })
      .then((response) => {
        if (response) {
          successCallBack(response);
        }
      });
  };

export const getGroupAdvancedSettings =
  (domain: string, groupName: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/${groupName}/channel`)
      .then((response) => {
        dispatch({
          type: StoreActionType.PUSH_GROUP_ADVANCED_SETTINGS,
          payload: {
            data: response,
            domain,
            groupName,
          },
        });

        return response;
      });
  };

export const createGroup = (
  domain: string,
  data: object | string | undefined,
  successCallBack?: Function,
  users?: string[],
  name?: any,
  finallyCallback?: Function,
): ThunkResult<void> => {
  if (!data) {
    data = store.getState().defValues.group_values;
    // @ts-ignore
    data.users = { users };
    // @ts-ignore
    data.title = name;
  }

  return (dispatch, _, { api }) => {
    privateClient
      .post(`${api}/api/v1/domains/${domain}/groups`, data)
      .then(() => {
        dispatch(getGroups(domain));
        if (successCallBack) {
          successCallBack();
        }
        dispatch(
          setNewNotification('success', toLocaleString('group_create_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('createGroup error', error);
        dispatch(
          setNewNotification('error', toLocaleString('group_create_error')),
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
        if (finallyCallback) {
          finallyCallback();
        }
      });
  };
};

export const updateGroup =
  (
    domain: string,
    currentGroup: string,
    currentGroupBody: object,
    subTab: EGroupSubTab,
    successCallBack?: Function,
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    const apiPutPath = () => {
      switch (subTab) {
        case EGroupSubTab.GENERAL:
          return `${api}/api/v1/domains/${domain}/groups/${currentGroup}`;

        case EGroupSubTab.GEOFENCING:
          return `${api}/api/v1/domains/${domain}/groups/${currentGroup}/geofencing`;

        case EGroupSubTab.ADVANCED_SETTINGS:
          return `${api}/api/v1/domains/${domain}/groups/${currentGroup}/channel`;

        default:
          return `${api}/api/v1/domains/${domain}/groups/${currentGroup}`;
      }
    };

    privateClient
      .put(apiPutPath(), currentGroupBody)
      .then(() => {
        if (subTab === EGroupSubTab.ADVANCED_SETTINGS) {
          dispatch(getGroupAdvancedSettings(domain, currentGroup));
        } else {
          dispatch(getGroups(domain));
        }

        if (successCallBack) {
          successCallBack();
        }
        dispatch(
          setNewNotification('success', toLocaleString('group_update_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('updateGroup error', error);
        dispatch(
          setNewNotification('error', toLocaleString('group_update_error')),
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };

export const deleteGroup = (
  group: any,
  domain: string,
  successCallback: Function = () => {},
): ThunkResult<void> => {
  const groupName = group.key.replace('sip:', '').replace(`@${domain}`, '');

  return (dispatch, _, { api }) => {
    privateClient
      .delete(`${api}/api/v1/domains/${domain}/groups/${groupName}`)
      .then(() => {
        dispatch({
          type: StoreActionType.DELETE_GROUP,
          name: group.key,
          domain,
        });
        successCallback();
        dispatch(
          setNewNotification('success', toLocaleString('group_delete_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('deleteGroup error', error);
        dispatch(
          setNewNotification('error', toLocaleString('group_delete_error')),
        );
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };
};

export const getGroupValues =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/default`)
      .then((response) => {
        dispatch({
          type: StoreActionType.SET_GROUP_VALUES,
          domain,
          data: response,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getGroupValues error', error));
  };

export const getGroupScheme =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/scheme`)
      .then((scheme) => {
        dispatch({ type: StoreActionType.SET_SCHEME_GROUP, domain, scheme });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getGroupScheme error', error));
  };

export const getGroupGeofencingScheme =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/geofencing/scheme`)
      .then((scheme) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_GROUP_GEOFENCING,
          domain,
          scheme,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getGroupGeofencingScheme error', error));
  };

export const getGroupAdvancedSettingsScheme =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/groups/channel/scheme`)
      .then((scheme) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_GROUP_ADVANCED_SETTINGS,
          payload: {
            domain,
            scheme,
          },
        });
      })
      .catch((error) =>
        // eslint-disable-next-line no-console
        console.error('getGroupAdvancedSettingsScheme error', error),
      );
  };

export const resetGroups = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_GROUPS });
};
