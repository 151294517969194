import { AnyAction } from 'redux';
import { List, Map } from 'immutable';
import { EConnection, IWSUserData } from 'constants/interfaces/IUsers';
import { cloneDeep } from 'lodash';
import { StoreActionType } from 'constants/storeActionType';
import { Config } from '../domains';

export type StoreUsers = Map<string, List<User>>;

export interface UserInfo {
  userId: string;
  displayName: string;
  deleted: boolean;
  enabled: boolean;
}

export interface User {
  blocked: boolean;
  configs?: Map<'mcdata' | 'mcptt' | 'mcvideo', Config | undefined>;
  enabled: boolean;
  fleets: {
    name: string;
    title: string;
  }[];
  groups?: {
    name: string;
    title: string;
  }[];
  name: string;
  login: string;
  online: boolean;
  roles?: string[];
  title: string;
  mcsId?: string;
  user_id?: number;
}

export interface GroupUser {
  mcdataMaxDataInSingleRequest: number | null;
  mcdataMaxTimeInSingleRequest: string | null;
  mcvideoOnNetworkRequired: boolean | null;
  onNetworkRequired: boolean | null;
  multiTalkerAllowed: boolean | null;
  allowStoreGroupCommInMsgstore: boolean | null;
  priority: number | null;
  receptionPriority: number | null;
  title?: string;
  username: string;
}

const initialState = (): Map<string, List<User>> => Map({});

interface PushUsers extends AnyAction {
  type: StoreActionType.PUSH_USERS;
  domain: string;
  users: User[];
}

interface AddUser extends AnyAction {
  type: StoreActionType.ADD_USER;
  domain: string;
  user: User;
}

interface AddUserGroups extends AnyAction {
  type: StoreActionType.ADD_USER_GROUPS;
  domain: string;
  name: string;
  groups: {
    name: string;
    title: string;
  }[];
}

interface UpdateOnlineStatus extends AnyAction {
  type: StoreActionType.UPDATE_ONLINE_STATUS;
  domain: string;
  userDataWS: IWSUserData[];
}

interface UpdateUser extends AnyAction {
  type: StoreActionType.UPDATE_USER;
  domain: string;
  user: User;
}

interface DeleteUser extends AnyAction {
  type: StoreActionType.DELETE_USER;
  domain: string;
  name: string;
}

interface AddConfig extends AnyAction {
  type: StoreActionType.ADD_CONFIG;
  domain: string;
  name: string;
  mode: string;
  data: Config | string;
}

interface UpdateConfig extends AnyAction {
  type: StoreActionType.USERS_UPDATE_CONFIG;
  domain: string;
  name: string;
  mode: string;
}

interface ClearUsersConfigScheme extends AnyAction {
  type: StoreActionType.CLEAR_USERS_CONFIG_SCHEME;
}

interface ResetUsers extends AnyAction {
  type: StoreActionType.RESET_USERS;
}

export type StoreUsersActions =
  | ClearUsersConfigScheme
  | PushUsers
  | AddUser
  | UpdateOnlineStatus
  | UpdateUser
  | DeleteUser
  | AddConfig
  | UpdateConfig
  | AddUserGroups
  | ResetUsers;

export default function users(
  store: StoreUsers = initialState(),
  action: StoreUsersActions,
) {
  switch (action.type) {
    case StoreActionType.PUSH_USERS: {
      return store.set(action.domain, List(action.users));
    }

    case StoreActionType.ADD_USER: {
      const section = store.get(action.domain);
      if (section) {
        return store.set(action.domain, section.push(action.user));
      }
      return store.set(action.domain, List([action.user]));
    }

    case StoreActionType.ADD_USER_GROUPS: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex((value) => value.name === action.name);
        if (index !== -1) {
          if (action.groups) {
            return store.setIn([action.domain, index, 'groups'], action.groups);
          }
          return store;
        }
        return store;
      }
      return store;
    }

    case StoreActionType.UPDATE_ONLINE_STATUS: {
      const section = store.get(action.domain);

      if (section) {
        action.userDataWS.forEach((dataWS) => {
          const index = section.findIndex(
            (item) => item.mcsId === dataWS.userId,
          );
          const user = section.get(index);

          if (index !== -1 && user) {
            user.online = dataWS.connectionState === EConnection.connected;
            store.setIn([action.domain, index], user);
          }
        });

        section.find((user) => user.mcsId === action.user);

        return cloneDeep(store);
      }

      return store;
    }

    case StoreActionType.UPDATE_USER: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findIndex(
          (value) => value.name === action.user.name,
        );

        if (index !== -1) {
          if (action.user) {
            return store.setIn([action.domain, index], action.user);
          }
          return store;
        }
      }
      return store;
    }

    case StoreActionType.DELETE_USER: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex((value) => value.name === action.name);
        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }
        return store;
      }
      return store;
    }

    case StoreActionType.ADD_CONFIG: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex((value) => value.name === action.name);
        if (index !== -1) {
          if (action.mode) {
            return store.setIn(
              [action.domain, index, 'configs', action.mode],
              action.data,
            );
          }
          return store;
        }
        return store;
      }
      return store;
    }

    case StoreActionType.USERS_UPDATE_CONFIG: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex((value) => value.name === action.name);

        if (index !== -1) {
          return store.setIn(
            [action.domain, index, 'configs', action.mode, 'value'],
            action.data,
          );
        }
        return store;
      }
      return store;
    }

    case StoreActionType.CLEAR_USERS_CONFIG_SCHEME: {
      return store.map((usersDomain) =>
        usersDomain.map((user) => {
          const { configs, ...rest } = user;

          return {
            ...rest,
            configs: configs
              ? Map(configs).map((userConfig) => ({
                  ...userConfig,
                  scheme: undefined,
                }))
              : undefined,
          };
        }),
      );
    }

    case StoreActionType.RESET_USERS: {
      return initialState();
    }

    default: {
      return store;
    }
  }
}
