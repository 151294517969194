import { AnyAction } from 'redux';
import { List, Map } from 'immutable';
import {
  GroupAdvancedSettings,
  IGroupGeofencing,
} from 'constants/interfaces/IGroup';
import { StoreActionType } from 'constants/storeActionType';
import { GroupUser } from 'store/users';
import { Status } from 'store/statuses';
import { LMRConfiguration } from 'constants/interfaces/ILMRGatewys';

const advancedSettingsField = 'advancedSettings';
export type StoreGroups = Map<string, List<IGroup>>;

export interface IGroup {
  name: string;
  title: string;
  users: {
    users: GroupUser[];
  };
  statuses?: {
    dataAllowEnhancedStatus: boolean;
    statuses: Status[];
  };
  [key: string]: any;
  geofencing?: IGroupGeofencing;
  advancedSettings?: GroupAdvancedSettings;
}

interface CreateGroup extends AnyAction {
  type: StoreActionType.CREATE_GROUP;
  domain: string;
  data: IGroup;
}

interface PushGroups extends AnyAction {
  type: StoreActionType.PUSH_GROUPS;
  domain: string;
  data: IGroup[];
}

interface PushGeofencingConfig extends AnyAction {
  type: StoreActionType.PUSH_GEOFENCING_CONFIG;
  data: [];
  domain: string;
  groupName: string;
}

interface PushAdvancedSettings extends AnyAction {
  type: StoreActionType.PUSH_GROUP_ADVANCED_SETTINGS;
  payload: {
    data: LMRConfiguration;
    domain: string;
    groupName: string;
  };
}

interface DeleteGroup extends AnyAction {
  type: StoreActionType.DELETE_GROUP;
  domain: string;
  name: string;
}

interface UpdateGroup extends AnyAction {
  type: StoreActionType.UPDATE_GROUP;
  domain: string;
  data: IGroup;
  name: string;
}

interface ResetIGroups extends AnyAction {
  type: StoreActionType.RESET_GROUPS;
}

export type StoreGroupsActions =
  | CreateGroup
  | PushGroups
  | PushGeofencingConfig
  | PushAdvancedSettings
  | DeleteGroup
  | UpdateGroup
  | ResetIGroups;

const initialState = (): Map<string, List<IGroup>> => Map({});

export default function IGroups(
  store: StoreGroups = initialState(),
  action: StoreGroupsActions,
) {
  switch (action.type) {
    case StoreActionType.CREATE_GROUP: {
      const section = store.get(action.domain);
      if (section) {
        return store.set(action.domain, section.push(action.data));
      }
      return store.set(action.domain, List([action.data]));
    }

    case StoreActionType.PUSH_GROUPS: {
      return store.set(action.domain, List(action.data));
    }

    case StoreActionType.UPDATE_GROUP: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.name === action.name);

        if (index !== -1) {
          return store.setIn([action.domain, index], action.data);
        }
      }
      return store;
    }

    case StoreActionType.PUSH_GEOFENCING_CONFIG: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.name === action.groupName);

        if (index !== -1) {
          return store.setIn([action.domain, index, 'geofencing'], action.data);
        }
      }

      return store;
    }

    case StoreActionType.PUSH_GROUP_ADVANCED_SETTINGS: {
      const section = store.get(action.payload.domain);

      if (section) {
        const index = section.findKey(
          (item) => item.name === action.payload.groupName,
        );

        if (index !== -1) {
          return store.setIn(
            [action.payload.domain, index, advancedSettingsField],
            action.payload.data,
          );
        }
      }

      return store;
    }

    case StoreActionType.DELETE_GROUP: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.name === action.name);
        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }
        return store;
      }
      return store;
    }

    case StoreActionType.RESET_GROUPS: {
      return initialState();
    }

    default: {
      return store;
    }
  }
}
