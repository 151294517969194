import {
  getDefaultFeatureCssProperties,
  IGeofenceData,
  MapToolsAdvancedFeatureType,
  MapToolsFeatureTypeEnum,
  WPGT,
} from 'constants/MapBox/MapBox';
import { toLocaleString } from 'helpers/translator';
import { cloneDeep } from 'lodash';
import { EWaypointIcons } from '.';

export const mapLineStringToWaypointMapper = (
  feature: IGeofenceData,
  points?: WPGT.Point[],
) => {
  const { waypointStyles } = feature.properties;
  const waypoint: WPGT.WaypointModel = {
    id: feature.id,
    line: {
      latlngs: feature?.geometry?.coordinates?.map((coord: any) => ({
        lat: coord[1],
        lng: coord[0],
      })),
      name: feature.name ?? toLocaleString('new_waypoint'),
      style: {
        className: '',
        clickable: false,
        color: '#000', // not needed
        dashArray: null,
        fill: false,
        fillColor: waypointStyles?.stroke ?? '#e41d1a',
        fillOpacity:
          waypointStyles?.fillOpacity ??
          getDefaultFeatureCssProperties().cssProperties.fillOpacity,
        lineCap: null,
        lineJoin: null,
        opacity: 0.5,
        pointerEvents: null,
        stroke: true,
        weight: waypointStyles?.strokeWidth ?? 5,
      },
    },
    points: feature.geometry.coordinates.map((coords: any, index: number) =>
      getWaypointPointModel(points?.[index], coords, index),
    ),
  };

  return waypoint;
};

export const getWaypointPointModel = (
  point: WPGT.Point | undefined,
  coords: any,
  index: number,
) => ({
  pointNum: index + 1,
  pointCircleRadius: 0.0,
  pointCircleStyle: {
    className: 'class',
    clickable: false,
    color: point?.pointCircleStyle?.color ?? '#090',
    dashArray: '3',
    fill: true,
    fillColor: point?.pointCircleStyle?.fillColor ?? '#50E3C2',
    fillOpacity: point?.pointCircleStyle?.fillOpacity ?? 1,
    lineCap: null,
    lineJoin: null,
    pointerEvents: null,
    opacity: 1,
    stroke: true,
    weight: 1.0,
  },
  pointDetail:
    point?.pointDetail ?? `${toLocaleString('point_detail')} #${index + 1}`,
  pointFill: point?.pointFill ?? '#fff',
  pointIcon: {
    color: point?.pointIcon?.color ?? '#fff',
    html: point?.pointIcon?.html ?? EWaypointIcons.IC1,
    iconAnchor: {
      x: 12.0,
      y: 12.0,
    },
    iconSize: {
      x: 24.0,
      y: 24.0,
    },
    popupAnchor: null,
  },
  pointLabel: '',
  pointLatLng: {
    lat: coords[1],
    lng: coords[0],
  },
  pointName:
    point?.pointName ?? `${toLocaleString('point_label')} #${index + 1}`,
  pointStyle: {
    className: '',
    clickable: false,
    color: '#e41d1a',
    fill: true,
    fillColor: '#e41d1a',
    fillOpacity: point?.pointStyle?.fillOpacity ?? 1,
    lineCap: null,
    lineJoin: null,
    opacity: 1,
    pointerEvents: null,
    stroke: false,
    weight: point?.pointStyle?.weight ?? 5.0,
  },
});

export const waypointToMapLineStringMapper = (wp: WPGT.WaypointModel) =>
  ((waypoint) => {
    try {
      // @ts-ignore
      waypoint.line = JSON.parse(waypoint.line);
      // @ts-ignore
      waypoint.points = JSON.parse(waypoint.points);
    } catch {
      //
    }

    const mapFeature: IGeofenceData = {
      id: waypoint.id ?? 0,
      name: waypoint.line.name,
      geometry: {
        coordinates: waypoint.line?.latlngs?.map((el) => [el.lng, el.lat]),
        type: MapToolsFeatureTypeEnum.LineString,
      },
      advancedType: MapToolsAdvancedFeatureType.Waypoint,
      properties: {
        advancedType: MapToolsAdvancedFeatureType.Waypoint,
        pointIndexToMove: null,
        cssStyles: {
          stroke: waypoint.line.style.fillColor,
          strokeWidth: waypoint.line.style.weight,
          strokeOpacity: 0,
          fill: 'none',
        },
        waypointStyles: {
          stroke: waypoint.line.style.fillColor,
          fillOpacity: waypoint.line.style.fillOpacity,
          strokeWidth: waypoint.line.style.weight,
          points: waypoint.points,
        },
      },
    };

    return mapFeature;
  })(cloneDeep(wp));
