import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en/translation.json';
import de from './de/translation.json';
import es from './es/translation.json';
import ar from './ar/translation.json';
import fr from './fr/translation.json';

export enum Language {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  AR = 'ar',
  FR = 'fr',
}

i18next.use(initReactI18next).init({
  lng: Language.EN,
  fallbackLng: Language.EN,
  resources: {
    [Language.EN]: {
      translation: en,
    },
    [Language.DE]: {
      translation: de,
    },
    [Language.ES]: {
      translation: es,
    },
    [Language.AR]: {
      translation: ar,
    },
    [Language.FR]: {
      translation: fr,
    },
  },
});
