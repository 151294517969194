import { List, ListIterator, Many, orderBy } from 'lodash';

type Orders = Many<'asc' | 'desc'>;

/**
 * For sorting array and the object properties based lib lodash {@link https://github.com/lodash/lodash/blob/main/src/orderBy.ts | _.orderBy}.
 * If orders is unspecified, all values are sorted in ascending order.
 * Otherwise, specify an order of "desc" for descending or "asc" for ascending sort order of corresponding values.
 *
 * @example
 * const users = [
 *  { 'user': 'fred',   'age': 48 },
 *  { 'user': 'barney', 'age': 34 },
 *  { 'user': 'fred',   'age': 40 },
 *  { 'user': 'barney', 'age': 36 }
 * ];
 * // Sort by `user` in ascending order and by `age` in descending order.
 * naturalSort(users, ['user', 'age'], ['asc', 'desc']);
 * // => objects for [['barney', 36], ['barney', 34], ['fred', 48], ['fred', 40]]
 */
function naturalSort<T>(
  array: List<T>,
  iterators: Many<ListIterator<T, keyof T> | keyof T> | undefined,
  orders?: Many<boolean | 'asc' | 'desc'> | undefined,
) {
  let currentOrders: Orders;

  if (typeof orders === 'boolean') {
    currentOrders = orders ? 'desc' : 'asc';
  } else {
    currentOrders = orders as Orders;
  }

  return orderBy<T>(array, iterators, currentOrders);
}

export { naturalSort };