import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserInfo,
  setCurrentUser,
  setLocale,
} from 'store/actions/userController';
import jwt_decode from 'jwt-decode';
import { getBrowserLanguage, toLocaleString } from 'helpers/translator';
import PrivateRoute from './components/PrivateRoute';
import Notification from './components/Notification/Notification';
import './static/css/variables.css';

// Containers
const Index = React.lazy(() => import('pages/Index'));

// Pages
const Token = React.lazy(() => import('pages/Token'));
const Page404 = React.lazy(() => import('pages/Page404'));
const Forbidden = React.lazy(() => import('pages/Forbidden'));

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector((store: any) => store.self.token);
  const tokenDecode: { mcptt_id: string } = token && jwt_decode(token);
  const mcpttId: string = tokenDecode && tokenDecode.mcptt_id;
  const currentUser: string = mcpttId?.replace('sip:', '');

  useEffect(() => {
    if (currentUser) {
      dispatch(setCurrentUser(currentUser));
      const userName = currentUser.split('@')[0];
      const userDomain = currentUser.split('@')[1];

      if (userDomain) {
        dispatch(getUserInfo(userDomain, userName));
      } else {
        const defaultLanguage = getBrowserLanguage();
        dispatch(setLocale(defaultLanguage));
      }
    }
  }, [dispatch, currentUser]);

  return (
    <>
      <Router>
        <React.Suspense fallback={toLocaleString('loading')}>
          <Switch>
            <Route path='/tc/auth/' component={Token} />
            <Route path='/forbidden' component={Forbidden} />
            <PrivateRoute exact path='/' component={Index} />
            <PrivateRoute path='/tc/domain/:domain/:tab' component={Index} />
            <PrivateRoute path='/tc/domain/:domain' component={Index} />
            <PrivateRoute
              path='/tc/setting/:setting'
              usePrivateTop
              component={Index}
            />
            <Route path='*' component={Page404} />
          </Switch>
        </React.Suspense>

        <Notification />
      </Router>
    </>
  );
};

export default App;
