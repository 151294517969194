import { List, Map } from 'immutable';
import { StoreActionType } from 'constants/storeActionType';
import { AnyAction } from 'redux';

export type StoreFleets = Map<string, List<Fleet>>;

export type Fleet = {
  name: string;
  title: string;
  users: {
    users: {
      username: string;
      title: string;
    }[];
  };
  groups: {
    groups: string[];
  }
};

interface GetFleets extends AnyAction {
  type: StoreActionType.GET_FLEETS;
  domain: string;
  data: Fleet[];
}

interface DeleteFleet extends AnyAction {
  type: StoreActionType.DELETE_FLEET;
  domain: string;
  fleet: string;
}

interface ResetFleets extends AnyAction {
  type: StoreActionType.RESET_FLEETS;
}

export type StoreFleetsActions = GetFleets | DeleteFleet | ResetFleets;

const initialState = (): Map<string, List<Fleet>> => Map({});

const fleets = (
  store: StoreFleets = initialState(),
  action: StoreFleetsActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_FLEETS: {
      return store.set(action.domain, List(action.data));
    }
    case StoreActionType.DELETE_FLEET: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((fleet) => fleet.name === action.fleet);

        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }

        return store;
      }

      return store;
    }
    case StoreActionType.RESET_FLEETS: {
      return initialState();
    }
    default: {
      return store;
    }
  }
};

export default fleets;
