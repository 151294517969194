import { UsersRoles } from 'constants/commonEnum';
import { StoreActionType } from 'constants/storeActionType';
import { Language } from 'i18n/config';
import { AnyAction } from 'redux';

export type StoreSelf = {
  isAuth: boolean;
  token: string;
  refreshToken: string;
  permissions?: any;
  userName?: string;
  shortName?: string;
  locale?: Language;
  licenseInfo?: any;
  roles?: UsersRoles[];
};

interface SelfAuth extends AnyAction {
  type: StoreActionType.AUTH;
  data: {
    isAuth: boolean;
    token: string;
    refreshToken: string;
  };
}

interface SelfInit extends AnyAction {
  type: StoreActionType.INIT;
  data: { token: string };
}

interface SelfPermissions extends AnyAction {
  type: StoreActionType.SELF_PERMISSIONS;
  data: any;
}

interface SetCurrentUser extends AnyAction {
  type: StoreActionType.SET_CURRENT_USER;
  userName: string;
  shortName?: string;
}

interface SetLocale extends AnyAction {
  type: StoreActionType.SET_LOCALE;
  locale: Language;
}

interface SetLicenseInfo extends AnyAction {
  type: StoreActionType.SET_LICENSE_INFO;
  licenseInfo: any;
}

interface SetSelfRoles extends AnyAction {
  type: StoreActionType.SET_SELF_ROLES;
  roles: UsersRoles[];
}

interface ResetSelf extends AnyAction {
  type: StoreActionType.RESET_SELF;
}

export type StoreSelfActions =
  | SelfAuth
  | SelfInit
  | SelfPermissions
  | SetCurrentUser
  | SetLocale
  | SetLicenseInfo
  | SetSelfRoles
  | ResetSelf;

const initialStore = (): StoreSelf => {
  const strSelf = localStorage.getItem('mc-store-self');
  if (strSelf) {
    const objSelf = JSON.parse(strSelf);
    return objSelf as StoreSelf;
  }
  return { token: '', refreshToken: '', isAuth: false };
};

export default function self(
  store: StoreSelf = initialStore(),
  action: StoreSelfActions,
) {
  switch (action.type) {
    case StoreActionType.AUTH: {
      const copy = { ...store };
      copy.isAuth = action.data.isAuth;
      copy.token = action.data.token;
      copy.refreshToken = action.data.refreshToken;
      return copy;
    }
    case StoreActionType.INIT: {
      return store;
    }
    case StoreActionType.SET_CURRENT_USER: {
      const copy = { ...store };
      copy.userName = action.userName;
      copy.shortName = action.shortName;
      return copy;
    }
    case StoreActionType.SET_LOCALE: {
      const copy = { ...store };
      copy.locale = action.locale;
      return copy;
    }
    case StoreActionType.SELF_PERMISSIONS: {
      const copy = { ...store };
      copy.permissions = action.data;
      return copy;
    }
    case StoreActionType.SET_LICENSE_INFO: {
      const copy = { ...store };
      copy.licenseInfo = action.licenseInfo;
      return copy;
    }
    case StoreActionType.SET_SELF_ROLES: {
      const copy = { ...store };
      copy.roles = action.roles;
      return copy;
    }
    case StoreActionType.RESET_SELF: {
      return initialStore();
    }
    default: {
      return store;
    }
  }
}
