import { StoreActionType } from 'constants/storeActionType';
import { LMRGateway } from 'constants/interfaces/ILMRGatewys';
import {
  CreateGateway,
  DeleteGateway,
  SetGateways,
  SetLMRLoading,
  UpdateGateway,
} from './actions';

interface LMRGatewaysStore {
  loading: boolean;
  gateways: LMRGateway[];
}

export type StoreLMRGatewaysActions =
  | SetGateways
  | CreateGateway
  | UpdateGateway
  | DeleteGateway
  | SetLMRLoading;

const lmr = (
  store: LMRGatewaysStore = {
    loading: false,
    gateways: [],
  },
  action: StoreLMRGatewaysActions,
): LMRGatewaysStore => {
  switch (action.type) {
    case StoreActionType.LMR_CREATE_GATEWAY: {
      return {
        ...store,
        gateways: [...store.gateways, action.payload],
      };
    }

    case StoreActionType.LMR_SET_GATEWAYS: {
      return {
        ...store,
        gateways: action.payload,
      };
    }

    case StoreActionType.LMR_DELETE_GATEWAY: {
      const gateways = store.gateways.filter(
        (gateway) => action.payload !== gateway.id,
      );

      return {
        ...store,
        gateways,
      };
    }

    case StoreActionType.LMR_UPDATE_GATEWAY: {
      const gatewayIndex = store.gateways.findIndex(
        (gateway) => action.payload.id === gateway.id,
      );
      const gateways = [...store.gateways];
      gateways.splice(gatewayIndex, 1, action.payload);

      return {
        ...store,
        gateways,
      };
    }

    case StoreActionType.LMR_SET_LOADING: {
      return {
        ...store,
        loading: action.payload,
      };
    }

    default: {
      return store;
    }
  }
};

export default lmr;
