import {
  checkDurationFormat,
  parseToDurationFormatString,
} from './durationISOFormat';
import { toLocaleString } from './translator';

const DEFAULT_NUMBER_DUPLICATE_CONFIG = 1;
const MAX_NAME_LENGTH = 40;

/* eslint-disable no-restricted-syntax */
const generateUuidv4 = () =>
  // @ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
  (
      // eslint-disable-next-line no-bitwise
      c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export function runSimpleMapper(data: any = {}) {
  const result: any = {};

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      const lowerCaseName = key[0].toLowerCase() + key.slice(1);
      if (Array.isArray(data[key])) {
        result[lowerCaseName] = data[key].map((item: any) =>
          runSimpleMapper(item),
        );
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        result[lowerCaseName] = runSimpleMapper(data[key]);
      } else {
        result[lowerCaseName] = data[key];
      }
    }
  }
  return result;
}

export const hexToRgba = (hex: string, opacity?: number) => {
  if (typeof hex === 'string' && hex.includes('rgb')) {
    return hex;
  }

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const op: any = { a: 1 };

  if (typeof opacity === 'number') {
    op.a = opacity;
  }

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        ...op,
      }
    : null;
};

const findTitleDuplicatesAndFix = (arr: any[], domain: string) =>
  arr.map((elem, index, array) => {
    const needLoginInfo = array.some(
      (el, ind) => el.title === elem.title && ind !== index,
    );

    return {
      ...elem,
      title: needLoginInfo
        ? `${elem.title} (${elem.login ?? elem.username}@${domain})`
        : elem.title,
    };
  });

export const validateDuration = (isFieldRequired: boolean) => (value: string) => {
  if (!value && !isFieldRequired) {
    return false;
  }

  const val = parseToDurationFormatString(value);

  return (
    !(checkDurationFormat(value) || checkDurationFormat(val)) &&
    toLocaleString('validate_duration_format')
  );
};

export const createNewName = (
  oldName: string,
  suffix: string,
  namesLocationConfigs: string[],
) => {
  const splitedName = oldName.split(' ');
  let newName = '';
  const wordsInName = splitedName.length;
  if (splitedName[wordsInName - 1] === suffix) {
    newName = `${oldName} ${DEFAULT_NUMBER_DUPLICATE_CONFIG}`;
  } else if (splitedName[wordsInName - 2] === suffix) {
    newName = `${splitedName.slice(0, splitedName.length - 1).join(' ')} ${
      Number(splitedName[wordsInName - 1]) + 1
    }`;
  } else {
    newName = `${oldName} ${suffix}`;
  }

  while (namesLocationConfigs.includes(newName)) {
    const nameWords = newName.split(' ');
    let lastNumber = parseInt(nameWords[nameWords.length - 1], 10);

    if (lastNumber) {
      nameWords.pop();
      newName = `${nameWords.join(' ')} ${(lastNumber += 1)}`;
    } else {
      newName = `${nameWords.join(
        ' ',
      )} ${DEFAULT_NUMBER_DUPLICATE_CONFIG}`;
    }
  }

  const newNameLength = newName.length;
  if (newNameLength > MAX_NAME_LENGTH) {
    const newNameArray = newName.split(suffix);
    newNameArray[0] = `${newNameArray[0].slice(
      0,
      newNameArray[0].length - (newNameLength - MAX_NAME_LENGTH) - 1,
    )} `;
    newName = newNameArray.join(suffix);
  }

  return newName;
};
export const commonHelpers = {
  generateUuidv4,
  runSimpleMapper,
  hexToRgb: hexToRgba,
  findTitleDuplicatesAndFix,
  validateDuration,
};
