import { StoreActionType } from 'constants/storeActionType';
import { Map } from 'immutable';
import { CallStatisticUnit, StoreCallStatisticActions } from './actions';

export enum CallStatisticStoreField {
  HOURLY = 'hourly',
  MINUTLY = 'minutly',
}

export type StoreCallStatistic = Map<
  string,
  {
    [CallStatisticStoreField.HOURLY]: CallStatisticUnit[];
    [CallStatisticStoreField.MINUTLY]: CallStatisticUnit[];
  }
>;

const initialState = (): StoreCallStatistic => Map({});

const callStatistic = (
  store: StoreCallStatistic = initialState(),
  action: StoreCallStatisticActions,
) => {
  switch (action.type) {
    case StoreActionType.SET_CALL_STATISTIC: {
      if (action.payload.minutely) {
        return store.setIn(
          [action.payload.domain, CallStatisticStoreField.MINUTLY],
          action.payload.data,
        );
      }

      return store.setIn(
        [action.payload.domain, CallStatisticStoreField.HOURLY],
        action.payload.data,
      );
    }

    default: {
      return store;
    }
  }
};

export default callStatistic;
