import i18next from 'i18next';
import { ThunkResult } from 'constants/commonTypes';
import { commonHelpers } from 'helpers/commonHelpers';
import { FileExtensions } from 'constants/commonEnum';
import { List } from 'immutable';
import { naturalSort } from 'helpers/naturalSort';
import { toLocaleString } from 'helpers/translator';
import { IWSUserData } from 'constants/interfaces/IUsers';
import { privateClient } from 'helpers/client';
import { StoreActionType } from 'constants/storeActionType';
import {
  UserData,
  UsersDataCreate,
  UsersChangeSet,
  UsersNames,
  UserDataCreate,
} from 'constants/commonInterfaces';
import { Language } from 'i18n/config';
import { createFleet } from './fleetController';
import { createGroup } from './groupController';
import {
  setShowModal,
  setLoading,
  setNewNotification,
} from './othersController';
import { clearSchemes } from './schemesController';

type User = {
  enabled: boolean;
  fleets: {
    name: string;
    title: string;
  }[];
  name: string;
  login: string;
  groups: {
    name: string;
    title: string;
  }[];
  online: boolean;
  roles?: string[];
  title: string;
  mcsId: string;
  locale: string;
  user_id: number;
};
interface GetQROptions {
  domainName: string;
  usersNames: UsersNames[];
  isReissue: boolean;
  afterResolve: (data: Blob) => void;
}
export const getUsers =
  (domain: string, usersCallback?: Function): ThunkResult<void> =>
  (dispatch, getState, { api }) => {
    const { currentDomain } = getState().others;

    privateClient
      .get(`${api}/api/v1/domains/${domain}/users`)
      .then((res) => {
        const users = res.map((user: User) => ({
          blocked: !user.enabled,
          enabled: user.enabled,
          fleets: user.fleets,
          groups: user.groups,
          name: user.name,
          login: user.login,
          online: user.online,
          title: user.title ?? user.name,
          mcsId: user.mcsId,
          roles: user.roles,
          locale: user.locale,
          user_id: user.user_id,
        }));
        dispatch({
          type: StoreActionType.PUSH_USERS,
          domain,
          users: commonHelpers.findTitleDuplicatesAndFix(
            naturalSort(users, 'title'),
            currentDomain,
          ),
        });
        if (usersCallback) {
          usersCallback(users);
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getUsers error', error));
  };

export const createUser =
  (
    domain: string,
    form: UserDataCreate,
    successCallBack?: Function,
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    dispatch(
      checkUsersLicense(domain, 1, (checkRes) => {
        if (checkRes) {
          privateClient
            .post<UserDataCreate>(`${api}/api/v1/domains/${domain}/users`, form)
            .then(() => {
              dispatch(getUsers(domain));
              if (successCallBack) {
                successCallBack();
              }

              dispatch(
                setNewNotification(
                  'success',
                  toLocaleString('user_create_success'),
                ),
              );
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error('createUser error', err);
            })
            .finally(() => dispatch(setLoading(false)));
        }
      }),
    );
  };

export function setLocale(locale: Language): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_LOCALE, locale });
    i18next.changeLanguage(locale);
  };
}

export const getUserInfo =
  (domain: string, name: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/${name}`)
      .then((res) => {
        dispatch(setLocale(res.locale));
        dispatch({
          type: StoreActionType.SET_SELF_ROLES,
          roles: res.roles.roles,
        });
        dispatch({
          type: StoreActionType.SET_CURRENT_USER,
          userName: res.mcsId,
          shortName: res.title,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getUserInfo error', error));
  };

export const getUserGroups =
  (domain: string, name: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/${name}/groups`)
      .then((data) => {
        if (data.length) {
          dispatch({
            type: StoreActionType.ADD_USER_GROUPS,
            domain,
            name,
            groups: data,
          });
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getUserGroups error', err));
  };

export const getUser =
  (domain: string, name: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/${name}`)
      .then((res) => {
        const user = { ...res, blocked: !res.enabled };
        delete user.password;
        dispatch({
          type: StoreActionType.UPDATE_USER,
          domain,
          user,
        });
        dispatch(getUserGroups(domain, name));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getUser error', err));
  };

export const updateUserOnlineStatus =
  (domain: string, userDataWS: IWSUserData[]): ThunkResult<void> =>
  (dispatch) => {
    dispatch({
      type: StoreActionType.UPDATE_ONLINE_STATUS,
      domain,
      userDataWS,
    });
  };

export const updateUser =
  ({
    name,
    domain,
    userData,
    isChangedLocale,
    activeDomain,
  }: {
    name: string;
    domain: string;
    userData: UserData;
    isChangedLocale?: boolean;
    activeDomain?: string;
  }): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    dispatch(setLoading(true));

    privateClient
      .put<UserData>(`${api}/api/v1/domains/${domain}/users/${name}`, userData)
      .then((res) => {
        dispatch(
          setNewNotification('success', toLocaleString('user_update_success')),
        );

        const user = { ...res, blocked: !res.enabled };
        delete user.password;
        dispatch({
          type: StoreActionType.UPDATE_USER,
          domain,
          user,
        });

        if (isChangedLocale && activeDomain) {
          dispatch(clearSchemes());
          dispatch({
            type: StoreActionType.CLEAR_CONFIGS,
            data: {
              domain: activeDomain,
            },
          });
        }

        dispatch(getUserGroups(domain, name));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('updateUser error', err);
      })
      .finally(() => dispatch(setLoading(false)));
  };

export const resetUserPassword =
  (
    domain: string,
    name: string,
    finallyCallBack = () => {},
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .delete(
        `${api}/api/v1/domains/${domain}/users/${name}/password/temporary`,
      )
      .then((data) => {
        navigator.clipboard
          .writeText(data)
          .then(() => {
            dispatch(
              setNewNotification(
                'success',
                toLocaleString('reset_temp_success'),
              ),
            );
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.error('clipboard error', error));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getUser error', err))
      .finally(() => {
        finallyCallBack();
      });
  };

export const reloadUsers =
  (domain: string, users: UsersNames[]): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .post<UsersNames[]>(
        `${api}/api/v1/domains/${domain}/users/reloading`,
        users,
      )
      .then(() => {
        dispatch(
          setNewNotification('success', toLocaleString('reload_success')),
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('reloadUsers error', err);
        dispatch(setNewNotification('error', toLocaleString('reload_error')));
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };

export const disableUsers =
  (domain: string, names: UsersNames[]): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    const putData: UsersChangeSet = {
      changeSet: '{"enabled":false}',
      names,
    };
    privateClient
      .put<UsersChangeSet>(
        `${api}/api/v1/domains/${domain}/users/batch`,
        putData,
      )
      .then(() => {
        dispatch(reloadUsers(domain, names));
        dispatch(
          setNewNotification(
            'success',
            toLocaleString('disable_reload_success'),
          ),
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('disableUsers error', err);
        dispatch(
          setNewNotification('error', toLocaleString('disable_reload_error')),
        );
      })
      .finally(() => dispatch(getUsers(domain)));
  };

export const createUsers = (
  domain: string,
  users: UsersDataCreate[],
  successCallBack?: Function,
): ThunkResult<void> => {
  const logins = users.map(({ login }: { login: string }) =>
    login.toLowerCase(),
  );
  const groupName = users.map(({ team }: { team: string }) => team)[0];

  const getFleetForm = (fleetUsers: any): any => {
    const usersForFleet = fleetUsers.map(({ name }: { name: string }) => ({
      username: name,
    }));
    return {
      name: '',
      title: groupName,
      users: {
        users: usersForFleet,
      },
    };
  };

  const getGroupForm = (groupUsers: any): any =>
    groupUsers.map(({ name }: { name: string }) => ({
      username: name,
      priority: 0,
      mcvideoOnNetworkRequired: false,
      onNetworkRequired: false,
      mcdataMaxDataInSingleRequest: 65535,
      allowStoreGroupCommInMsgstore: true,
    }));

  return (dispatch, _, { api }) => {
    dispatch(
      checkUsersLicense(domain, users.length, (checkRes) => {
        if (checkRes) {
          privateClient
            .post<UsersDataCreate[]>(
              `${api}/api/v1/domains/${domain}/users/batch`,
              users,
            )
            .then(() =>
              dispatch(
                getUsers(domain, (userList: List<User>) => {
                  const groupUsers =
                    userList?.filter((user) => logins.includes(user.login)) ??
                    [];
                  dispatch(
                    createGroup(
                      domain,
                      undefined,
                      undefined,
                      getGroupForm(groupUsers),
                      groupName,
                      () =>
                        dispatch(
                          createFleet(
                            domain,
                            getFleetForm(groupUsers),
                            successCallBack,
                          ),
                        ),
                    ),
                  );
                  dispatch(
                    setNewNotification(
                      'success',
                      toLocaleString('user_create_success'),
                    ),
                  );
                }),
              ),
            )
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error('createUsers error', err);
              dispatch(
                setNewNotification(
                  'error',
                  toLocaleString('user_create_error'),
                ),
              );
            })
            .finally(() => dispatch(setLoading(false)));
        }
      }),
    );
  };
};

export const updateUsers =
  (
    domain: string,
    names: UsersNames[] | string[],
    changeSet: string,
    afterResolve = () => {},
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    dispatch(setLoading(true));
    const putData = {
      changeSet,
      names,
    };
    privateClient
      .put<UsersChangeSet>(
        `${api}/api/v1/domains/${domain}/users/batch`,
        putData,
      )
      .then(() => {
        dispatch(
          setNewNotification('success', toLocaleString('users_update_success')),
        );
      })
      .then(afterResolve)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('updateUsers error: ', err);
        dispatch(
          setNewNotification('error', toLocaleString('users_update_error')),
        );
      })
      .finally(() => {
        dispatch(getUsers(domain));
        dispatch(setLoading(false));
      });
  };

export const deleteUsers =
  (users: any, domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .delete(`${api}/api/v1/domains/${domain}/users/batch`, {
        body: JSON.stringify(users),
      })
      .then(() => {
        users.forEach((name: string) => {
          dispatch({ type: StoreActionType.DELETE_USER, name, domain });
        });
        dispatch(
          setNewNotification('success', toLocaleString('user_delete_success')),
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('deleteUsers error', err);
        dispatch(
          setNewNotification('error', toLocaleString('user_delete_error')),
        );
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };

export const getUsersPriorities =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/priorities`)
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_USERS_PRIORITIES,
          domain,
          usersPriorities: data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getUsersPriorities error', err));
  };

export const getUserScheme =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/scheme`)
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_USER,
          domain,
          scheme: data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getUserScheme error', error));
  };

export const getUserSchemeBatch =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/scheme_batch`)
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_USER_BATCH,
          domain,
          scheme: data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getUserSchemeBatch error', err));
  };

export const setCurrentUser =
  (userName: string): ThunkResult<void> =>
  (dispatch) => {
    dispatch({ type: StoreActionType.SET_CURRENT_USER, userName });
  };

export const changeLocale = (
  username: string,
  locale: string,
  activeDomain: string,
): ThunkResult<void> => {
  const [userName, userDomain] = username.split('@');

  return (dispatch, _, { api }) => {
    if (userName && userDomain) {
      privateClient
        .get(`${api}/api/v1/domains/${userDomain}/users/${userName}`)
        .then((res) =>
          dispatch(
            updateUser({
              name: userName,
              domain: userDomain,
              userData: { ...res, locale },
              isChangedLocale: true,
              activeDomain,
            }),
          ),
        )
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('changeLocale error', err);
          dispatch(
            setNewNotification('error', toLocaleString('settings_save_error')),
          );
        });
    }
  };
};

export const resetUsers = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_USERS });
};

export const getQR =
  ({
    domainName,
    usersNames,
    isReissue,
    afterResolve,
  }: GetQROptions): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    const fileExtension =
      usersNames.length > 1 ? FileExtensions.ZIP : FileExtensions.PDF;
    privateClient
      .post<UsersNames[]>(
        `${api}/api/v1/domains/${domainName}/users/qr${
          isReissue ? '/reissue' : ''
        }`,
        usersNames,
      )
      .then((data) => {
        if (usersNames.length > 1) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = `QRCodes.${fileExtension}`;
          link.click();
        }
        afterResolve(data);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('genQR error', err))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const checkUsersLicense =
  (
    domain: string,
    expectedUserCount: number,
    callback: (result: boolean) => void,
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/license`)
      .then((data) => {
        const result = expectedUserCount <= data;
        if (!result) {
          dispatch(
            setNewNotification(
              'error',
              toLocaleString('user_count_exceeds_expected'),
            ),
          );
        }
        callback(result);
      })
      .catch(() => {
        callback(false);
      });
  };
