import { NotificationTypes } from 'components/Notification/Notification';
import { PageEnum, DomainTabName } from 'constants/commonEnum';
import { ThunkResult } from 'constants/commonTypes';
import { StoreActionType } from 'constants/storeActionType';
import { ModalDataProps, NotificationOptions } from 'store/others';
import { notification as notificationController } from 'antd';
import { publicClient } from 'helpers/client';

export function setHeaderTitle(title: string): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_HEADER_TITLE, title });
  };
}

export function setSelectedRows<T>(selected_rows: T[]): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_SELECTED_ROWS, selected_rows });
  };
}

export function setSelectedTab(selected_tab: string): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_SELECTED_TAB, selected_tab });
  };
}

export const setSelectedUserSubTab =
  (subTab: string): ThunkResult<void> =>
  (dispatch) => {
    dispatch({
      type: StoreActionType.SET_SELECTED_USER_SUB_TAB,
      selectedSubTab: subTab,
    });
  };

export function setShowModal(show_modal: boolean): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_SHOW_MODAL, show_modal });
  };
}

export const setModalData =
  (modal_data: ModalDataProps): ThunkResult<void> =>
  (dispatch) => {
    dispatch({ type: StoreActionType.SET_MODAL_DATA, modal_data });
  };

export function setShowDrawer(show_drawer: boolean): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_SHOW_DRAWER, show_drawer });
  };
}

export function setLoading(isLoading: boolean): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_ASYNC_DONE, isLoading });
  };
}

export const setTheme =
  (theme: boolean): ThunkResult<void> =>
  (dispatch) => {
    dispatch({ type: StoreActionType.SET_THEME, theme });
  };

export function setDataTable(dataTable: object): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_DATA_TABLE, dataTable });
  };
}

export function setFilterTable(filterTable: any): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_FILTER_TABLE, filterTable });
  };
}

export function setCurrentPage(
  page: PageEnum | DomainTabName,
): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_CURRENT_PAGE, page });
  };
}

export function setCurrentDomain(domain: string): ThunkResult<void> {
  return (dispatch) => {
    dispatch({ type: StoreActionType.SET_CURRENT_DOMAIN, domain });
  };
}

export function setExceptionString(): ThunkResult<void> {
  return (dispatch, _, { api }) => {
    publicClient(`${api}/api/v1/verification/messages`)
      .then((response) => response.json())
      .then((strings) => {
        dispatch({ type: StoreActionType.SET_EXCEPTION_STRINGS, strings });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('setExceptionString error', error));
  };
}

export function getApiVersion(): ThunkResult<void> {
  return (dispatch, _, { api }) => {
    publicClient(`${api}/api/v1/configuration/version`)
      .then((response) => response.text())
      .then((apiVersion) => {
        dispatch({ type: StoreActionType.SET_API_VERSION, apiVersion });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getApiVersion error', error));
  };
}

export const resetValues = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_VALUES });
};

export const setNewNotification =
  (
    viewType: NotificationTypes,
    message: string,
    options?: NotificationOptions,
  ): ThunkResult<void> =>
  (dispatch) => {
    dispatch({
      type: StoreActionType.SET_NEW_NOTIFICATION,
      viewType,
      message,
      options,
    });
  };

export const removeNotification =
  (viewType: NotificationTypes, message: string): ThunkResult<void> =>
  (dispatch) => {
    dispatch({ type: StoreActionType.REMOVE_NOTIFICATION, viewType, message });
  };

const notificationCloseTimeouts: { [id: string]: NodeJS.Timeout | null } = {};
const NOTIFICATION_CLOSE_TIME = 5000;

export const expireEndlessNotifications =
  (): ThunkResult<void> => (dispatch, getState) => {
    const { others } = getState();

    if (others.displayedNotifications.length > 0) {
      const endlessNotifications = others.displayedNotifications.filter(
        (notification) => notification.options?.callPreparing,
      );

      endlessNotifications.forEach(({ message, type, options }) => {
        const id = options!.callPreparing.writerSessionId;

        if (id && !notificationCloseTimeouts[id]) {
          notificationCloseTimeouts[id] = setTimeout(() => {
            notificationController.close(
              options!.callPreparing.writerSessionId,
            );
            dispatch(removeNotification(type, message));
            notificationCloseTimeouts[id] = null;
          }, NOTIFICATION_CLOSE_TIME);
        }
      });
    }
  };
