export enum StoreActionType {
  // BACKUPS
  GET_BACKUPS = '@@/backups/GET_BACKUPS',
  CREATE_BACKUP = '@@/backups/CREATE_BACKUP',
  RESTORE_BACKUP = '@@/backups/RESTORE_BACKUP',
  GET_DATABASES = '@@/backups/GET_DATABASES',
  GET_BACKUP = '@@/backups/GET_BACKUP',
  DELETE_BACKUP = '@@/backups/DELETE_BACKUP',
  GET_DELETED_BACKUPS = '@@/backups/GET_DELETED_BACKUPS',
  RESET_BACKUPS = '@@/backups/RESET_BACKUPS',
  SET_BACKUPS_LOADING = '@@/backups/SET_LOADING',
  GET_BACKUPS_SCHEDULE = '@@/backups/GET_BACKUPS_SCHEDULE',
  SET_BACKUPS_SCHEDULE = '@@/backups/SET_BACKUPS_SCHEDULE',
  GET_BACKUPS_SCHEDULE_HISTORY = '@@/backups/GET_BACKUPS_SCHEDULE_HISTORY',
  GET_BACKUPS_RETENTION = '@@/backups/GET_BACKUPS_RETENTION',
  SET_BACKUPS_RETENTION = '@@/backups/SET_BACKUPS_RETENTION',
  GET_BACKUPS_RETENTION_HISTORY = '@@/backups/GET_BACKUPS_RETENTION_HISTORY',
  GET_BACKUP_SUBSCRIPTIONS = '@@/backups/GET_BACKUP_SUBSCRIPTIONS',
  SET_BACKUP_SUBSCRIPTIONS = '@@/backups/SET_BACKUP_SUBSCRIPTIONS',
  DELETE_BACKUP_SUBSCRIPTIONS = '@@/backups/DELETE_BACKUP_SUBSCRIPTIONS',
  PUSH_BACKUP_USERS = '@@/users/PUSH_BACKUP_USERS',
  // CALL HISTORY
  GET_CALL_HISTORY = '@@/callHistory/GET_CALL_HISTORY',
  RESET_CALL_HISTORY = '@@/callHistory/RESET_CALL_HISTORY',
  SELECT_CALL_DETAILS_ID = '@@/callHistory/SELECT_CALL_DETAILS_ID',
  SELECT_CALL_DETAILS_ID_BY_WRITER_SESSION_ID = '@@/callHistory/SELECT_CALL_DETAILS_ID_BY_WRITER_SESSION_ID',
  SELECT_WRITER_SESSION_ID = '@@/callHistory/SELECT_WRITER_SESSION_ID',
  SET_CALL_HISTORY_FILTERS = '@@/callHistory/SET_CALL_HISTORY_FILTERS',
  SET_CALL_HISTORY_PARTICIPANTS = '@@/callHistory/SET_CALL_HISTORY_PARTICIPANTS',
  SET_CALL_WRITER_SESSIONS = '@@/callHistory/SET_CALL_WRITER_SESSIONS',
  SET_CALL_WRITER_PREPARING_STATE = '@@/callHistory/SET_CALL_WRITER_PREPARING_STATE',
  PUSH_CALL_WRITER_PREPARING_PROGRESS = '@@/callHistory/PUSH_CALL_WRITER_PREPARING_PROGRESS',
  SET_SIGNALING_DATA = '@@/callHistory/SET_SIGNALING_DATA',
  // CALL STATISTIC
  SET_CALL_STATISTIC = '@@/callStatistic/SET_CALL_STATISTIC',
  // DOMAINS
  ADD_DOMAIN = '@@/domains/ADD_DOMAIN',
  DELETE_DOMAIN = '@@/domains/DELETE_DOMAIN',
  PUSH_CONFIG = '@@/domains/PUSH_CONFIG',
  PUSH_DOMAINS = '@@/domains/PUSH_DOMAINS',
  RESET_DOMAINS = '@@/domains/RESET_DOMAINS',
  SET_USERS_PRIORITIES = '@@/domains/SET_USERS_PRIORITIES',
  UPDATE_CONFIG = '@@/domains/UPDATE_CONFIG',
  UPDATE_DOMAIN = '@@/domains/UPDATE_DOMAIN',
  CLEAR_CONFIGS = '@@/domains/CLEAR_CONFIGS',
  // FLEETS
  DELETE_FLEET = '@@/fleets/DELETE_FLEET',
  GET_FLEETS = '@@/fleets/GET_FLEETS',
  RESET_FLEETS = '@@/fleets/RESET_FLEETS',
  // FUNCTIONAL ALIASES
  SET_FUNCTIONAL_ALIASES = '@@/functionalAliases/SET_FUNCTIONAL_ALIASES',
  // GEOFENCES
  ADD_GEOFENCE = '@@/Geofences/ADD_GEOFENCE',
  DELETE_GEOFENCE = '@@/Geofences/DELETE_GEOFENCE',
  GET_GEOFENCES = '@@/Geofences/GET_GEOFENCES',
  UPDATE_GEOFENCE = '@@/Geofences/UPDATE_GEOFENCE',
  // GROUPS
  CREATE_GROUP = '@@/groups/CREATE_GROUP',
  DELETE_GROUP = '@@/groups/DELETE_GROUP',
  PUSH_GEOFENCING_CONFIG = '@@/groups/PUSH_GEOFENCING_CONFIG',
  PUSH_GROUP_ADVANCED_SETTINGS = '@@/groups/PUSH_GROUP_ADVANCED_SETTINGS',
  PUSH_GROUPS = '@@/groups/PUSH_GROUPS',
  RESET_GROUPS = '@@/groups/RESET_GROUPS',
  UPDATE_GROUP = '@@/groups/UPDATE_GROUP',
  // LOCATIONS
  DELETE_LOCATION_CONFIG = '@@/locationConfigs/DELETE_LOCATION_CONFIG',
  GET_LOCATION_CONFIGS = '@@/locationConfigs/GET_LOCATION_CONFIGS',
  RESET_LOCATION_CONFIGS = '@@/locationConfigs/RESET_LOCATION_CONFIGS',
  // MAP
  ADD_GUARD_TOUR = '@@/Map/GuardTours/ADD_GUARD_TOUR',
  ADD_SCHEDULE = '@@/Map/GuardTours/ADD_SCHEDULE',
  DELETE_GUARD_TOUR = '@@/Map/GuardTours/DELETE_GUARD_TOUR',
  DELETE_SCHEDULE = '@@/Map/GuardTours/DELETE_SCHEDULE',
  SET_GUARD_TOUR_LIST = '@@/Map/GuardTours/SET_GUARD_TOUR_LIST',
  SET_GUARD_TOUR_POINT_STATUS = '@@/Map/GuardTours/SET_GUARD_TOUR_POINT_STATUS',
  SET_GUARD_TOUR_STATUS = '@@/Map/GuardTours/SET_GUARD_TOUR_STATUS',
  SET_SCHEDULES_LIST = '@@/Map/GuardTours/SET_SCHEDULES_LIST',
  UPDATE_GUARD_TOUR = '@@/Map/GuardTours/UPDATE_GUARD_TOUR',
  UPDATE_SCHEDULE = '@@/Map/GuardTours/UPDATE_SCHEDULE',
  ADD_WAYPOINT = '@@/Map/Waypoints/ADD_WAYPOINT',
  DELETE_WAYPOINT = '@@/Map/Waypoints/DELETE_WAYPOINT',
  SET_WAYPOINTS_LIST = '@@/Map/Waypoints/SET_WAYPOINTS_LIST',
  UPDATE_WAYPOINT = '@@/Map/Waypoints/UPDATE_WAYPOINT',
  SET_UNDO_FEATURES = '@@/Map/SET_UNDO_FEATURES',
  SET_REDO_FEATURES = '@@/Map/SET_REDO_FEATURES',
  // MAINTENANCE
  GET_SWAGGER_URL_LIST = '@@/maintenance/GET_SWAGGER_URL_LIST',
  RESET_SWAGGER_LIST = '@@/maintenance/RESET_SWAGGER_LIST',
  MAINTENANCE_SET_LOADING = '@@/maintenance/SET_LOADING',
  // OTHERS
  REMOVE_NOTIFICATION = '@@/others/REMOVE_NOTIFICATION',
  SET_API_VERSION = '@@/others/SET_API_VERSION',
  SET_ASYNC_DONE = '@@/others/SET_ASYNC_DONE',
  SET_CURRENT_DOMAIN = '@@/others/SET_CURRENT_DOMAIN',
  SET_CURRENT_PAGE = '@@/others/SET_CURRENT_PAGE',
  SET_DATA_TABLE = '@@/others/SET_DATA_TABLE',
  SET_EXCEPTION_STRINGS = '@@/others/SET_EXCEPTION_STRINGS',
  SET_FILTER_TABLE = '@@/others/SET_FILTER_TABLE',
  SET_HEADER_TITLE = '@@/others/SET_HEADER_TITLE',
  SET_MODAL_DATA = '@@/others/SET_MODAL_DATA',
  SET_NEW_NOTIFICATION = '@@/others/SET_NEW_NOTIFICATION',
  SET_SELECTED_ROWS = '@@/others/SET_SELECTED_ROWS',
  SET_SELECTED_TAB = '@@/others/SET_SELECTED_TAB',
  SET_SELECTED_USER_SUB_TAB = '@@/others/SET_SELECTED_USER_SUB_TAB',
  SET_SHOW_DRAWER = '@@/others/SET_SHOW_DRAWER',
  SET_SHOW_MODAL = '@@/others/SET_SHOW_MODAL',
  SET_THEME = '@@/others/SET_THEME',
  // RESTRICTIONS
  DELETE_RESTRICTION = '@@/restrictions/DELETE_RESTRICTION',
  GET_RESTRICTIONS = '@@/restrictions/GET_RESTRICTIONS',
  SET_RESTRICTIONS = '@@/restrictions/SET_RESTRICTIONS',
  UPDATE_RESTRICTION = '@@/restrictions/UPDATE_RESTRICTION',
  // ROLES
  GET_ROLES = '@@/roles/GET_ROLES',
  RESET_ROLES = '@@/roles/RESET_ROLES',
  // SCHEMES
  SET_SCHEME_USER = '@@/schemes/SET_SCHEME_USER',
  SET_SCHEME_USER_BATCH = '@@/schemes/SET_SCHEME_USER_BATCH',
  RESET_SCHEMES = '@@/schemes/RESET_SCHEMES',
  SET_SCHEME_ADVANCED_SETTINGS = '@@/schemes/SET_SCHEME_ADVANCED_SETTINGS',
  SET_SCHEME_BATCH_ADVANCED_SETTINGS = '@@/schemes/SET_SCHEME_BATCH_ADVANCED_SETTINGS',
  SET_SCHEME_BATCH_DATA = '@@/schemes/SET_SCHEME_BATCH_DATA',
  SET_SCHEME_BATCH_LWP = '@@/schemes/SET_SCHEME_BATCH_LWP',
  SET_SCHEME_BATCH_PTT = '@@/schemes/SET_SCHEME_BATCH_PTT',
  SET_SCHEME_BATCH_VIDEO = '@@/schemes/SET_SCHEME_BATCH_VIDEO',
  SET_SCHEME_DATA = '@@/schemes/SET_SCHEME_DATA',
  SET_SCHEME_DOMAIN = '@@/schemes/SET_SCHEME_DOMAIN',
  SET_SCHEME_FLEET = '@@/schemes/SET_SCHEME_FLEET',
  SET_SCHEME_GROUP = '@@/schemes/SET_SCHEME_GROUP',
  SET_SCHEME_GROUP_GEOFENCING = '@@/schemes/SET_SCHEME_GROUP_GEOFENCING',
  SET_SCHEME_GROUP_ADVANCED_SETTINGS = '@@/schemes/SET_SCHEME_GROUP_ADVANCED_SETTINGS',
  SET_SCHEME_LWP = '@@/schemes/SET_SCHEME_LWP',
  SET_SCHEME_PTT = '@@/schemes/SET_SCHEME_PTT',
  SET_SCHEME_STATUS = '@@/schemes/SET_SCHEME_STATUS',
  SET_SCHEME_VIDEO = '@@/schemes/SET_SCHEME_VIDEO',
  // SELF
  AUTH = '@@/self/AUTH',
  INIT = '@@/self/INIT',
  RESET_SELF = '@@/self/RESET_SELF',
  SELF_PERMISSIONS = '@@/self/SELF_PERMISSIONS',
  SET_CURRENT_USER = '@@/self/SET_CURRENT_USER',
  SET_LICENSE_INFO = '@@/self/SET_LICENSE_INFO',
  SET_LOCALE = '@@/self/SET_LOCALE',
  SET_SELF_ROLES = '@@/self/SET_SELF_ROLES',
  // STATUSES
  CREATE_STATUS = '@@/statuses/CREATE_STATUS',
  DELETE_STATUS = '@@/statuses/DELETE_STATUS',
  GET_STATUSES = '@@/statuses/GET_STATUSES',
  RESET_STATUSES = '@@/statuses/RESET_STATUSES',
  // TABS
  ADD_TAB = '@@/tabs/ADD_TAB',
  CHANGE_TAB = '@@/tabs/CHANGE_TAB',
  CLEAR_TABS = '@@/tabs/CLEAR_TABS',
  DELETE_TAB = '@@/tabs/DELETE_TAB',
  // UPDATE
  CREATE_UPDATE_VERSION = '@@/updateVersions/CREATE_UPDATE_VERSION',
  DELETE_UPDATE_VERSION = '@@/updateVersions/DELETE_UPDATE_VERSION',
  GET_UPDATE_VERSIONS_LIST = '@@/updateVersions/GET_UPDATE_VERSIONS_LIST',
  RESET_UPDATE_VERSIONS_LIST = '@@/updateVersions/RESET_UPDATE_VERSIONS_LIST',
  UPDATE_SET_LOADING = '@@/updateVersions/SET_LOADING',
  UPLOAD_UPDATE_VERSION = '@@/updateVersions/UPLOAD_UPDATE_VERSION',
  UPLOAD_UPDATE_VERSION_PROGRESS = '@@/updateVersions/UPLOAD_UPDATE_VERSION_PROGRESS',
  REMOVE_UPDATE_VERSION_PROGRESS = '@@/updateVersions/REMOVE_UPDATE_VERSION_PROGRESS',
  // USERS
  ADD_CONFIG = '@@/users/ADD_CONFIG',
  ADD_USER = '@@/users/ADD_USER',
  ADD_USER_GROUPS = '@@/users/ADD_USER_GROUPS',
  DELETE_USER = '@@/users/DELETE_USER',
  PUSH_USERS = '@@/users/PUSH_USERS',
  RESET_USERS = '@@/users/RESET_USERS',
  USERS_UPDATE_CONFIG = '@@/users/UPDATE_CONFIG',
  CLEAR_USERS_CONFIG_SCHEME = '@@/users/CLEAR_USERS_CONFIG_SCHEME',
  UPDATE_ONLINE_STATUS = '@@/users/UPDATE_ONLINE_STATUS',
  UPDATE_USER = '@@/users/UPDATE_USER',
  RESET_VALUES = '@@/values/RESET_VALUES',
  SET_FLEET_VALUES = '@@/values/SET_FLEET_VALUES',
  SET_GROUP_VALUES = '@@/values/SET_GROUP_VALUES',
  SET_USER_PROFILE_VALUES = '@@/values/SET_USER_PROFILE_VALUES',
  SET_USER_VALUES = '@@/values/SET_USER_VALUES',
  UPDATE_USER_PROFILE_DATA = '@@/values/UPDATE_USER_PROFILE_DATA',
  GET_USERS_LICENSE = '@@/users/GET_USERS_LICENSE',
  // LMR
  LMR_SET_LOADING = '@@/lmr/SET_LOADING',
  LMR_SET_GATEWAYS = '@@/lmr/SET_GATEWAYS',
  LMR_CREATE_GATEWAY = '@@/lmr/CREATE_GATEWAY',
  LMR_DELETE_GATEWAY = '@@/lmr/DELETE_GATEWAY',
  LMR_UPDATE_GATEWAY = '@@/lmr/UPDATE_GATEWAY',
}
