import ic_1 from 'icons/map_icons/icon1.svg';
import ic_2 from 'icons/map_icons/icon2.svg';
import ic_3 from 'icons/map_icons/icon3.svg';
import ic_4 from 'icons/map_icons/icon4.svg';
import ic_5 from 'icons/map_icons/icon5.svg';
import ic_6 from 'icons/map_icons/icon6.svg';
import ic_7 from 'icons/map_icons/icon7.svg';
import ic_8 from 'icons/map_icons/icon8.svg';
import ic_9 from 'icons/map_icons/icon9.svg';
import ic_10 from 'icons/map_icons/icon10.svg';
import ic_11 from 'icons/map_icons/icon11.svg';
import ic_gr_inactive from 'icons/map_icons/ic_gr_inactive.svg';
import ic_gr_pause from 'icons/map_icons/ic_gr_pause.svg';
import ic_gr_active from 'icons/map_icons/ic_gr_active.svg';
import ic_gr_selected from 'icons/map_icons/ic_gr_selected.svg';
import ic_gr_complete from 'icons/map_icons/ic_gr_complete.svg';
import ic_gr_start from 'icons/map_icons/ic_gr_start.svg';
import ic_gr_skip from 'icons/map_icons/ic_gr_skip.svg';
import { GuardTourPointIconsEnum as GTPointIconsEnum } from 'constants/MapBox/MapBox';

export enum EWaypointIcons {
  IC1 = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2m0-9a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7zm0 16.88c-2.12-2.69-5-7-5-9.88a5 5 0 0 1 10 0c0 2.85-2.92 7.21-5 9.88z"/></svg>',
  IC2 = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 16 16"><path d="M499.537 13.689L493.1 3.418a.917.917 0 0 0-1.539 0l-6.435 10.271a.837.837 0 0 0-.013.872.906.906 0 0 0 .787.439h12.869a.906.906 0 0 0 .783-.439.837.837 0 0 0-.015-.872zm-7.2-.689a.667.667 0 1 1 .662-.667.679.679 0 0 1-.661.667zm.533-2.7c0 .261-.313.371-.533.371-.295 0-.543-.109-.543-.371 0-1-.129-2.445-.129-3.449 0-.329.294-.514.671-.514.359 0 .662.185.662.514.002 1-.127 2.442-.127 3.449z" transform="translate(-484) translate(484) translate(-484.333 -1)"/></svg>',
  IC3 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M402.335 15H397a11.976 11.976 0 0 0 2-6.667c1.993 0 5.306-5.305 5.332-5.331v.007-.007c.026.025 3.339 5.331 5.332 5.331a11.951 11.951 0 0 0 2 6.667h-5.335c-.019-.025-2-2.674-2-4.664.004 1.989-1.976 4.638-1.994 4.664z" transform="translate(-396) translate(396) translate(-396.334 -1.001)"/></g></g></svg>',
  IC4 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M234.173 7.017l-1.345-3.585A.666.666 0 0 0 232.2 3h-7.076a.666.666 0 0 0-.624.433l-1.34 3.584A1.329 1.329 0 0 0 222 8.333v3.333A1.333 1.333 0 0 0 223.333 13v1.333A.667.667 0 0 0 224 15h1.333a.667.667 0 0 0 .667-.667V13h5.333v1.333A.667.667 0 0 0 232 15h1.333a.667.667 0 0 0 .667-.667V13a1.333 1.333 0 0 0 1.333-1.333V8.333a1.329 1.329 0 0 0-1.16-1.316zM224.333 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm1.257-6.667h6.152l1 2.667h-8.152zM233 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1z" transform="translate(-220) translate(220) translate(-220.667 -1)"/></g></g></svg>',
  IC5 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M269.667 7.667v-4a.667.667 0 0 1 .666-.667h4a.667.667 0 0 1 .667.667v4a.667.667 0 0 1-.667.667h-4a.667.667 0 0 1-.666-.667zm6 2H269a.669.669 0 0 0-.667.667v3.993a.007.007 0 0 0 .007.007 6.647 6.647 0 0 0 7.987 0 .007.007 0 0 0 .007-.007v-3.994a.669.669 0 0 0-.667-.666zm-7-2v-4h-2.087a.524.524 0 0 0-.527.527v3.16a.528.528 0 0 0 .527.527h2.107a1.1 1.1 0 0 1-.02-.214zm-3.14 1.26a.532.532 0 0 0-.527.527v3.153a.007.007 0 0 0 .007.007 5.157 5.157 0 0 0 2.327.987v-3.268a1.655 1.655 0 0 1 .773-1.407zm12.56-5.26H276v4a1.106 1.106 0 0 1-.02.213h2.107a.528.528 0 0 0 .527-.527v-3.16a.524.524 0 0 0-.527-.526zm1.053 5.26h-2.58a1.655 1.655 0 0 1 .773 1.407V13.6a5.157 5.157 0 0 0 2.327-.987.007.007 0 0 0 .007-.007V9.453a.532.532 0 0 0-.527-.526z" transform="translate(-264) translate(264) translate(-264.333 -1)"/></g></g></svg>',
  IC6 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M101.667 7.333v-.666a4.667 4.667 0 0 0-9.333 0v.667H91V14a1.333 1.333 0 0 0 1.333 1.333h9.333A1.333 1.333 0 0 0 103 14V7.333zM97.5 11.847v.653a.5.5 0 0 1-1 0v-.653A.986.986 0 0 1 96 11a1 1 0 1 1 2 0 .986.986 0 0 1-.5.847zm2.833-4.513h-6.666v-.667a3.333 3.333 0 1 1 6.667 0z" transform="translate(-88) translate(88) translate(-89 -.667)"/></g></g></svg>',
  IC7 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M138.144 3.6l-1.333-1.333h6.989a.662.662 0 0 1 .62.433l1.347 3.587a1.32 1.32 0 0 1 1.16 1.313v3.333a1.332 1.332 0 0 1-.453 1l-1.687-1.687a1 1 0 1 0-1.173-1.173l-2.807-2.807h3.527l-1-2.667zm8.649 12.138l-1.556-1.556a.647.647 0 0 1-.306.085H143.6a.669.669 0 0 1-.667-.667v-1.336H137.6V13.6a.669.669 0 0 1-.667.667H135.6a.669.669 0 0 1-.667-.667v-1.336a1.337 1.337 0 0 1-1.333-1.333V7.6a1.32 1.32 0 0 1 1.16-1.313l.707-1.877-2.671-2.671.943-.943 14 14zm-9.862-6.471a1 1 0 1 0-1 1 1 1 0 0 0 1-1.003zm.391-3l-.823-.823-.308.823z" transform="translate(-132) translate(132) translate(-132.264 -.264)"/></g></g></svg>',
  IC8 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M191 2.333h-4.667V1L179 2.333v12l7.333 1.333v-1.333H191zm-6 7.333h-1.333V7H185zM189.667 13h-3.333V3.667h3.333z" transform="translate(-176) translate(176) translate(-177 -.333)"/></g></g></svg>',
  IC9 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M356.333 4H355V2.667a.667.667 0 0 1 1.333 0zm0 5.333H355V14h-.667v1.333H357V14h-.667zm9.333-6.667a.667.667 0 1 0-1.333 0V4h1.333zm0 6.667h-1.333V14h-.667v1.333h2.667V14h-.667zm-12-4.667a.669.669 0 0 0-.667.667V7.96l3.293-3.293zm7.96 0h-3.92l-4 4h3.92zm1.333 4l4-4h-3.92l-4 4zm1.413 0H367a.669.669 0 0 0 .667-.666V5.373zm-.707 2H357V12h6.667z" transform="translate(-352) translate(352) translate(-352.333 -.667)"/></g></g></svg>',
  IC10 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path d="M13.52 4.22l-1.887 1.887a4.667 4.667 0 0 1 0 6.6l1.887 1.887a7.339 7.339 0 0 0 0-10.373zm-10.373 0a7.339 7.339 0 0 0 0 10.373l1.887-1.887a4.667 4.667 0 0 1 0-6.6zm6.52 5.187a1.333 1.333 0 1 1-1.334-1.334 1.333 1.333 0 0 1 1.334 1.334z" transform="translate(-.333 -1.407)"/></g></svg>',
  IC11 = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M315.333 7.667v-4A.667.667 0 0 1 316 3h4a.667.667 0 0 1 .667.667v4a.667.667 0 0 1-.667.667h-4a.667.667 0 0 1-.667-.667zm6 2h-6.667a.669.669 0 0 0-.667.667v3.993a.007.007 0 0 0 .007.007 6.647 6.647 0 0 0 7.987 0 .007.007 0 0 0 .007-.007v-3.994a.669.669 0 0 0-.667-.666z" transform="translate(-308) translate(308) translate(-310 -1)"/></g></g></svg>',
}

const createIconImage = (src: string): HTMLImageElement => {
  const image = new Image(45, 45);
  image.src = src;
  return image;
};

const waypointPointIcons = {
  icon1: {
    key: 1,
    html: EWaypointIcons.IC1,
    path: ic_1,
    icon: createIconImage(ic_1),
  },
  icon2: {
    key: 2,
    html: EWaypointIcons.IC2,
    path: ic_2,
    icon: createIconImage(ic_2),
  },
  icon3: {
    key: 3,
    html: EWaypointIcons.IC3,
    path: ic_3,
    icon: createIconImage(ic_3),
  },
  icon4: {
    key: 4,
    html: EWaypointIcons.IC4,
    path: ic_4,
    icon: createIconImage(ic_4),
  },
  icon5: {
    key: 5,
    html: EWaypointIcons.IC5,
    path: ic_5,
    icon: createIconImage(ic_5),
  },
  icon6: {
    key: 6,
    html: EWaypointIcons.IC6,
    path: ic_6,
    icon: createIconImage(ic_6),
  },
  icon7: {
    key: 7,
    html: EWaypointIcons.IC7,
    path: ic_7,
    icon: createIconImage(ic_7),
  },
  icon8: {
    key: 8,
    html: EWaypointIcons.IC8,
    path: ic_8,
    icon: createIconImage(ic_8),
  },
  icon9: {
    key: 9,
    html: EWaypointIcons.IC9,
    path: ic_9,
    icon: createIconImage(ic_9),
  },
  icon10: {
    key: 10,
    html: EWaypointIcons.IC10,
    path: ic_10,
    icon: createIconImage(ic_10),
  },
  icon11: {
    key: 11,
    html: EWaypointIcons.IC11,
    path: ic_11,
    icon: createIconImage(ic_11),
  },
};

const guardTourPointIcons = {
  [GTPointIconsEnum.INACTIVE]: {
    name: GTPointIconsEnum.INACTIVE,
    icon: createIconImage(ic_gr_inactive),
  },
  [GTPointIconsEnum.PAUSE]: {
    name: GTPointIconsEnum.PAUSE,
    icon: createIconImage(ic_gr_pause),
  },
  [GTPointIconsEnum.SKIP]: {
    name: GTPointIconsEnum.SKIP,
    icon: createIconImage(ic_gr_skip),
  },
  [GTPointIconsEnum.START]: {
    name: GTPointIconsEnum.START,
    icon: createIconImage(ic_gr_start),
  },
  [GTPointIconsEnum.COMPLETE]: {
    name: GTPointIconsEnum.COMPLETE,
    icon: createIconImage(ic_gr_complete),
  },
  [GTPointIconsEnum.ACTIVE]: {
    name: GTPointIconsEnum.ACTIVE,
    icon: createIconImage(ic_gr_active),
  },
  [GTPointIconsEnum.SELECTED]: {
    name: GTPointIconsEnum.SELECTED,
    icon: createIconImage(ic_gr_selected),
  },
};

export { createIconImage, guardTourPointIcons, waypointPointIcons };

export {
  mapLineStringToWaypointMapper,
  waypointToMapLineStringMapper,
} from './waypointMapper';
