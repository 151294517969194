import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelectorStore } from 'store';

const Login = React.lazy(() => import('pages/Login'));

interface PrivateRouteProps extends RouteProps {
  component: any;
  usePrivateTop?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const isAuth = useSelectorStore((store) => store.self.isAuth);

  const { component: Component, usePrivateTop = false, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuth ? (
          <Component {...routeProps} usePrivateTop={usePrivateTop} />
        ) : (
          <Login />
        )
      }
    />
  );
};

export default PrivateRoute;
