import { combineReducers } from 'redux';
import {
  useSelector as useWithoutType,
  TypedUseSelectorHook,
} from 'react-redux';
import defValues, { StoreDefValuesActions } from './values';
import domains, { StoreDomainsActions } from './domains';
import fleets, { StoreFleetsActions } from './fleets';
import groups, { StoreGroupsActions } from './groups';
import others, { StoreOthersActions } from './others';
import restrictions, { StoreRestrictionsActions } from './restrictions';
import roles, { StoreRolesActions } from './roles';
import schemes, { StoreSchemesActions } from './schemes';
import self, { StoreSelfActions } from './self';
import statuses, { StoreStatusesActions } from './statuses';
import callHistory, { StoreCallHistoryActions } from './callHistory';
import tabs, { StoreTabsActions } from './tabs';
import users, { StoreUsersActions } from './users';
import apiDocs, { StoreMaintenanceActions } from './apiDocs';
import versionManagement, {
  StoreVersionManagementActions,
} from './versionManagement';
import locationConfigs, { StoreLocationConfigsActions } from './locationConfig';
import geofences, { StoreGeofencesActions } from './geofences';
import map, { StoreWaypointsActions } from './map';
import backupManagement, {
  StoreBackupManagementActions,
} from './backupManagement';
import functionalAliases, {
  StoreFunctionalAliasesActions,
} from './functionalAliases';
import lmr, { StoreLMRGatewaysActions } from './lmr';
import callStatistic from './callStatistic';
import { StoreCallStatisticActions } from './callStatistic/actions';

const reducers = combineReducers({
  defValues,
  domains,
  apiDocs,
  fleets,
  groups,
  others,
  restrictions,
  roles,
  schemes,
  self,
  statuses,
  callHistory,
  callStatistic,
  tabs,
  users,
  versionManagement,
  locationConfigs,
  geofences,
  map,
  backupManagement,
  functionalAliases,
  lmr,
});

export type ReduxStore = ReturnType<typeof reducers>;
export type StoreActionType =
  | StoreDefValuesActions
  | StoreDomainsActions
  | StoreFleetsActions
  | StoreGroupsActions
  | StoreOthersActions
  | StoreRestrictionsActions
  | StoreRolesActions
  | StoreSchemesActions
  | StoreSelfActions
  | StoreStatusesActions
  | StoreCallHistoryActions
  | StoreTabsActions
  | StoreUsersActions
  | StoreMaintenanceActions
  | StoreVersionManagementActions
  | StoreLocationConfigsActions
  | StoreGeofencesActions
  | StoreWaypointsActions
  | StoreBackupManagementActions
  | StoreFunctionalAliasesActions
  | StoreLMRGatewaysActions
  | StoreCallStatisticActions;

export const useSelectorStore: TypedUseSelectorHook<ReduxStore> =
  useWithoutType;

export default reducers;
