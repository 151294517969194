import { StoreActionType } from 'constants/storeActionType';
import { AnyAction } from 'redux';
import { SchemePTTProps } from 'store/types';

export type SchemeContent = {
  title: string;
  description?: string;
  properties?: {
    [key: string]: SchemeContent;
  };
  view?: {
    visible: boolean;
    editable: boolean;
    batch: true;
  };
  verification?: {
    required?: boolean;
    minValue?: number;
    maxValue?: number;
  };
};

interface Scheme<K extends string> {
  properties: {
    [key in K]: SchemeContent;
  };
}

export type StoreSchemes = {
  schemeDomain?: string;
  schemeUser?: any;
  schemeUserBatch?: any;
  schemeAdvancedSettings?: any;
  schemeBatchAdvancedSettings?: any;
  schemeLWP?: Scheme<string>;
  schemeBatchLWP?: any;
  schemeData?: string;
  schemePTT?: Scheme<SchemePTTProps>;
  schemeVideo?: string;
  schemeBatchData?: string;
  schemeBatchPTT?: string;
  schemeBatchVideo?: string;
  schemeGroup?: string[];
  schemeGroupGeofencing?: string[];
  schemeGroupAdvancedSettings?: string[];
  schemeFleet?: string[];
  schemeStatus?: string[];
};

const initialState = (): StoreSchemes => ({});

interface SetSchemeDomain extends AnyAction {
  type: StoreActionType.SET_SCHEME_DOMAIN;
  data: string;
}

interface SetSchemeUser extends AnyAction {
  type: StoreActionType.SET_SCHEME_USER;
  domain: string;
}

interface SetSchemeUserBatch extends AnyAction {
  type: StoreActionType.SET_SCHEME_USER_BATCH;
  domain: string;
}

interface SetSchemeData extends AnyAction {
  type: StoreActionType.SET_SCHEME_DATA;
  data: string;
}

interface SetSchemePTT extends AnyAction {
  type: StoreActionType.SET_SCHEME_PTT;
  data: Scheme<SchemePTTProps>;
}

interface SetSchemeVideo extends AnyAction {
  type: StoreActionType.SET_SCHEME_VIDEO;
  data: string;
}

interface SetSchemeAdvancedSettings extends AnyAction {
  type: StoreActionType.SET_SCHEME_ADVANCED_SETTINGS;
  data: string;
}

interface SetSchemeBatchAdvancedSettings extends AnyAction {
  type: StoreActionType.SET_SCHEME_BATCH_ADVANCED_SETTINGS;
  data: string;
}

interface SetSchemeLWP extends AnyAction {
  type: StoreActionType.SET_SCHEME_LWP;
  data: Scheme<string>;
}

interface SetSchemeBatchLWP extends AnyAction {
  type: StoreActionType.SET_SCHEME_BATCH_LWP;
  data: Scheme<string>;
}

interface SetSchemeBatchData extends AnyAction {
  type: StoreActionType.SET_SCHEME_BATCH_DATA;
  data: string;
}

interface SetSchemeBatchPTT extends AnyAction {
  type: StoreActionType.SET_SCHEME_BATCH_PTT;
  data: string;
}

interface SetSchemeBatchVideo extends AnyAction {
  type: StoreActionType.SET_SCHEME_BATCH_VIDEO;
  data: string;
}

interface SetSchemeGroup extends AnyAction {
  type: StoreActionType.SET_SCHEME_GROUP;
  domain: string;
}

interface SetSchemeGroupGeofencing extends AnyAction {
  type: StoreActionType.SET_SCHEME_GROUP_GEOFENCING;
  domain: string;
}

interface SetSchemeGroupAdvancedSettings extends AnyAction {
  type: StoreActionType.SET_SCHEME_GROUP_ADVANCED_SETTINGS;
  payload: {
    domain: string;
    scheme: any;
  };
}

interface SetSchemeFleet extends AnyAction {
  type: StoreActionType.SET_SCHEME_FLEET;
  domain: string;
}

interface SetSchemeStatus extends AnyAction {
  type: StoreActionType.SET_SCHEME_STATUS;
  domain: string;
}

interface ResetSchemes extends AnyAction {
  type: StoreActionType.RESET_SCHEMES;
}

export type StoreSchemesActions =
  | SetSchemeDomain
  | SetSchemeUser
  | SetSchemeUserBatch
  | SetSchemeAdvancedSettings
  | SetSchemeBatchAdvancedSettings
  | SetSchemeLWP
  | SetSchemeBatchLWP
  | SetSchemeData
  | SetSchemePTT
  | SetSchemeVideo
  | SetSchemeBatchData
  | SetSchemeBatchPTT
  | SetSchemeBatchVideo
  | SetSchemeGroup
  | SetSchemeGroupGeofencing
  | SetSchemeGroupAdvancedSettings
  | SetSchemeFleet
  | SetSchemeStatus
  | ResetSchemes;

export default function schemes(
  store: StoreSchemes = initialState(),
  action: StoreSchemesActions,
): StoreSchemes {
  switch (action.type) {
    case StoreActionType.SET_SCHEME_USER: {
      const copy = { ...store };
      copy.schemeUser = action.scheme;
      return copy;
    }
    case StoreActionType.SET_SCHEME_USER_BATCH: {
      const copy = { ...store };
      copy.schemeUserBatch = action.scheme;
      return copy;
    }
    case StoreActionType.SET_SCHEME_ADVANCED_SETTINGS: {
      return { ...store, schemeAdvancedSettings: action.data };
    }
    case StoreActionType.SET_SCHEME_BATCH_ADVANCED_SETTINGS: {
      return { ...store, schemeBatchAdvancedSettings: action.data };
    }
    case StoreActionType.SET_SCHEME_GROUP: {
      return { ...store, schemeGroup: action.scheme };
    }
    case StoreActionType.SET_SCHEME_GROUP_GEOFENCING: {
      return { ...store, schemeGroupGeofencing: action.scheme };
    }
    case StoreActionType.SET_SCHEME_GROUP_ADVANCED_SETTINGS: {
      return { ...store, schemeGroupAdvancedSettings: action.payload.scheme };
    }
    case StoreActionType.SET_SCHEME_FLEET: {
      const copy = { ...store };
      copy.schemeFleet = action.scheme;
      return copy;
    }
    case StoreActionType.SET_SCHEME_STATUS: {
      const copy = { ...store };
      copy.schemeStatus = action.scheme;
      return copy;
    }
    case StoreActionType.SET_SCHEME_DOMAIN: {
      const copy = { ...store };
      copy.schemeDomain = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_DATA: {
      const copy = { ...store };
      copy.schemeData = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_PTT: {
      const copy = { ...store };
      copy.schemePTT = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_VIDEO: {
      const copy = { ...store };
      copy.schemeVideo = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_BATCH_DATA: {
      const copy = { ...store };
      copy.schemeBatchData = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_BATCH_PTT: {
      const copy = { ...store };
      copy.schemeBatchPTT = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_BATCH_VIDEO: {
      const copy = { ...store };
      copy.schemeBatchVideo = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_LWP: {
      const copy = { ...store };
      copy.schemeLWP = action.data;
      return copy;
    }
    case StoreActionType.SET_SCHEME_BATCH_LWP: {
      const copy = { ...store };
      copy.schemeBatchLWP = action.data;
      return copy;
    }
    case StoreActionType.RESET_SCHEMES: {
      return initialState();
    }
    default: {
      return store;
    }
  }
}
