import { NotificationTypes } from 'components/Notification/Notification';
import {
  LocalDomainName,
  ModalDataTarget,
  PageEnum,
  DomainTabName,
} from 'constants/commonEnum';
import { StoreActionType } from 'constants/storeActionType';
import { AnyAction } from 'redux';

export interface ModalDataProps {
  cancelText: string;
  modalMsg: string;
  okText: string;
  target: ModalDataTarget;
  checkboxText?: string;
}

export interface NotificationOptions {
  callPreparing: {
    writerSessionId: string;
    domain: string;
  };
}

export type StoreOthers = {
  apiVersion: string;
  isLoading: boolean;
  currentDomain: string;
  dataTable: object | null;
  exception_strings: {
    maxValue_exception: string;
    minLength_exception: string;
    pattern_exception: string;
    maxLength_exception: string;
    minValue_exception: string;
    password_exception: string;
    required_exception: string;
    intValue_exception: string;
  };
  filterTable: object | null;
  modal_data: ModalDataProps;
  page: PageEnum | DomainTabName;
  selected_rows: any;
  selected_tab: string;
  selectedSubTab: string;
  show_drawer: boolean;
  show_modal: boolean;
  displayedNotifications: {
    type: NotificationTypes;
    message: string;
    options?: NotificationOptions;
  }[];
  lastNotification?: {
    type: NotificationTypes;
    message: string;
    options?: NotificationOptions;
  };
  theme: boolean;
  title: string;
};

const initialState = (): {
  apiVersion: string;
  isLoading: boolean;
  currentDomain: string;
  dataTable: object;
  exception_strings: {
    maxValue_exception: string;
    minLength_exception: string;
    pattern_exception: string;
    maxLength_exception: string;
    minValue_exception: string;
    password_exception: string;
    required_exception: string;
    intValue_exception: string;
  };
  filterTable: object | null;
  modal_data: ModalDataProps;
  page: PageEnum | DomainTabName;
  selected_rows: any[];
  selected_tab: string;
  selectedSubTab: string;
  show_drawer: boolean;
  show_modal: boolean;
  displayedNotifications: { type: NotificationTypes; message: string }[];
  lastNotification?: { type: NotificationTypes; message: string };
  theme: boolean;
  title: string;
} => ({
  apiVersion: '',
  isLoading: false,
  currentDomain: LocalDomainName,
  dataTable: {},
  exception_strings: {
    intValue_exception: '',
    maxLength_exception: '',
    maxValue_exception: '',
    minLength_exception: '',
    minValue_exception: '',
    password_exception: '',
    pattern_exception: '',
    required_exception: '',
  },
  filterTable: null,
  modal_data: {
    cancelText: '',
    modalMsg: '',
    okText: '',
    target: ModalDataTarget.EMPTY,
    checkboxText: '',
  },
  page: DomainTabName.CONFIGURATIONS,
  selected_rows: [],
  selected_tab: '',
  selectedSubTab: '',
  show_drawer: false,
  show_modal: false,
  displayedNotifications: [],
  lastNotification: undefined,
  theme: false,
  title: '',
});

interface SetHeaderTitle extends AnyAction {
  type: StoreActionType.SET_HEADER_TITLE;
  title: string;
}

interface SetSelectedRows<T = unknown> extends AnyAction {
  type: StoreActionType.SET_SELECTED_ROWS;
  selected_rows: T[];
}

interface SetSelectedTab extends AnyAction {
  type: StoreActionType.SET_SELECTED_TAB;
  selected_tab: string;
}

interface SetSelectedUserSubTab extends AnyAction {
  type: StoreActionType.SET_SELECTED_USER_SUB_TAB;
  selectedSubTab: string;
}

interface SetAsyncDone extends AnyAction {
  type: StoreActionType.SET_ASYNC_DONE;
  isLoading: boolean;
}

interface SetShowModal extends AnyAction {
  type: StoreActionType.SET_SHOW_MODAL;
  show_modal: boolean;
}

interface SetModalData extends AnyAction {
  type: StoreActionType.SET_MODAL_DATA;
  modal_data: ModalDataProps;
}

interface SetShowDrawer extends AnyAction {
  type: StoreActionType.SET_SHOW_DRAWER;
  show_drawer: boolean;
}

interface SetTheme extends AnyAction {
  type: StoreActionType.SET_THEME;
  theme: boolean;
}

interface SetFilterTable extends AnyAction {
  type: StoreActionType.SET_FILTER_TABLE;
  filterTable: object;
}

interface SetCurrentPage extends AnyAction {
  type: StoreActionType.SET_CURRENT_PAGE;
  page: PageEnum | DomainTabName;
}

interface SetDataTable extends AnyAction {
  type: StoreActionType.SET_DATA_TABLE;
  dataTable: object | null;
}

interface SetCurrentDomain extends AnyAction {
  type: StoreActionType.SET_CURRENT_DOMAIN;
  domain: string;
}

interface SetExceptionStrings extends AnyAction {
  type: StoreActionType.SET_EXCEPTION_STRINGS;
  strings: {
    maxValue_exception: string;
    minLength_exception: string;
    pattern_exception: string;
    maxLength_exception: string;
    minValue_exception: string;
    password_exception: string;
    required_exception: string;
    intValue_exception: string;
  };
}

interface SetApiVersion extends AnyAction {
  type: StoreActionType.SET_API_VERSION;
  apiVersion: string;
}

interface SetNewNotification extends AnyAction {
  type: StoreActionType.SET_NEW_NOTIFICATION;
  viewType: NotificationTypes;
  message: string;
  options?: NotificationOptions;
}

interface RemoveNotification extends AnyAction {
  type: StoreActionType.REMOVE_NOTIFICATION;
  viewType: NotificationTypes;
  message: string;
}

export type StoreOthersActions =
  | SetHeaderTitle
  | SetSelectedRows
  | SetSelectedTab
  | SetSelectedUserSubTab
  | SetAsyncDone
  | SetShowModal
  | SetModalData
  | SetShowDrawer
  | SetTheme
  | SetFilterTable
  | SetDataTable
  | SetCurrentPage
  | SetCurrentDomain
  | SetExceptionStrings
  | SetApiVersion
  | SetNewNotification
  | RemoveNotification;

export default function others(
  store: StoreOthers = initialState(),
  action: StoreOthersActions,
): StoreOthers {
  switch (action.type) {
    case StoreActionType.SET_HEADER_TITLE: {
      return { ...store, title: action.title };
    }
    case StoreActionType.SET_SELECTED_ROWS: {
      return { ...store, selected_rows: action.selected_rows };
    }
    case StoreActionType.SET_SELECTED_TAB: {
      return { ...store, selected_tab: action.selected_tab };
    }
    case StoreActionType.SET_SELECTED_USER_SUB_TAB: {
      return { ...store, selectedSubTab: action.selectedSubTab };
    }
    case StoreActionType.SET_ASYNC_DONE: {
      return { ...store, isLoading: action.isLoading };
    }
    case StoreActionType.SET_SHOW_MODAL: {
      return { ...store, show_modal: action.show_modal };
    }
    case StoreActionType.SET_MODAL_DATA: {
      return { ...store, modal_data: action.modal_data };
    }
    case StoreActionType.SET_SHOW_DRAWER: {
      return { ...store, show_drawer: action.show_drawer };
    }
    case StoreActionType.SET_THEME: {
      return { ...store, theme: action.theme };
    }
    case StoreActionType.SET_FILTER_TABLE: {
      return { ...store, filterTable: action.filterTable };
    }
    case StoreActionType.SET_CURRENT_PAGE: {
      return { ...store, page: action.page };
    }
    case StoreActionType.SET_DATA_TABLE: {
      return { ...store, dataTable: action.dataTable };
    }
    case StoreActionType.SET_CURRENT_DOMAIN: {
      return { ...store, currentDomain: action.domain };
    }
    case StoreActionType.SET_EXCEPTION_STRINGS: {
      return { ...store, exception_strings: action.strings };
    }
    case StoreActionType.SET_API_VERSION: {
      return { ...store, apiVersion: action.apiVersion };
    }
    case StoreActionType.SET_NEW_NOTIFICATION: {
      if (
        store.displayedNotifications.find(
          (item) => item.message === action.message,
        )
      ) {
        return store;
      }

      return {
        ...store,
        displayedNotifications: [
          ...store.displayedNotifications,
          {
            message: action.message,
            type: action.viewType,
            options: action.options,
          },
        ],
        lastNotification: {
          message: action.message,
          type: action.viewType,
          options: action.options,
        },
      };
    }
    case StoreActionType.REMOVE_NOTIFICATION: {
      if (
        store.displayedNotifications.find(
          (item) => item.message === action.message,
        )
      ) {
        return {
          ...store,
          displayedNotifications: [
            ...store.displayedNotifications.filter(
              (item) => item.message !== action.message,
            ),
          ],
          lastNotification: undefined,
        };
      }

      return store;
    }
    default: {
      return store;
    }
  }
}
