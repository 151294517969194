import { TARGET_BASE_URL } from 'constants/common';

const homepage =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/`
    : '/';

const apiUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/admin-server`
    : `/admin-server`;

const updatingServerApiUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/updating-server`
    : `/updating-server`;

const cdServerApi =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/cd-server`
    : `/cd-server`;

const lmServerApi =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/lm-server`
    : `/lm-server`;

const famServerApi =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/fam-server`
    : `/fam-server`;

const mapServerApi =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/map-server`
    : `/map-server`;

const baseUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}`
    : TARGET_BASE_URL;

const redirectUri = `${window.location.origin}/tc/auth/`;

const idmsUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/idms`
    : `/idms`;

const publicIdmsUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/idms`
    : `${TARGET_BASE_URL}/idms`;

const logoutUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/idms/logout`
    : `/idms/logout`;

const publicLogoutUri =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/idms/logout`
    : `${TARGET_BASE_URL}/idms/logout`;

const lmrData =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/lmr-data`
    : `/lmr-data`;

const eventServer =
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== 'localhost'
    ? `${window.location.origin}/event-server`
    : `/event-server`;

const constants = {
  homepage,
  cdServerApi,
  base_uri: baseUri,
  api_uri: apiUri,
  apiUpdatingServer: updatingServerApiUri,
  famServerApi,
  lmServerApi,
  mapServerApi,
  idms_uri: idmsUri,
  publicIdmsUri,
  redirect_uri: redirectUri,
  publicLogoutUri,
  logout_uri: logoutUri,
  lmrData,
  eventServer,
};

export default constants;
