import { List, Map } from 'immutable';
import { AnyAction } from 'redux';
import { StoreActionType } from 'constants/storeActionType';

type StoreRestrictions = Map<string, List<Restriction>>;

export type Restriction = {
  count: number;
  name: string;
  service: {
    service: string;
  };
};

interface GetRestrictions extends AnyAction {
  type: StoreActionType.GET_RESTRICTIONS;
}

interface SetRestriction extends AnyAction {
  type: StoreActionType.SET_RESTRICTIONS;
}

interface DeleteRestriction extends AnyAction {
  type: StoreActionType.DELETE_RESTRICTION;
}

interface UpdateRestrictions extends AnyAction {
  type: StoreActionType.UPDATE_RESTRICTION;
}

export type StoreRestrictionsActions =
  | GetRestrictions
  | SetRestriction
  | DeleteRestriction
  | UpdateRestrictions;

const initialState = (): Map<string, List<Restriction>> => Map({});

const restrictions = (
  store: StoreRestrictions = initialState(),
  action: StoreRestrictionsActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_RESTRICTIONS: {
      return store.set(action.domain, List(action.data));
    }
    case StoreActionType.SET_RESTRICTIONS: {
      const section = store.get(action.domain);

      if (section) {
        return store.set(action.domain, section.push(action.data));
      }

      return store.set(action.domain, List([action.data]));
    }
    case StoreActionType.DELETE_RESTRICTION: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex(
          (service) => service.name === action.service,
        );

        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }
      }

      return store;
    }
    case StoreActionType.UPDATE_RESTRICTION: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findIndex(
          (service) => service.name === action.service,
        );

        if (index !== -1) {
          return action.data
            ? store.setIn([action.domain, index], action.data)
            : store;
        }
      }

      return store;
    }
    default: {
      return store;
    }
  }
};

export default restrictions;
