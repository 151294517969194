import URIS from 'configs/deploy';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from 'store';

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      thunk.withExtraArgument({
        baseUrl: URIS.base_uri,
        api: URIS.api_uri,
        apiUpdatingServer: URIS.apiUpdatingServer,
        lmServerApi: URIS.lmServerApi,
        mapServer: URIS.mapServerApi,
        cdServerApi: URIS.cdServerApi,
        famServerApi: URIS.famServerApi,
        lmrData: URIS.lmrData,
        eventServer: URIS.eventServer,
      }),
    ),
  ),
);

export default store;
