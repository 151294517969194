import { StoreActionType } from 'constants/storeActionType';
import { List, Map } from 'immutable';
import { CallType, RecordPreparingState } from 'constants/commonEnum';
import {
  GetCallHistory,
  ICallHistory,
  ICallHistoryFilters,
  ICallParticipant,
  ResetCallHistory,
  SelectCallDetailsId,
  SelectCallDetailsIdByWriterSessionId,
  SelectWriterSessionId,
  SetCallHistoryFilters,
  SetCallParticipants,
  SetCallWriterPreparingProgress,
  SetCallWriterPreparingState,
  SetCallWriterSessions,
  SetSignalingData,
} from './actions';

export enum CallHistoryStoreField {
  SELECTED_CALL_DETAILS_ID = 'selectedCallDetailsId',
  SELECTED_WRITER_SESSION_ID = 'selectedWriterSessionId',
  HIGHLIGHTED_WRITER_SESSION_ID = 'highlightedWriterSessionId',
  PARTICIPANTS = 'participants',
  SESSION_TYPE = 'sessionType',
  RECORD_PROCESSING = 'recordProcessing',
  DATA = 'data',
}

export type RecordProcessing = {
  preparingState: RecordPreparingState;
  preparingProgress: number;
};

export type StoreCallHistory = Map<
  string,
  {
    [CallHistoryStoreField.DATA]: List<ICallHistory>;
    filters: ICallHistoryFilters;
    [CallHistoryStoreField.RECORD_PROCESSING]: {
      [sessionId: string]: RecordProcessing;
    };
    [CallHistoryStoreField.SELECTED_WRITER_SESSION_ID]: string | null;
    [CallHistoryStoreField.HIGHLIGHTED_WRITER_SESSION_ID]: string | null;
    [CallHistoryStoreField.SELECTED_CALL_DETAILS_ID]: string | null;
  }
>;

export type StoreCallHistoryActions =
  | GetCallHistory
  | ResetCallHistory
  | SetCallHistoryFilters
  | SetCallParticipants
  | SetCallWriterSessions
  | SetSignalingData
  | SelectWriterSessionId
  | SetCallWriterPreparingState
  | SetCallWriterPreparingProgress
  | SelectCallDetailsIdByWriterSessionId
  | SelectCallDetailsId;

const initialState = (): StoreCallHistory => Map({});

const callHistory = (
  store: StoreCallHistory = initialState(),
  action: StoreCallHistoryActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_CALL_HISTORY: {
      return store.setIn(
        [action.domain, CallHistoryStoreField.DATA],
        List(action.data),
      );
    }

    case StoreActionType.SET_CALL_HISTORY_FILTERS: {
      return store.setIn([action.domain, 'filters'], action.filters);
    }

    case StoreActionType.SET_CALL_HISTORY_PARTICIPANTS: {
      const index = (
        store.getIn([
          action.domain,
          CallHistoryStoreField.DATA,
        ]) as List<ICallHistory>
      )?.findKey((hist) => hist.id === action.id);

      if (index !== undefined) {
        return store.setIn(
          [
            action.domain,
            CallHistoryStoreField.DATA,
            index,
            CallHistoryStoreField.PARTICIPANTS,
          ],
          action.data,
        );
      }

      return store;
    }

    case StoreActionType.SET_CALL_WRITER_SESSIONS: {
      const index = (
        store.getIn([
          action.domain,
          CallHistoryStoreField.DATA,
        ]) as List<ICallHistory>
      )?.findKey((hist) => hist.id === action.id);

      const getSession = (userId: string) =>
        action.data.find((prtp) => userId === prtp.userId);

      if (index !== undefined) {
        const sessionType = store.getIn([
          action.domain,
          CallHistoryStoreField.DATA,
          index,
          CallHistoryStoreField.SESSION_TYPE,
        ]) as CallType;

        const participants = (
          store.getIn([
            action.domain,
            CallHistoryStoreField.DATA,
            index,
            CallHistoryStoreField.PARTICIPANTS,
          ]) as ICallParticipant[]
        ).map((p) => {
          const participant =
            sessionType === CallType.PRIVATE
              ? getSession(p.cdpn ?? p.cgpn)
              : getSession(p.cdpn) || getSession(p.cgpn);

          return {
            ...p,
            writerSessionId: participant?.mcsSessionId ?? null,
          };
        });

        return store.setIn(
          [
            action.domain,
            CallHistoryStoreField.DATA,
            index,
            CallHistoryStoreField.PARTICIPANTS,
          ],
          participants,
        );
      }

      return store;
    }

    case StoreActionType.SET_SIGNALING_DATA: {
      const index = (
        store.getIn([
          action.domain,
          CallHistoryStoreField.DATA,
        ]) as List<ICallHistory>
      )?.findKey((item) => item.id === action.id);

      if (index !== undefined) {
        const participants = (
          store.getIn([
            action.domain,
            CallHistoryStoreField.DATA,
            index,
            CallHistoryStoreField.PARTICIPANTS,
          ]) as ICallParticipant[]
        ).map((p) => {
          if (p.writerSessionId === action.writerSessionId) {
            return {
              ...p,
              signalingData: action.signalingData,
            };
          }

          return p;
        });

        return store.setIn(
          [
            action.domain,
            CallHistoryStoreField.DATA,
            index,
            CallHistoryStoreField.PARTICIPANTS,
          ],
          participants,
        );
      }

      return store;
    }

    case StoreActionType.SELECT_WRITER_SESSION_ID: {
      const newStore = store.setIn(
        [action.domain, CallHistoryStoreField.HIGHLIGHTED_WRITER_SESSION_ID],
        '',
      );

      return newStore.setIn(
        [action.domain, CallHistoryStoreField.SELECTED_WRITER_SESSION_ID],
        action.writerSessionId,
      );
    }

    case StoreActionType.SELECT_CALL_DETAILS_ID: {
      const newStore = store.setIn(
        [action.domain, CallHistoryStoreField.HIGHLIGHTED_WRITER_SESSION_ID],
        '',
      );

      return newStore.setIn(
        [action.domain, CallHistoryStoreField.SELECTED_CALL_DETAILS_ID],
        action.callDetailsId,
      );
    }

    case StoreActionType.SELECT_CALL_DETAILS_ID_BY_WRITER_SESSION_ID: {
      const sessionId = action.writerSessionId;

      const data = store.getIn([
        action.domain,
        CallHistoryStoreField.DATA,
      ]) as ICallHistory[];

      const callHistoryItem = data.find((item) =>
        item.participants?.some((prtp) => prtp.writerSessionId === sessionId),
      );

      if (callHistoryItem) {
        const newStore = store.setIn(
          [action.domain, CallHistoryStoreField.HIGHLIGHTED_WRITER_SESSION_ID],
          sessionId,
        );

        return newStore.setIn(
          [action.domain, CallHistoryStoreField.SELECTED_CALL_DETAILS_ID],
          callHistoryItem?.id,
        );
      }

      return store;
    }

    case StoreActionType.SET_CALL_WRITER_PREPARING_STATE: {
      const recordProcessing = store.getIn([
        action.payload.domain,
        CallHistoryStoreField.RECORD_PROCESSING,
        action.payload.writerSessionId,
      ]) as RecordProcessing;

      if (recordProcessing) {
        if (
          action.payload.state === RecordPreparingState.NONE ||
          (recordProcessing.preparingState !== RecordPreparingState.SUCCESS &&
            recordProcessing.preparingState !== RecordPreparingState.FAIL)
        ) {
          return store.setIn(
            [
              action.payload.domain,
              CallHistoryStoreField.RECORD_PROCESSING,
              action.payload.writerSessionId,
            ],
            {
              ...recordProcessing,
              preparingState: action.payload.state,
              preparingProgress:
                recordProcessing.preparingState ===
                RecordPreparingState.IN_PROGRESS
                  ? recordProcessing.preparingProgress
                  : 0,
            },
          );
        }

        return store;
      }

      return store.setIn(
        [
          action.payload.domain,
          CallHistoryStoreField.RECORD_PROCESSING,
          action.payload.writerSessionId,
        ],
        {
          preparingState: action.payload.state,
        },
      );
    }

    case StoreActionType.PUSH_CALL_WRITER_PREPARING_PROGRESS: {
      const recordProcessing = store.getIn([
        action.payload.domain,
        CallHistoryStoreField.RECORD_PROCESSING,
        action.payload.writerSessionId,
      ]) as RecordProcessing;

      if (recordProcessing) {
        return store.setIn(
          [
            action.payload.domain,
            CallHistoryStoreField.RECORD_PROCESSING,
            action.payload.writerSessionId,
          ],
          {
            ...recordProcessing,
            preparingProgress: recordProcessing.preparingProgress
              ? recordProcessing.preparingProgress + 1
              : 1,
          },
        );
      }

      return store;
    }

    case StoreActionType.RESET_CALL_HISTORY: {
      return initialState();
    }

    default: {
      return store;
    }
  }
};

export default callHistory;
