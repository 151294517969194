import { parse, pattern, toSeconds } from 'iso8601-duration';

// TODO may be need more smart RegExp
export const checkDurationFormat = (value: string) =>
  (/(\d+d) (\d+h) (\d+m) (\d+s)/gi.test(value) ||
    /(\d+d) (\d+h) (\d+m) (\d+s)/gi.test(value) ||
    /(\d+h) (\d+m) (\d+s)/gi.test(value) ||
    /(\d+m) (\d+s)/gi.test(value) ||
    /(\d+d)/gi.test(value) ||
    /(\d+h)/gi.test(value) ||
    /(\d+m)/gi.test(value) ||
    /(\d+s)/gi.test(value)) &&
  !value.split(' ').filter((item) => +item).length &&
  !value
    // @ts-ignore
    .replaceAll(' ', '')
    .split('')
    .filter(
      (item: string) =>
        item !== '0' && !+item && !['d', 'h', 'm', 's'].includes(item),
    ).length;

export const parseToDurationFormatString = (
  value: string,
  setIfEmpty?: string,
) => {
  let changedValue = '';

  if (!pattern.test(value)) {
    changedValue = value;
  } else {
    try {
      const parsedISOvalue = parse(value);
      const keysObj = Object.keys(parsedISOvalue);
      keysObj.forEach((el) => {
        // @ts-ignore
        if (parsedISOvalue[el]) {
          changedValue = `${
            // @ts-ignore
            changedValue + parsedISOvalue[el] + el.substring(0, 1)
          } `;
        }
      });

      // @ts-ignore
      if (setIfEmpty && keysObj.every((k) => parsedISOvalue[k] === 0)) {
        changedValue = setIfEmpty;
      }
    } catch (e) {
      changedValue = value;
    }

    changedValue = changedValue?.split(' ').join(' ').trim();
  }

  return changedValue;
};

export const parseStringFormatToISOFormat = (value: string) => {
  const arrayString = value.split('');
  arrayString.forEach((val, index) => {
    if (['d', 'h', 'm', 's'].includes(val) && value[index + 1] !== ' ') {
      arrayString.splice(index + 1, 0, ' ');
    }
  });

  const obj = arrayString.join('').split(' ');
  let isoObj = {
    years: '0',
    months: '0',
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
    millisecond: '000',
  };

  obj.forEach((item) => {
    if (item.substring(item.length - 1) === 'd') {
      isoObj = {
        ...isoObj,
        days: item.substring(0, item.length - 1),
      };
    }
    if (item.substring(item.length - 1) === 'h') {
      isoObj = {
        ...isoObj,
        hours: item.substring(0, item.length - 1),
      };
    }
    if (item.substring(item.length - 1) === 'm') {
      isoObj = {
        ...isoObj,
        minutes: item.substring(0, item.length - 1),
      };
    }
    if (item.substring(item.length - 1) === 's') {
      isoObj = {
        ...isoObj,
        seconds: item.substring(0, item.length - 1),
      };
    }
  });

  // eslint-disable-next-line max-len
  const isoString = `P${isoObj.years}Y${isoObj.months}M${isoObj.days}DT${isoObj.hours}H${isoObj.minutes}M${isoObj.seconds}.${isoObj.millisecond}S`;

  return isoString;
};

const secToIso8601converter = (ms: number) => {
  if (ms === 0) {
    return 'P0D';
  }

  let offset = Math.floor(ms);
  let days = 0;
  if (offset < 0) {
    days = Math.floor(offset % 86400000);
    offset -= 86400000 * days;
  }
  const milliseconds = offset % 1000;
  offset = Math.floor(offset / 1000);
  const seconds = offset % 60;
  offset = Math.floor(offset / 60);
  const minutes = offset % 60;
  offset = Math.floor(offset / 60);
  const hours = offset % 24;
  days += Math.floor(offset / 24);
  const parts = ['P'];

  if (days) {
    parts.push(`${days}D`);
  }
  if (hours || minutes || seconds || milliseconds) {
    parts.push('T');
    if (hours) {
      parts.push(`${hours}H`);
    }
    if (minutes) {
      parts.push(`${minutes}M`);
    }
    if (seconds || milliseconds) {
      parts.push(seconds.toString());
      if (milliseconds) {
        let temp = milliseconds.toString();

        while (temp.length < 3) {
          temp = `0${temp}`;
        }
        parts.push(`.${temp}`);
      }
      parts.push('S');
    }
  }

  return parts.join('');
};

export const millisecondsToDuration = (ms: number, setIfEmpty = '0d 0h 0m 0s') =>
  parseToDurationFormatString(secToIso8601converter(ms), setIfEmpty);

export const durationToMilliseconds = (duration: string) =>
  toSeconds(parse(parseStringFormatToISOFormat(duration))) * 1000;

function pad(num: number) {
    return (`0${num}`).slice(-2);
}

export const hhmmss = (secs: number | undefined, options?: { mmss: boolean }) => {
  if (secs === undefined) {
    return `00:00:00`;
  }
  
  secs = Math.floor(secs)
  let minutes = Math.floor(secs / 60);
  secs %=60;

  const hours = Math.floor(minutes/60)
  minutes %=60;

  if (options?.mmss) {
    return `${pad(minutes)}:${pad(secs)}`;
  }

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}