import moment from 'moment';
import { AnyAction } from 'redux';
import { StoreActionType } from 'constants/storeActionType';
import { UploadingState } from 'constants/commonEnum';
import {
  RemoveUpdateVersionProgress,
  UploadUpdateVersionProgress,
} from './actions';

export interface IVersionManagement {
  id?: number | string;
  app?: string;
  branch?: string;
  version?: string;
  file_name?: string;
  created_at?: string;
  updated_at?: string;
  size?: number;
  last?: boolean;
  force_install?: boolean;
}

interface GetVersionList extends AnyAction {
  type: StoreActionType.GET_UPDATE_VERSIONS_LIST;
  response: IVersionManagement[];
}

interface SetLoadingVersionList extends AnyAction {
  type: StoreActionType.UPDATE_SET_LOADING;
  loading: boolean;
}
interface ResetVersionList extends AnyAction {
  type: StoreActionType.RESET_UPDATE_VERSIONS_LIST;
}

interface CreateUpdateVersion extends AnyAction {
  type: StoreActionType.CREATE_UPDATE_VERSION;
  response: IVersionManagement;
}

interface DeleteUpdateVersion extends AnyAction {
  type: StoreActionType.DELETE_UPDATE_VERSION;
  idVersion: string | number;
}

interface UploadUpdateVersion extends AnyAction {
  type: StoreActionType.UPLOAD_UPDATE_VERSION;
  idVersion: string | number;
}

export type StoreVersionManagementActions =
  | GetVersionList
  | CreateUpdateVersion
  | DeleteUpdateVersion
  | UploadUpdateVersion
  | ResetVersionList
  | SetLoadingVersionList
  | UploadUpdateVersionProgress
  | RemoveUpdateVersionProgress;

interface IStore {
  loading: boolean;
  uploadProgress: {
    [fileName: string]: {
      progress: number;
      state: UploadingState;
    };
  };
  data: IVersionManagement[];
}

const versionManagement = (
  store: IStore = { loading: false, uploadProgress: {}, data: [] },
  action: StoreVersionManagementActions,
): IStore => {
  switch (action.type) {
    case StoreActionType.GET_UPDATE_VERSIONS_LIST: {
      return {
        ...store,
        loading: false,
        data: action.response.sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix(),
        ),
      };
    }

    case StoreActionType.CREATE_UPDATE_VERSION: {
      return {
        ...store,
        loading: false,
        data: store.data.concat(action.response),
      };
    }

    case StoreActionType.DELETE_UPDATE_VERSION: {
      return {
        ...store,
        data: store.data.filter((item) => item.id !== action.idVersion),
      };
    }

    case StoreActionType.RESET_UPDATE_VERSIONS_LIST: {
      return { ...store, data: [] };
    }

    case StoreActionType.UPDATE_SET_LOADING: {
      return { ...store, loading: action.loading };
    }

    case StoreActionType.UPLOAD_UPDATE_VERSION_PROGRESS: {
      return {
        ...store,
        uploadProgress: {
          ...store.uploadProgress,
          [action.payload.fileName]: {
            progress:
              action.payload.progress ??
              store.uploadProgress[action.payload.fileName].progress,
            state: action.payload.state,
          },
        },
      };
    }

    case StoreActionType.REMOVE_UPDATE_VERSION_PROGRESS: {
      const { [action.payload.fileName]: fileName, ...rest } =
        store.uploadProgress;

      return {
        ...store,
        uploadProgress: rest,
      };
    }

    default: {
      return store;
    }
  }
};

export default versionManagement;
