import { IGeofenceData } from 'constants/MapBox/MapBox';
import { StoreActionType } from 'constants/storeActionType';
import { List, Map } from 'immutable';
import { AnyAction } from 'redux';

export type StoreLocationConfigs = Map<string, List<IGeofenceData>>;

interface GetGeofences extends AnyAction {
  type: StoreActionType.GET_GEOFENCES;
  domain: string;
  data: IGeofenceData[];
}

interface AddGeofence extends AnyAction {
  type: StoreActionType.ADD_GEOFENCE;
  domain: string;
  data: IGeofenceData;
}

interface DeleteGeofence extends AnyAction {
  type: StoreActionType.DELETE_GEOFENCE;
  domain: string;
  id: IGeofenceData['id'];
}

interface UpdateGeofence extends AnyAction {
  type: StoreActionType.UPDATE_GEOFENCE;
  domain: string;
  data: IGeofenceData;
}

export type StoreGeofencesActions =
  | GetGeofences
  | UpdateGeofence
  | AddGeofence
  | DeleteGeofence;

const initialState = (): Map<string, List<IGeofenceData>> => Map({});

const geofences = (
  store: StoreLocationConfigs = initialState(),
  action: StoreGeofencesActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_GEOFENCES: {
      return store.set(action.domain, List(action.data));
    }

    case StoreActionType.ADD_GEOFENCE: {
      const section = store.get(action.domain);

      if (section) {
        if (section.findIndex((gf) => gf?.id === action.data?.id) !== -1) {
          return store;
        }

        return store?.set(action.domain, section.push(action.data));
      }

      return store;
    }

    case StoreActionType.DELETE_GEOFENCE: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.id);

        if (index !== -1) {
          return store?.removeIn([action.domain, index]);
        }
      }

      return store;
    }

    case StoreActionType.UPDATE_GEOFENCE: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.data.id);

        if (index !== -1) {
          return store.setIn([action.domain, index], action.data);
        }
      }
      return store;
    }

    default: {
      return store;
    }
  }
};

export default geofences;
