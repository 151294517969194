import { toLocaleString } from 'helpers/translator';
import { ThunkResult } from 'constants/commonTypes';
import { naturalSort } from 'helpers/naturalSort';
import { commonHelpers } from 'helpers/commonHelpers';
import { StoreActionType } from 'constants/storeActionType';
import { privateClient } from 'helpers/client';
import { Fleet } from '../fleets';

import {
  setLoading,
  setNewNotification,
  setSelectedRows,
  setShowModal,
} from './othersController';

export const getFleets =
  (domain: string): ThunkResult<void> =>
  (dispatch, getState, { api }) => {
    const { currentDomain } = getState().others;

    privateClient
      .get(`${api}/api/v1/domains/${domain}/fleets`)
      .then((response) => {
        const data = response.map((item: any) => {
          const temp = { ...item };

          if (temp?.users?.users.length) {
            temp.users.users = commonHelpers.findTitleDuplicatesAndFix(
              naturalSort(temp.users.users, 'title'),
              currentDomain,
            );
          }

          return temp;
        });
        dispatch({
          type: StoreActionType.GET_FLEETS,
          data: naturalSort(data, 'title'),
          domain,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getFleets error: ', error));
  };

export const createFleet =
  (
    domain: string,
    fleetForm: Fleet,
    successCallBack?: Function,
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .post(`${api}/api/v1/domains/${domain}/fleets`, {
        domain,
        ...fleetForm,
      })
      .then(() => {
        dispatch(getFleets(domain));
        dispatch(setSelectedRows([]));
        if (successCallBack) {
          successCallBack();
        }
        dispatch(
          setNewNotification('success', toLocaleString('fleet_create_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('createFleet error', error);
        dispatch(
          setNewNotification('error', toLocaleString('fleet_create_error')),
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };

export const updateFleet =
  (
    domain: string,
    fleet: string,
    fleetForm: Fleet,
    successCallBack?: Function,
  ): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .put(`${api}/api/v1/domains/${domain}/fleets/${fleet}`, {
        domain,
        ...fleetForm,
      })
      .then(() => {
        dispatch(getFleets(domain));
        if (successCallBack) {
          successCallBack();
        }
        dispatch(
          setNewNotification('success', toLocaleString('fleet_update_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('updateFleet error', error);
        dispatch(
          setNewNotification('error', toLocaleString('fleet_update_error')),
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };

export const deleteFleet =
  (domain: string, fleet: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .delete(`${api}/api/v1/domains/${domain}/fleets/${fleet}`)
      .then(() => {
        dispatch({
          type: StoreActionType.DELETE_FLEET,
          domain,
          fleet,
        });
        dispatch(
          setNewNotification('success', toLocaleString('fleet_delete_success')),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('deleteFleet error', error);
        dispatch(
          setNewNotification('error', toLocaleString('fleet_delete_error')),
        );
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };

export const setPrivateInFleet =
  (domain: string, fleetKey: string, fleetName: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .put(`${api}/api/v1/domains/${domain}/fleets/${fleetKey}/private`)
      .then(() => {
        dispatch(
          setNewNotification(
            'success',
            `${fleetName} ${toLocaleString('fleet_update_success')}`,
          ),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('resetPrivateInFleet error', error);
        dispatch(
          setNewNotification(
            'error',
            `${fleetName} ${toLocaleString('fleet_update_error')}`,
          ),
        );
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };

export const resetPrivateInFleet =
  (domain: string, fleetKey: string, fleetName: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .delete(`${api}/api/v1/domains/${domain}/fleets/${fleetKey}/private`)
      .then(() => {
        dispatch(
          setNewNotification(
            'success',
            `${fleetName} ${toLocaleString('fleet_update_success')}`,
          ),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('resetPrivateInFleet error', error);
        dispatch(
          setNewNotification(
            'error',
            `${fleetName} ${toLocaleString('fleet_update_error')}`,
          ),
        );
      })
      .finally(() => {
        dispatch(setShowModal(false));
        dispatch(setLoading(false));
      });
  };

export const getFleetScheme =
  (domain: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/fleets/scheme`)
      .then((response) => {
        const scheme = response;
        dispatch({ type: StoreActionType.SET_SCHEME_FLEET, domain, scheme });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error('getFleetScheme error: ', error));
  };

export const setFleetValues =
  (selectedUsers: string[]): ThunkResult<void> =>
  (dispatch) => {
    dispatch({ type: StoreActionType.SET_FLEET_VALUES, selectedUsers });
  };

export const resetFleets = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_FLEETS });
};
