import { useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeNotification,
  setCurrentPage,
} from 'store/actions/othersController';
import { ReduxStore, useSelectorStore } from 'store';
import { useHistory } from 'react-router-dom';
import { DomainTabName } from 'constants/commonEnum';
import { callHistoryActions } from 'store/callHistory/actions';
import { commonHelpers } from 'helpers/commonHelpers';
import { getHighlightedWriterSessionId } from 'store/__selectors/callHistorySelectors';

export type NotificationTypes = 'success' | 'error' | 'info' | 'warning';
export enum ENotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

enum NotificationDuration {
  ENDLESS = 0,
  STANDARD = 4.5,
}

const closableNotification = 'closable';

const Notification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastNotification = useSelector(
    (store: ReduxStore) => store.others.lastNotification,
  );
  const highlightedWriterSessionId = useSelectorStore((state) =>
    getHighlightedWriterSessionId(
      state,
      lastNotification?.options?.callPreparing.domain ?? '',
    ),
  );

  const handleCloseNotification = (text: string, type: NotificationTypes) => {
    dispatch(removeNotification(type, text));
  };

  useEffect(() => {
    if (
      highlightedWriterSessionId &&
      lastNotification?.options?.callPreparing
    ) {
      history.push(
        `/tc/domain/${lastNotification.options.callPreparing.domain}/${DomainTabName.CALL_HISTORY}`,
      );
      dispatch(setCurrentPage(DomainTabName.CALL_HISTORY));
      handleCloseNotification(lastNotification.message, lastNotification.type);
    }
  }, [highlightedWriterSessionId, lastNotification]);

  useEffect(() => {
    if (lastNotification) {
      const key =
        lastNotification.options?.callPreparing?.writerSessionId ??
        commonHelpers.generateUuidv4();

      notification[lastNotification.type]({
        key,
        message: lastNotification.message,
        className: lastNotification.options && closableNotification,
        duration: lastNotification.options
          ? NotificationDuration.ENDLESS
          : NotificationDuration.STANDARD,
        onClose: () =>
          handleCloseNotification(
            lastNotification.message,
            lastNotification.type,
          ),
        onClick: () => {
          const { options } = lastNotification;

          if (options) {
            if (options.callPreparing) {
              dispatch(
                callHistoryActions.selectCallDetailsIdByWriterSessionId(
                  options.callPreparing.domain,
                  options.callPreparing.writerSessionId,
                ),
              );
              notification.close(key);
            }
          }
        },
      });
    }
  }, [lastNotification]);

  return null;
};

export default Notification;
