import { AnyAction } from 'redux';
import { List } from 'immutable';
import { StoreActionType } from 'constants/storeActionType';

export type StoreMaintenances = IApiDocs;

export interface ISwaggerListData {
  label: string;
  swagger_url: string;
}
interface IApiDocs {
  data: List<ISwaggerListData>[];
  loading: boolean;
}

interface GetSwaggerUrlList extends AnyAction {
  type: StoreActionType.GET_SWAGGER_URL_LIST;
  data: IApiDocs[];
}

interface LoadingSwaggerUrlList extends AnyAction {
  type: StoreActionType.MAINTENANCE_SET_LOADING;
}

interface ResetApiDocs extends AnyAction {
  type: StoreActionType.RESET_SWAGGER_LIST;
}

export type StoreMaintenanceActions =
  | GetSwaggerUrlList
  | ResetApiDocs
  | LoadingSwaggerUrlList;

const apiDocs = (
  store: {
    loading: boolean;
    data: IApiDocs[];
  } = { loading: false, data: [] },
  action: StoreMaintenanceActions,
) => {
  switch (action.type) {
    case StoreActionType.MAINTENANCE_SET_LOADING: {
      return { ...store, loading: true };
    }

    case StoreActionType.GET_SWAGGER_URL_LIST: {
      return { loading: false, data: action.data };
    }

    case StoreActionType.RESET_SWAGGER_LIST: {
      return { ...store, loading: false, data: [] };
    }

    default: {
      return store;
    }
  }
};

export default apiDocs;
