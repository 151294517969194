import store from 'store/store';
import reactStringReplace from 'react-string-replace';
import { useHistory } from 'react-router-dom';
import { currentTab } from 'constants/localStorage';
import i18next from 'i18next';
import { StoreActionType } from 'constants/storeActionType';
import { Language } from 'i18n/config';

export interface ToLocaleStringParams {
  history: ReturnType<typeof useHistory>;
}

interface StringParams {
  type: 'link';
  page?: string;
}

export const toLocaleString = (
  string: string,
  params?: ToLocaleStringParams,
): any => {
  try {
    const localeString = parseNestedStrings(i18next.t(string), params);

    if (localeString) {
      return localeString;
    }

    return string;
  } catch (error) {
    return string;
  }
};

export const getBrowserLanguage = () => {
  let language =
    window?.navigator?.language.slice(0, 2).toLowerCase() ?? Language.EN;

  if (!Object.values(Language).includes(language as Language)) {
    language = Language.EN;
  }

  return language as Language;
};

/**
 * If string has nested structure, parse the string contained within all square brackets [...]
 * if you want to wrap the string in jsx, then define params after double colon
 */
export const parseNestedStrings = (
  str: string,
  params?: ToLocaleStringParams,
) => {
  const getNestedStr = (s: string) => {
    const match = (s.match(/\[(.*?)\]/) as any[]) ?? []; // output: ['[str]', 'str']
    let stringParams: StringParams | null = null;
    let clean = '';

    if (match.length === 0) {
      return null;
    }

    try {
      [clean, stringParams] = match[1].split('::');
      stringParams = JSON.parse(String(stringParams)) ?? {};
    } catch (e) {
      //
    }

    return {
      clean,
      withBrackets: match[0],
      params: stringParams,
    };
  };

  const nestedStr = getNestedStr(str);

  if (!nestedStr) {
    return str;
  }

  if (nestedStr.params?.type) {
    return (
      <>
        {reactStringReplace(str, nestedStr.withBrackets, (match, i) => {
          switch (nestedStr.params?.type) {
            case 'link': {
              return (
                <a
                  href='/'
                  key={match + i}
                  onClick={(e) => {
                    e.preventDefault();
                    store.dispatch({
                      type: StoreActionType.SET_SHOW_DRAWER,
                      show_drawer: false,
                    });

                    if (typeof nestedStr.params?.page === 'string') {
                      store.dispatch({
                        type: StoreActionType.SET_CURRENT_PAGE,
                        page: nestedStr.params.page,
                      });
                      params?.history.push(nestedStr.params.page);
                      localStorage.setItem(currentTab, nestedStr.params.page);
                    }
                  }}
                >
                  {toLocaleString(nestedStr.clean)}
                </a>
              );
            }
            default:
              return null;
          }
        })}
      </>
    );
  }

  return str.replace(nestedStr.withBrackets, toLocaleString(nestedStr.clean));
};
