import { ThunkResult } from 'constants/commonTypes';
import { StoreActionType } from 'constants/storeActionType';
import { privateClient } from 'helpers/client';

const clearSchemes = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_SCHEMES });
  dispatch({ type: StoreActionType.CLEAR_USERS_CONFIG_SCHEME });
};

const getDomainScheme =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/scheme`)
      .then((dataResponse) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_DOMAIN,
          data:
            typeof dataResponse === 'object'
              ? {
                  ...dataResponse,
                  properties: {
                    ...dataResponse.properties,
                    name: {
                      ...dataResponse.properties.name,
                      view: {
                        ...dataResponse.properties.name.view,
                        disabled: true,
                      },
                    },
                  },
                }
              : dataResponse,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getDomainScheme error', err));
  };

const getSchemeProfileData =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/domain/users/user/profile/mcdata/scheme`)
      .then((data) => {
        dispatch({ type: StoreActionType.SET_SCHEME_DATA, data });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileData error', err));
  };

const getSchemeProfilePTT =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/domain/users/user/profile/mcptt/scheme`)
      .then((data) => {
        dispatch({ type: StoreActionType.SET_SCHEME_PTT, data });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfilePTT error', err));
  };

const getSchemeProfileVideo =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/domain/users/user/profile/mcvideo/scheme`)
      .then((data) => {
        dispatch({ type: StoreActionType.SET_SCHEME_VIDEO, data });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileVideo error', err));
  };

const getSchemeProfileAdvancedSettings =
  (domain: string, user: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(
        `${api}/api/v1/domains/${domain}/users/${user}/profile/tassta/scheme`,
      )
      .then((data) => {
        dispatch({ type: StoreActionType.SET_SCHEME_ADVANCED_SETTINGS, data });
      })
      .catch((err) =>
        // eslint-disable-next-line no-console
        console.error('getSchemeProfileAdvancedSettings error', err),
      );
  };

const getSchemeProfileBatchAdvancedSettings =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(
        `${api}/api/v1/domains/domain/users/user/profile/tassta/scheme_batch`,
      )
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_BATCH_ADVANCED_SETTINGS,
          data,
        });
      })
      .catch((err) =>
        // eslint-disable-next-line no-console
        console.error('getSchemeProfileBatchAdvancedSettings error', err),
      );
  };

const getSchemeProfileBatchData =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(
        `${api}/api/v1/domains/domain/users/user/profile/mcdata/scheme_batch`,
      )
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_BATCH_DATA,
          data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileBatchData error', err));
  };

const getSchemeProfileBatchPTT =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/domain/users/user/profile/mcptt/scheme_batch`)
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_BATCH_PTT,
          data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileBatchPTT error', err));
  };

const getSchemeProfileBatchVideo =
  (): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(
        `${api}/api/v1/domains/domain/users/user/profile/mcvideo/scheme_batch`,
      )
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_BATCH_VIDEO,
          data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileBatchVideo error', err));
  };

const getSchemeProfileLWP =
  (domain: string, user: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(`${api}/api/v1/domains/${domain}/users/${user}/profile/lwp/scheme`)
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_LWP,
          data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileLWP error', err));
  };

const getSchemeProfileBatchLWP =
  (domain: string, user: string): ThunkResult<void> =>
  (dispatch, _, { api }) => {
    privateClient
      .get(
        `${api}/api/v1/domains/${domain}/users/${user}/profile/lwp/scheme_batch`,
      )
      .then((data) => {
        dispatch({
          type: StoreActionType.SET_SCHEME_BATCH_LWP,
          data,
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('getSchemeProfileBatchLWP error', err));
  };

const resetSchemes = (): ThunkResult<void> => (dispatch) => {
  dispatch({ type: StoreActionType.RESET_SCHEMES });
};

export {
  clearSchemes,
  getDomainScheme,
  getSchemeProfileData,
  getSchemeProfilePTT,
  getSchemeProfileVideo,
  getSchemeProfileAdvancedSettings,
  getSchemeProfileBatchAdvancedSettings,
  getSchemeProfileBatchData,
  getSchemeProfileBatchPTT,
  getSchemeProfileBatchVideo,
  getSchemeProfileLWP,
  getSchemeProfileBatchLWP,
  resetSchemes,
};
