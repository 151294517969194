import { IGeofenceData, WPGT } from 'constants/MapBox/MapBox';
import { StoreActionType } from 'constants/storeActionType';
import { List, Map } from 'immutable';
import { AnyAction } from 'redux';

export type StoreWaypoints = {
  waypoints: Map<string, List<WPGT.WaypointModel>>;
  guardtours: Map<string, List<WPGT.GuardTourModel>>;
  guardtours_schedules: Map<string, List<WPGT.GTSchedule>>;
  undo: { [domain: string]: IGeofenceData[] | null };
  redo: { [domain: string]: IGeofenceData[] | null };
};

interface SetWaypointsList extends AnyAction {
  type: StoreActionType.SET_WAYPOINTS_LIST;
  domain: string;
  data: WPGT.WaypointModel[];
}

interface UpdateWaypoint extends AnyAction {
  type: StoreActionType.UPDATE_WAYPOINT;
  domain: string;
  data: WPGT.WaypointModel;
}

interface AddWaypoint extends AnyAction {
  type: StoreActionType.ADD_WAYPOINT;
  domain: string;
  data: WPGT.WaypointModel;
}

interface DeleteWaypoint extends AnyAction {
  type: StoreActionType.DELETE_WAYPOINT;
  domain: string;
  data: WPGT.WaypointModel;
}

interface SetGuardToursList extends AnyAction {
  type: StoreActionType.SET_GUARD_TOUR_LIST;
  domain: string;
  data: WPGT.GuardTourModel[];
}

interface UpdateGuardTour extends AnyAction {
  type: StoreActionType.UPDATE_GUARD_TOUR;
  domain: string;
  data: WPGT.GuardTourModel;
}

interface AddGuardTour extends AnyAction {
  type: StoreActionType.ADD_GUARD_TOUR;
  domain: string;
  data: WPGT.GuardTourModel;
}

interface DeleteGuardTour extends AnyAction {
  type: StoreActionType.DELETE_GUARD_TOUR;
  domain: string;
  data: WPGT.GuardTourModel;
}

interface GuardTourStatus extends AnyAction {
  type: StoreActionType.SET_GUARD_TOUR_STATUS;
  domain: string;
  status: WPGT.GTStatus;
  id: WPGT.GuardTourModel['id'];
}

interface GuardTourPointStatus extends AnyAction {
  type: StoreActionType.SET_GUARD_TOUR_POINT_STATUS;
  domain: string;
  status: WPGT.GTPointStatus;
  guardTourId: WPGT.GuardTourModel['id'];
  pointId: WPGT.GTPoint['pointNum'];
}

interface GuardTourPointStatusFinished extends AnyAction {
  type: StoreActionType.SET_GUARD_TOUR_POINT_STATUS;
  domain: string;
  guardTourId: WPGT.GuardTourModel['id'];
  isFinished: boolean;
}

interface SetSchedulesList extends AnyAction {
  type: StoreActionType.SET_SCHEDULES_LIST;
  domain: string;
  data: WPGT.GTSchedule[];
}

interface UpdateSchedule extends AnyAction {
  type: StoreActionType.UPDATE_SCHEDULE;
  domain: string;
  data: WPGT.GTSchedule;
}

interface AddSchedule extends AnyAction {
  type: StoreActionType.ADD_SCHEDULE;
  domain: string;
  data: WPGT.GTSchedule;
}

interface DeleteSchedule extends AnyAction {
  type: StoreActionType.DELETE_SCHEDULE;
  domain: string;
  id: WPGT.GTSchedule['id'];
}

interface SetUndoFeatures extends AnyAction {
  type: StoreActionType.SET_UNDO_FEATURES;
  payload: {
    allFeatures: IGeofenceData[];
    domain: string;
  };
}

interface SetRedoFeatures extends AnyAction {
  type: StoreActionType.SET_REDO_FEATURES;
  payload: {
    allFeatures: IGeofenceData[] | null;
    domain: string;
  };
}

export type StoreWaypointsActions =
  | SetWaypointsList
  | UpdateWaypoint
  | AddWaypoint
  | DeleteWaypoint
  | SetGuardToursList
  | UpdateGuardTour
  | AddGuardTour
  | DeleteGuardTour
  | GuardTourStatus
  | GuardTourPointStatus
  | GuardTourPointStatusFinished
  | SetSchedulesList
  | UpdateSchedule
  | AddSchedule
  | DeleteSchedule
  | SetUndoFeatures
  | SetRedoFeatures;

const initialState = (): any => ({
  waypoints: Map({}),
  guardtours: Map({}),
  guardtours_schedules: Map({}),
  undo: null,
});

const map = (
  store: StoreWaypoints = initialState(),
  action: StoreWaypointsActions,
) => {
  switch (action.type) {
    case StoreActionType.SET_WAYPOINTS_LIST: {
      const list = action.data.map((wp: any) => {
        try {
          return {
            ...wp,
            line: JSON.parse(wp.line),
            points: JSON.parse(wp.points),
          };
        } catch {
          return wp;
        }
      });

      const waypoints = store.waypoints?.set(action.domain, List(list));

      return {
        ...store,
        waypoints,
      };
    }

    case StoreActionType.UPDATE_WAYPOINT: {
      const section = store.waypoints?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.data.id);

        if (index !== -1) {
          const waypoints = store.waypoints?.setIn([action.domain, index], {
            ...action.data,
            // @ts-ignore
            line: JSON.parse(action.data.line),
            // @ts-ignore
            points: JSON.parse(action.data.points),
          });

          return {
            ...store,
            waypoints,
          };
        }
      }
      return store;
    }

    case StoreActionType.ADD_WAYPOINT: {
      const section = store.waypoints?.get(action.domain);

      try {
        if (section) {
          if (section.findIndex((gt) => gt?.id === action.data?.id) !== -1) {
            return store;
          }

          const waypoints = store.waypoints?.set(
            action.domain,
            section.push({
              ...action.data,
              // @ts-ignore
              line: JSON.parse(action.data.line),
              // @ts-ignore
              points: JSON.parse(action.data.points),
            }),
          );

          return {
            ...store,
            waypoints,
          };
        }
      } catch {
        return store;
      }

      return store;
    }

    case StoreActionType.DELETE_WAYPOINT: {
      const section = store.waypoints?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.data.id);

        if (index !== -1) {
          const waypoints = store.waypoints?.removeIn([action.domain, index]);

          return {
            ...store,
            waypoints,
          };
        }
      }

      return store;
    }

    case StoreActionType.SET_GUARD_TOUR_LIST: {
      const list = action.data.map((gt: any) => {
        try {
          return {
            ...gt,
            line: JSON.parse(gt.line),
            points: JSON.parse(gt.points),
          };
        } catch {
          return gt;
        }
      });

      const guardtours = store.guardtours?.set(action.domain, List(list));

      return {
        ...store,
        guardtours,
      };
    }

    case StoreActionType.UPDATE_GUARD_TOUR: {
      const section = store.guardtours?.get(action.domain);

      try {
        if (section) {
          const index = section.findKey((item) => item.id === action.data.id);

          if (index !== -1) {
            const guardtours = store.guardtours?.setIn([action.domain, index], {
              ...action.data,
              // @ts-ignore
              line: JSON.parse(action.data.line),
              // @ts-ignore
              points: JSON.parse(action.data.points),
            });

            return {
              ...store,
              guardtours,
            };
          }
        }
      } catch {
        return store;
      }

      return store;
    }

    case StoreActionType.ADD_GUARD_TOUR: {
      const section = store.guardtours?.get(action.domain);

      try {
        if (section) {
          if (section.findIndex((gt) => gt?.id === action.data?.id) !== -1) {
            return store;
          }

          const guardtours = store.guardtours?.set(
            action.domain,
            section.push({
              ...action.data,
              // @ts-ignore
              line: JSON.parse(action.data.line),
              // @ts-ignore
              points: JSON.parse(action.data.points),
            }),
          );

          return {
            ...store,
            guardtours,
          };
        }
      } catch (e) {
        return store;
      }

      return store;
    }

    case StoreActionType.DELETE_GUARD_TOUR: {
      const section = store.guardtours?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.data.id);

        if (index !== -1) {
          const guardtours = store.guardtours?.removeIn([action.domain, index]);

          return {
            ...store,
            guardtours,
          };
        }
      }

      return store;
    }

    case StoreActionType.SET_GUARD_TOUR_STATUS: {
      const section = store.guardtours?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.id);

        if (index !== -1) {
          const guardtours = store.guardtours?.updateIn(
            [action.domain, index],
            (gt: any) => ({ ...gt, status: action.status }),
          );

          return {
            ...store,
            guardtours,
          };
        }
      }

      return store;
    }

    case StoreActionType.SET_GUARD_TOUR_POINT_STATUS: {
      const section = store.guardtours?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.guardTourId);

        if (index !== -1) {
          const gtPoints = store.guardtours?.getIn([
            action.domain,
            index,
            'points',
          ]) as WPGT.GTPoint[];

          if (gtPoints) {
            if (!action.isFinished) {
              const pointIndex = gtPoints.findIndex(
                (item) => item.pointNum === action.pointId,
              );

              if (pointIndex !== -1) {
                const guardtours = store.guardtours?.updateIn(
                  [action.domain, index, 'points', pointIndex],
                  (p: any) => ({ ...p, status: action.status }),
                );

                return {
                  ...store,
                  guardtours,
                };
              }
            } else {
              const gtPointsMapped = gtPoints.map((p) => ({
                ...p,
                status: WPGT.GTPointStatus.VISITED,
              }));
              const guardtours = store.guardtours?.setIn(
                [action.domain, index, 'points'],
                gtPointsMapped,
              );

              return {
                ...store,
                guardtours,
              };
            }
          }
        }
      }

      return store;
    }

    case StoreActionType.SET_SCHEDULES_LIST: {
      const gеSchedules = store.guardtours_schedules?.set(
        action.domain,
        List(action.data),
      );

      return {
        ...store,
        guardtours_schedules: gеSchedules,
      };
    }

    case StoreActionType.UPDATE_SCHEDULE: {
      const section = store.guardtours_schedules?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.data.id);

        if (index !== -1) {
          const gtSchedules = store.guardtours_schedules?.setIn(
            [action.domain, index],
            action.data,
          );

          return {
            ...store,
            guardtours_schedules: gtSchedules,
          };
        }
      }
      return store;
    }

    case StoreActionType.ADD_SCHEDULE: {
      const section = store.guardtours_schedules.get(action.domain);

      if (section) {
        const gtSchedules = store.guardtours_schedules?.set(
          action.domain,
          section!.push(action.data),
        );

        return {
          ...store,
          guardtours_schedules: gtSchedules,
        };
      }

      return store;
    }

    case StoreActionType.DELETE_SCHEDULE: {
      const section = store.guardtours_schedules?.get(action.domain);

      if (section) {
        const index = section.findKey((item) => item.id === action.id);

        if (index !== -1) {
          const gtSchedules = store.guardtours_schedules?.deleteIn([
            action.domain,
            index,
          ]);

          return {
            ...store,
            guardtours_schedules: gtSchedules,
          };
        }
      }
      return store;
    }

    case StoreActionType.SET_UNDO_FEATURES: {
      const undo = {
        ...store.undo,
        [action.payload.domain]: action.payload.allFeatures,
      };

      const redo = {
        ...store.redo,
        [action.payload.domain]: null,
      };

      return { ...store, undo, redo };
    }

    case StoreActionType.SET_REDO_FEATURES: {
      const undo = {
        ...store.undo,
        [action.payload.domain]: null,
      };

      const redo = {
        ...store.redo,
        [action.payload.domain]: action.payload.allFeatures,
      };

      return { ...store, undo, redo };
    }

    default: {
      return store;
    }
  }
};

export default map;
