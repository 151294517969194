import { toLocaleString } from 'helpers/translator';
import {
  GeographicalAreaChangeTypeEnum,
  MCPTTSignalEventTypeEnum,
  TriggerTypesEnum,
} from './commonEnum';
import { IMcpttSignalEventTypes, ITriggerTypes } from './commonTypes';

export const applications = [
  { label: toLocaleString('tstar_for_android'), value: 'tfax' },
  { label: toLocaleString('tgalaxy_for_ms_windows'), value: 'galaxywin' },
  { label: toLocaleString('tgalaxy_for_linux'), value: 'galaxylinux' },
  { label: toLocaleString('tgalaxy_for_os_x'), value: 'galaxydarwin' },
];

export const branches = [
  { label: toLocaleString('release'), value: 'release' },
  { label: toLocaleString('dev'), value: 'dev' },
  { label: toLocaleString('debug'), value: 'debug' },
];

export const triggerTypes: ITriggerTypes[] = [
  {
    label: toLocaleString('geographical_area_change2'),
    value: TriggerTypesEnum.GeographicalArea,
  },
  {
    label: toLocaleString('periodic_report'),
    value: TriggerTypesEnum.PeriodicReport,
  },
  {
    label: toLocaleString('traveled_distance'),
    value: TriggerTypesEnum.TraveledDistance,
  },
  {
    label: toLocaleString('mcptt_signal_event2'),
    value: TriggerTypesEnum.MCPTTSignalEvent,
  },
];

export const mcpttSignalEventTypes: IMcpttSignalEventTypes[] = [
  {
    label: toLocaleString('functional_alias_activation'),
    value: MCPTTSignalEventTypeEnum.FunctionalAliasActivation,
  },
  {
    label: toLocaleString('functional_alias_deactivation'),
    value: MCPTTSignalEventTypeEnum.FunctionalAliasDeactivation,
  },
  {
    label: toLocaleString('location_configuration_received'),
    value: MCPTTSignalEventTypeEnum.LocationConfigurationReceived,
  },
  {
    label: toLocaleString('group_call'),
    value: MCPTTSignalEventTypeEnum.GroupCall,
  },
  {
    label: toLocaleString('private_call'),
    value: MCPTTSignalEventTypeEnum.PrivateCall,
  },
];

export const geographicalAreaChangeType: {
  label: string;
  value: GeographicalAreaChangeTypeEnum;
}[] = [
  // this type don't support now, may be will be need later
  // {
  //   label: 'Any geographical area change',
  //   value: GeographicalAreaChangeTypeEnum.Any,
  // },
  {
    label: toLocaleString('enter_specific_area'),
    value: GeographicalAreaChangeTypeEnum.Enter,
  },
  {
    label: toLocaleString('exit_specific_area'),
    value: GeographicalAreaChangeTypeEnum.Exit,
  },
];

export const BASIC_AUTH_TOKEN = process.env.REACT_APP_BASIC_AUTH_TOKEN;

export enum SidebarPanel {
  DOMAINS = 'domains',
  MAINTENANCE = 'maintenance',
}

export enum MaintenancePage {
  API_DOCUMENTATIONS = 'api-documentations',
  VERSION_MANAGEMENT = 'version-management',
  BACKUP_MANAGEMENT = 'backup-management',
  LMR_GATEWAYS = 'lmr-gateways',
}

export const durationPlaceholder = '4d 6h 45m 10s';

export const TARGET_BASE_URL =
  process.env.REACT_APP_TARGET_BASE_URL || 'https://moon.tassta.com';
