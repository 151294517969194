import { toLocaleString } from 'helpers/translator';
import { RGBColor } from 'react-color';
import { FormikErrors } from 'formik';

export enum MapDrawMode {
  SIMPLE_SELECT = 'simple_select',
  DIRECT_SELECT = 'direct_select',
  DRAW_POLYGON = 'draw_polygon',
  DRAW_WAYPOINT = 'draw_waypoint',
  DRAW_GUARDTOUR = 'draw_guardtour',
  DRAW_CIRCLE = 'drag_circle',
  ADD_POINT = 'add_point',
}

export enum MapToolsFeatureTypeEnum {
  LineString = 'LineString',
  Point = 'Point',
  Polygon = 'Polygon',
  Rectangle = 'Rectangle',
  Circle = 'Circle',
}

export enum MapToolsAdvancedFeatureType {
  Waypoint = 'Waypoint',
  GuardTour = 'GuardTour',
}

export enum PointPropsSubTabEnum {
  General = 'general-tab',
  Points = 'points-tab',
  Schedule = 'schedule-tab',
}

export const getGeofencesDrawTools = (): IModes[] => [
  {
    id: MapDrawMode.DRAW_POLYGON,
    text: toLocaleString('add_polygon_geofence'),
  },
  {
    id: MapDrawMode.DRAW_CIRCLE,
    text: toLocaleString('add_circle_geofence'),
  },
];

export const getWaypointsDrawTools = (): IModes[] => [
  {
    id: MapDrawMode.DRAW_WAYPOINT,
    text: toLocaleString('add_route'),
  },
];

export const getGuardToursDrawTools = (): IModes[] => [
  {
    id: MapDrawMode.DRAW_GUARDTOUR,
    text: toLocaleString('add_guard_tour'),
  },
];

export const DEFAULT_VIEWPORT = {
  longitude: 4.1,
  latitude: 51,
  zoom: 3.2,
};

export const getDefaultFeatureCssProperties = (): IFeatureProperties => ({
  name: toLocaleString('new_geofence'),
  cssProperties: {
    fill: '#3bb2d0',
    fillOpacity: 0.1,
    stroke: '#3bb2d0',
    strokeOpacity: 1,
    strokeWidth: 2,
  },
});

export enum GuardTourLineStatusColorEnum {
  NOT_STARTED = '#9c9a9a',
  STARTED = '#1f8efa',
  ACTIVE = '#1f7ae6',
  SKIPPED = '#ec1c24',
  PAUSE = '#f0bc27',
  COMPLETED = '#279954',
}

export enum GuardTourPointIconsEnum {
  INACTIVE = 'inactive',
  PAUSE = 'pause',
  SKIP = 'skip',
  START = 'start',
  COMPLETE = 'complete',
  ACTIVE = 'active',
  SELECTED = 'selected',
}

export enum MapEventsEnum {
  WP_CREATE = 'waypoint_create',
  WP_DELETE = 'waypoint_delete',
  WP_UPDATE = 'waypoint_update',
  GT_CREATE = 'guard_tour_create',
  GT_DELETE = 'guard_tour_delete',
  GT_UPDATE = 'guard_tour_update',
  GT_STARTED = 'guard_tour_started',
  GT_STOPPED = 'guard_tour_stopped',
  GT_PAUSED = 'guard_tour_paused',
  GT_RESUMED = 'guard_tour_resumed',
  GT_FINISHED = 'guard_tour_finished',
  GT_CHECKPOINT = 'guard_tour_checkpoint',
  GT_POINT_SKIPPED = 'guard_tour_point_skipped',
  GT_SCHEDULE_CREATED = 'schedule_create',
  GT_SCHEDULE_DELETE = 'schedule_delete',
  GT_SCHEDULE_UPDATE = 'schedule_update',
}

export enum MapFeatureProperty {
  WAYPOINT_STYLES = 'waypointStyles',
  CSS_STYLES = 'cssStyles',
  DIRTY = 'dirty',
  POINT_INDEX_TO_MOVE = 'pointIndexToMove',
  READ_ONLY = 'readOnly',
  ADVANCED_TYPE = 'advancedType',
}

// mapBox component interfaces
export interface IModes {
  id: MapDrawMode;
  text: string;
}

export interface IMapDrawMode {
  modeId: MapDrawMode | null;
}

export interface IGeofenceData {
  id: number;
  name: string;
  type?: string;
  isClosePopup?: boolean;
  advancedType?: MapToolsAdvancedFeatureType;
  geometry: {
    coordinates: Array<any>;
    type: MapToolsFeatureTypeEnum;
  };
  properties: {
    pointIndexToMove?: number | null;
    advancedType?: MapToolsAdvancedFeatureType;
    cssStyles: ICSSPropertiesSvg;
    waypointStyles?: any;
    guardTourProps?: {
      status: WPGT.GuardTourModel['status'];
      points: WPGT.GTPoint[];
    };
  };
}

export interface IGeofenceStyle {
  index: number;
  feature: IGeofenceData;
}

export interface ICSSPropertiesSvg {
  fill: RGBColor | string;
  stroke: RGBColor | string;
  strokeWidth: number;
  fillOpacity?: number;
  strokeOpacity: number;
}

export interface IFeatureProperties {
  name: string;
  cssProperties: ICSSPropertiesSvg;
}

/** Waypoint and GuardTour namespace */
export namespace WPGT {
  interface Style {
    className: string;
    clickable: boolean;
    color: string;
    dashArray: string | null;
    fill: boolean;
    fillColor: string | RGBColor;
    fillOpacity: number;
    lineCap: string | null;
    lineJoin: string | null;
    opacity: number;
    pointerEvents: string | null;
    stroke: boolean;
    weight: number;
  }

  interface Latlng {
    lat: number;
    lng: number;
  }

  interface Line {
    latlngs: Latlng[];
    name: string;
    style: Style;
  }

  interface PointCircleStyle {
    className: string;
    clickable: boolean;
    color: string;
    dashArray: string;
    fill: boolean;
    fillColor: string;
    fillOpacity: number;
    opacity: number;
    stroke: boolean;
    weight: number;
  }

  interface GTPointCircleStyle extends PointCircleStyle {
    lineCap: string | null;
    lineJoin: string | null;
    pointerEvents: string | null;
  }

  interface XY {
    x: number;
    y: number;
  }

  interface PointIcon {
    color: string;
    html?: string;
    iconAnchor?: XY;
    iconSize?: XY;
  }

  interface PointLatLng {
    lat: number;
    lng: number;
  }

  interface PointStyle {
    className: string;
    clickable: boolean;
    color: string;
    fill: boolean;
    fillColor: string;
    fillOpacity: number;
    opacity: number;
    stroke: boolean;
    weight: number;
  }

  interface GTPointStyle extends PointStyle {
    dashArray: string | null;
    lineCap: string | null;
    lineJoin: string | null;
    pointerEvents: string | null;
  }

  /** Waypoint point */
  export interface Point {
    pointNum?: number;
    pointCircleRadius?: number;
    pointCircleStyle?: PointCircleStyle;
    pointDetail?: string;
    pointFill: string;
    pointIcon: PointIcon;
    pointLabel?: string;
    pointLatLng?: PointLatLng;
    pointName: string;
    pointStyle?: PointStyle;
  }

  /** GuardTour point status */
  export enum GTPointStatus {
    UNVISITED = 'unvisited',
    VISITED = 'visited',
    SKIPPED = 'skipped',
  }

  /** GuardTour point */
  export interface GTPoint extends Point {
    id_data: string;
    status: GTPointStatus;
    delay: number;
    pointStyle: GTPointStyle;
    pointCircleStyle?: GTPointCircleStyle;
  }

  export interface WaypointModel {
    id?: number;
    line: Line;
    points: Point[];
    server_id?: number;
  }

  /** GuardTour status */
  export enum GTStatus {
    STOP = 'stop',
    START = 'start',
    PAUSE = 'pause',
  }

  export interface GuardTourModel {
    id?: number;
    CreatedAt?: string;
    UpdatedAt?: string;
    user_id?: number | null;
    mcx_user_id?: string | null;
    server_id?: number;
    task_id?: number;
    name: string;
    description?: string;
    mode: string;
    accuracy: number;
    line: Line;
    points: GTPoint[];
    status: 'stop' | 'start' | 'pause';
  }

  export interface GTSchedule {
    id?: number;
    key: string;
    start_at: string;
    guard_tour_id: number;
    server_id?: number;
    period: 0 | 1 | 2;
  }
}

export interface IPointTiming {
  key: WPGT.GTPoint['pointNum'];
  route: string;
  timeout: string;
}

export interface PointTabProps {
  handleChange: (
    field: any,
  ) => void | ((e: string | React.ChangeEvent<any>) => void);
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<WPGT.GuardTourModel>>;
  values: WPGT.GuardTourModel;
}

export const isGuardTour = (
  model: WPGT.GuardTourModel | WPGT.WaypointModel,
): model is WPGT.GuardTourModel =>
  (model as WPGT.GuardTourModel).status !== undefined;
