export enum CurrentTabsEnum {
  apiDocumentations = 'ApiDocumentations',
  versionManagement = 'VersionManagement',
  backupManagement = 'BackupManagement',
  LMRGateways = 'LMRGateways',
}

export enum PermissionsEnum {
  API = 'Api',
  BACKUP_DTO = 'BackupDTO',
  DOMAIN_RESTRICTION = 'DomainRestriction',
  ENHANCED_STATUS = 'EnhancedStatus',
  FLEET = 'Fleet',
  GROUP = 'Group',
  LICENSE_INFO = 'LicenseInfo',
  MCDATA_SERVICE_CONFIGURATION = 'McdataServiceConfiguration',
  MCDATA_UE_CONFIGURATION = 'McdataUEConfiguration',
  MCPTT_SERVICE_CONFIGURATION = 'McpttServiceConfiguration',
  MCPTT_UE_CONFIGURATION = 'McpttUEConfiguration',
  MCVIDEO_SERVICE_CONFIGURATION = 'McvideoServiceConfiguration',
  MCVIDEO_UE_CONFIGURATION = 'McvideoUEConfiguration',
  ROLE = 'Role',
  SCRIPT = 'Script',
  TASSTA_SERVER = 'TasstaServer',
  UE_INITIAL_CONFIGURATION = 'UEInitialConfiguration',
  USER = 'User',
  VERSION = 'Version',
  LMR_DATA = 'LmrData',
}

export enum ComponentsIdEnum {
  locationDeleteBtn = 'locationConfig-delete-btn',
  locationEditBtn = 'locationConfig-edit-btn',
  locationDuplicateBtn = 'locationConfig-duplicate-btn',
  locationCreateBtn = 'locationConfig-create-btn',
  locationExportBtn = 'locationConfig-export-btn',
  locationImportBtn = 'locationConfig-import-btn',
  callHistoryExportBtn = 'callHistory-export-btn',
  domainCreateBtn = 'domain-create-btn',
  functionalAliasCreateBtn = 'functional-alias-create-btn',
  functionalAliasUpdateBtn = 'functional-alias-update-btn',
  functionalAliasDeleteBtn = 'functional-alias-delete-btn',
  LMRGatewaysCreateBtn = 'lmr-gateways-create-btn',
  LMRGatewaysUpdateBtn = 'lmr-gateways-update-btn',
  LMRGatewaysDeleteBtn = 'lmr-gateways-delete-btn',
}

export enum PermissionOperationsEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ = 'READ',
}

export enum PageEnum {
  DomainsPage = 'domains',
  CallDetailsPage = 'call_details',
  VersionManagement = 'version_management',
  BackupManagement = 'backup_management',
}

export enum DomainTabName {
  USERS = 'users',
  DASHBOARD = 'dashboard',
  CONFIGURATIONS = 'configurations',
  GROUPS = 'groups',
  FLEETS = 'fleets',
  STATUSES = 'statuses',
  CALL_HISTORY = 'call_history',
  CALL_STATISTIC = 'call_statistic',
  FUNCTIONAL_ALIASES = 'functional_aliases',
  LOCATION_CONFIGURATIONS = 'location_configurations',
  MAP_TOOLS = 'map_tools',
}

export enum UserSubTabEnum {
  UserSettingsTab = 'user-settings-tab',
  UserAdvancedSettingsTab = 'user-advanced-settings-tab',
  McdataTab = 'mcdata-tab',
  McpttTab = 'mcptt-tab',
  McvideoTab = 'mcvideo-tab',
  LwpTab = 'lwp-tab',
}

export enum EGroupSubTab {
  GENERAL = 'general-tab',
  GEOFENCING = 'geofencing-tab',
  ADVANCED_SETTINGS = 'advanced-settings-tab',
}

export enum EFunctionalAliasSubTab {
  General = 'general-tab',
  Geofencing = 'geofencing-tab',
}

export enum MapToolbarSubTabEnum {
  Geofences = 'geofences-tab',
  Waypoints = 'waypoints-tab',
  GuardTours = 'guard_tours-tab',
}

export enum ApiStatusEnum {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  TOKEN_EXPIRED = 419,
  SERVER_ERROR = 500,
}

export enum DrawerPanesEnum {
  NewDomain = 'new_domain',
  NewUser = 'new_user',
  NewUsers = 'new_users',
  NewGroup = 'new_group',
  NewFleet = 'new_fleet',
  NewFunctionalAlias = 'new_functional_alias',
  NewStatus = 'new_status',
  EditBatchUser = 'users_edit',
  NewLocationConfig = 'new_location_config',
  EditDomainSettings = 'editDomainSettings',
  Tassta = 'tassta',
  EditUserSettings = 'editUserSettings',
  ServiceMCData = 'service_mcdata',
  ServiceMCPTT = 'service_mcptt',
  ServieMCVideo = 'service_mcvideo',
  Initital = 'initial',
  UEMCData = 'ue_mcdata',
  UEMCPTT = 'ue_mcptt',
  UEMCVideo = 'ue_mcvideo',
}

export enum TriggerTypesEnum {
  GeographicalArea = 'GeographicalAreaChange',
  PeriodicReport = 'PeriodicReport',
  TraveledDistance = 'TraveledDistance',
  MCPTTSignalEvent = 'MCPTTSignalEvent',
}

export enum GeographicalAreaChangeTypeEnum {
  Any = 'AnyAreaChange',
  Enter = 'EnterSpecificArea',
  Exit = 'ExitSpecificArea',
}

export enum MCPTTSignalEventTypeEnum {
  FunctionalAliasActivation = 'functional_alias_activation',
  FunctionalAliasDeactivation = 'functional_alias_deactivation',
  LocationConfigurationReceived = 'configuration_received',
  GroupCall = 'group_call',
  PrivateCall = 'private_call',
}

export enum BackupEvents {
  BACKUPING = 'BACKUPING',
  BACKUPED = 'BACKUPED',
  RESTORING = 'RESTORING',
  RESTORED = 'RESTORED',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOADED = 'DOWNLOADED',
}

export enum BackupStatus {
  BACKUPING = 'BACKUPING',
  OK = 'OK',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
}

export enum EBackupSubTab {
  Current = 'current_tab',
  Deleted = 'deleted_tab',
}

export enum EBackupModalMode {
  Delete = 'delete',
  Restore = 'restore',
  Create = 'create',
  Download = 'download',
  Upload = 'upload',
}

export enum EBackupPeriod {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export const LocalDomainName = 'domain_list';

/** Do NOT change values in this enum. They are the same as we got them from api for form schemes. (but lowercased) */
/** Any change will affect a display status of widgets */
export enum EFormikWidgets {
  GROUP_FUNCTIONAL_ALIASES = 'functionalaliases',
  ORDERED_LIST = 'orderedlist',
  USER = 'user',
  USERS = 'users',
  USERS_WITH_FLEET = 'userswithfleet',
  USERS_WITH_SELF = 'userswithself',
  GROUP = 'group',
  GROUPS = 'groups',
  GROUP_USERS = 'groupusers',
  CUSTOM_USERS = 'customusers',
  ROLES = 'roles',
  GROUP_ENHANCED_STATUSES = 'groupenhancedstatuses',
  COLOR = 'color',
  FLEET_USERS = 'fleetusers',
  LMR_CONFIGURATION = 'lmrconfiguration',
  CALL_FORWARD = 'callforward',
}

export enum ModalDataTarget {
  DELETE = 'delete',
  RELOAD = 'reload',
  DISABLE_RELOAD = 'disable-reload',
  GET_QR = 'get-qr',
  SET_PRIVATE = 'set-private',
  RESET_PRIVATE = 'reset-private',
  EMPTY = '',
}

export enum FileExtensions {
  ZIP = 'zip',
  PDF = 'pdf',
}

export enum ResponseTypes {
  ArrayBuffer = 'arraybuffer',
  Blob = 'blob',
  FormData = 'formdata',
  Json = 'json',
  Text = 'text',
  Stream = 'stream',
}

export enum UsersRoles {
  USER = 'USER',
  RECORD_VIEWER = 'RECORDVIEWER',
  DISPATCHER = 'DISPATCHER',
  VIEWER = 'VIEWER',
  PERFORMER = 'PERFORMER',
  SUPERVISOR = 'SUPERVISOR',
  ADMIN = 'ADMIN',
}

export enum DaysOfWeek {
  SUN,
  MON,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
}

export enum CallType {
  PRIVATE = 'private',
  FIRST_TO_ANSWER = 'first-to-answer',
  PREARRANGED = 'prearranged',
  CHAT = 'chat',
  AMBIENT_LISTENING = 'ambient-listening',
  AMBIENT_LISTENING_REMOTE = 'ambient-listening-remote',
  MCVIDEO_PRIVATE = 'mcvideo-private',
  MCVIDEO_PREARRANGED = 'mcvideo-prearranged',
  MCVIDEO_CHAT = 'mcvideo-chat',
  AMBIENT_VIEWING = 'ambient-viewing',
  ONE_TO_SERVER_VIDEO_PUSH = 'one-to-server video push',
  ONE_TO_ONE_VIDEO_PUSH = 'one-to-one video push',
  ONE_TO_SERVER_VIDEO_PULL = 'one-from-server video pull',
  ONE_TO_ONE_VIDEO_PULL = 'one-to-one video pull',
}

export enum NotificationObjectType {
  MCDATA_PROFILE = 'MCDATA_PROFILE',
  MCPTT_PROFILE = 'MCPTT_PROFILE',
  MCVIDEO_PROFILE = 'MCVIDEO_PROFILE',
  ADVANCED_USER_SETTINGS = 'ADVANCED_USER_SETTINGS',
}

export enum NotificationEvent {
  RECORDING_RESULT = 'recording-result',
}

export enum RecordPreparingState {
  IN_PROGRESS = 'in-progress',
  SUCCESS = 'success',
  FAIL = 'fail',
  NONE = 'none',
}

export enum LocalStorageKey {
  CLIENT_CORRELATOR = 'client_correlator',
}

export enum VersionManagementModalMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum UploadingState {
  NONE = 'none',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  STOPPED = 'stopped',
  ERROR = 'error',
}

export enum CallStatisticsRange {
  LAST_HOUR = 'call_hist_filter_last_1_hour',
  TODAY = 'call_hist_filter_today',
  LAST_24_HOURS = 'call_hist_filter_last_24_hours',
  LAST_7_DAYS = 'call_hist_filter_last_7_days',
  LAST_30_DAYS = 'call_hist_filter_last_30_days',
  LAST_90_DAYS = 'call_hist_filter_last_90_days',
}

export enum StatisticsMetric {
  ACAPS = 'acaps',
  ACD = 'acd',
  ASR = 'asr',
  CAPS = 'caps',
  CONGESTION = 'congestion',
}

export enum CallStatisticDataType {
  MINUTELY = 'minutely',
  HOURLY = 'hourly'
}