import {
  ESignalingMessageType,
  TimeMarker,
  filterItemContent,
} from 'constants/callHistory/callHistory';
import { List, Map } from 'immutable';
import moment from 'moment';
import { createSelector } from 'reselect';
import { ReduxStore } from 'store';
import { CallHistoryStoreField, RecordProcessing } from 'store/callHistory';
import {
  ICallHistory,
  ICallHistoryFilters,
  ICallParticipant,
} from 'store/callHistory/actions';
import { User } from 'store/users';

const callHistorySection = (store: ReduxStore) => store.callHistory;
const othersSection = (store: ReduxStore) => store.others;
const usersSection = (store: ReduxStore) => store.users;
const domainProp = (_: ReduxStore, domain: string) => domain;
const sessionDataProp = (
  _: ReduxStore,
  sessionData: { sessionId: string; domain: string },
) => sessionData;

export const getCurrentCallHistorySelector = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) =>
    callHistory.getIn(
      [others.currentDomain, CallHistoryStoreField.DATA],
      List([]),
    ) as List<ICallHistory>,
);

export const getFilteredCallHistorySelector = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) => {
    const filters = callHistory.getIn([
      others.currentDomain,
      'filters',
    ]) as ICallHistoryFilters;

    return (
      callHistory.getIn(
        [others.currentDomain, CallHistoryStoreField.DATA],
        List([]),
      ) as List<ICallHistory>
    )
      .filter((hist) => {
        if (filters?.callType) {
          return hist.sessionType === filters?.callType;
        }

        return true;
      })
      .filter((hist) => {
        if (filters?.calledParty) {
          return hist.cdpns[0] === filters?.calledParty;
        }

        return true;
      })
      .filter((hist) => {
        if (filters?.callingParty) {
          return hist.cgpn === filters?.callingParty;
        }

        return true;
      })
      .filter((hist) => {
        if (filters?.disposition) {
          return hist.disposition === filters?.disposition;
        }

        return true;
      })
      .filter((hist) => {
        if (filters?.duration) {
          const startedAt = moment(hist.startedAt);
          const endedAt = moment(hist.endedAt);
          const duration = endedAt.diff(startedAt);

          if (
            duration === 0 &&
            filters?.duration[0].format('HH:mm:ss') === '00:00:00'
          ) {
            return true;
          }

          const date = moment().startOf('day').add(duration);

          return date.isBetween(
            filters?.duration[0],
            filters?.duration[1],
            undefined,
            '[]',
          );
        }

        return true;
      });
  },
);

export const getCurrentCallDetails = createSelector(
  [callHistorySection, getFilteredCallHistorySelector, othersSection],
  (callHistory, filteredCallHistoryList, others) => {
    const id = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.SELECTED_CALL_DETAILS_ID,
    ]) as string | null;

    return filteredCallHistoryList.find((value) => value.id === id) ?? null;
  },
);

export const getSelectedWriterSessionId = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) =>
    callHistory.getIn(
      [others.currentDomain, CallHistoryStoreField.SELECTED_WRITER_SESSION_ID],
      null,
    ) as string | null,
);

export const getSelectedCallDetailsId = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) =>
    callHistory.getIn(
      [others.currentDomain, CallHistoryStoreField.SELECTED_CALL_DETAILS_ID],
      null,
    ) as string | null,
);

export const getHighlightedWriterSessionId = createSelector(
  [callHistorySection, domainProp],
  (callHistory, domain) =>
    callHistory.getIn(
      [domain, CallHistoryStoreField.HIGHLIGHTED_WRITER_SESSION_ID],
      null,
    ) as string | null,
);

export const getSignalingDataSelector = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) => {
    const callDetailsId = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.SELECTED_CALL_DETAILS_ID,
    ]) as string | null;

    const writerSessionId = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.SELECTED_WRITER_SESSION_ID,
    ]) as string | null;

    const index = (
      callHistory.getIn([
        others.currentDomain,
        CallHistoryStoreField.DATA,
      ]) as List<ICallHistory>
    )?.findKey((hist) => hist.id === callDetailsId);

    if (index !== undefined) {
      const participant = (
        callHistory.getIn([
          others.currentDomain,
          CallHistoryStoreField.DATA,
          index,
          CallHistoryStoreField.PARTICIPANTS,
        ]) as ICallParticipant[]
      )?.find((p) => p.writerSessionId === writerSessionId) as ICallParticipant;

      if (participant) {
        return participant.signalingData;
      }
    }

    return undefined;
  },
);

export const getVideoSessionUserInfo = createSelector(
  [callHistorySection, othersSection, usersSection],
  (callHistory, others, users) => {
    const callDetailsId = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.SELECTED_CALL_DETAILS_ID,
    ]) as string | null;

    const writerSessionId = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.SELECTED_WRITER_SESSION_ID,
    ]) as string | null;

    const index = (
      callHistory.getIn([
        others.currentDomain,
        CallHistoryStoreField.DATA,
      ]) as List<ICallHistory>
    )?.findKey((hist) => hist.id === callDetailsId);

    if (index !== undefined) {
      const participant = (
        callHistory.getIn([
          others.currentDomain,
          CallHistoryStoreField.DATA,
          index,
          CallHistoryStoreField.PARTICIPANTS,
        ]) as ICallParticipant[]
      )?.find((p) => p.writerSessionId === writerSessionId) as ICallParticipant;

      const section = users.get(others.currentDomain) as List<User>;

      if (participant && section) {
        return (
          section.find((user) => participant.cdpn === user.mcsId) ||
          section.find((user) => participant.cgpn === user.mcsId)
        );
      }
    }

    return undefined;
  },
);

export const getSessionTimeMarkers = createSelector(
  [getSignalingDataSelector, othersSection, usersSection],
  (signalingData, others, users) => {
    const markers: TimeMarker[] =
      (signalingData?.packets ?? [])
        .filter((p) =>
          Object.values(ESignalingMessageType).includes(p.message_type),
        )
        .map((packet) => {
          const userId =
            packet.message?.granted_party_identity ?? packet.message?.user_id;
          let userName = '';

          if (userId) {
            userName =
              users
                .get(others.currentDomain)
                ?.find((user) => user.mcsId === userId)?.name ?? '';
          }

          return {
            key: packet.received_timestamp,
            time: packet.miliseconds / 1000,
            type: packet.message_type,
            text: filterItemContent[packet.message_type],
            extraText: userName,
          };
        }) ?? [];

    return markers;
  },
);

export const getRecordProcessingData = createSelector(
  [callHistorySection, othersSection],
  (callHistory, others) => {
    const recordProcessing = callHistory.getIn([
      others.currentDomain,
      CallHistoryStoreField.RECORD_PROCESSING,
    ]) as Map<'string', RecordProcessing>;

    return recordProcessing ? recordProcessing.toObject() : {};
  },
);

export const getUserByWriterSessionId = createSelector(
  [callHistorySection, usersSection, sessionDataProp],
  (callHistory, users, sessionData) => {
    const data = callHistory.getIn([
      sessionData.domain,
      CallHistoryStoreField.DATA,
    ]) as ICallHistory[];

    if (data) {
      const callHistoryItem = data.find((item) =>
        item.participants?.some(
          (prtp) => prtp.writerSessionId === sessionData.sessionId,
        ),
      );

      if (callHistoryItem) {
        const participant = callHistoryItem?.participants?.find(
          (prtp) => prtp.writerSessionId === sessionData.sessionId,
        );

        if (participant) {
          const usersStore = users.get(sessionData.domain)?.toArray() ?? [];

          const user =
            usersStore.find((us) => participant.cdpn === us.mcsId) ||
            usersStore.find((us) => participant.cgpn === us.mcsId);

          return user ?? null;
        }
      }
    }

    return null;
  },
);
