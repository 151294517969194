import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'static/css/theme_light.less';
import 'static/css/theme_dark.less';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'helpers/client';
import 'helpers/mapBox';

import './i18n/config';

store.subscribe(() => {
  const state = store.getState();
  const domains = state.domains.toJS();
  const users = state.users.toJS();
  const groups = state.groups.toJS();
  const roles = state.roles.toJS();
  const { self, schemes } = state;
  const defValuesForSchemes = state.defValues;

  localStorage.setItem('mc-store-domains', JSON.stringify(domains));
  localStorage.setItem('mc-store-users', JSON.stringify(users));
  localStorage.setItem('mc-store-groups', JSON.stringify(groups));
  localStorage.setItem('mc-store-roles', JSON.stringify(roles));
  localStorage.setItem('mc-store-self', JSON.stringify(self));
  localStorage.setItem('mc-store-schemes', JSON.stringify(schemes));
  localStorage.setItem('mc-store-values', JSON.stringify(defValuesForSchemes));
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById('root'),
);

serviceWorker.unregister();
