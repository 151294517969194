import { AnyAction } from 'redux';
import { StoreActionType } from 'constants/storeActionType';

export type Tab = {
  title: string;
  content: any;
  key: string;
  isChanged: boolean;
  changedSubTabs: string[];
};

export type StoreTabs = Tab[];

const initialState = (): Tab[] => [];

interface AddTab extends AnyAction {
  type: StoreActionType.ADD_TAB;
  data: Tab;
}

interface DeleteTab extends AnyAction {
  type: StoreActionType.DELETE_TAB;
  tabName: string;
}

interface ChangeTab extends AnyAction {
  type: StoreActionType.CHANGE_TAB;
  tabName: string;
  isChanged: boolean;
  subTabName?: string;
}

interface ClearTabs extends AnyAction {
  type: StoreActionType.CLEAR_TABS;
}

export type StoreTabsActions = AddTab | DeleteTab | ClearTabs | ChangeTab;

export default function tabs(
  store: StoreTabs = initialState(),
  action: StoreTabsActions,
) {
  switch (action.type) {
    case StoreActionType.ADD_TAB: {
      const copyStore = [...store];
      copyStore.push(action.data);
      return copyStore;
    }
    case StoreActionType.DELETE_TAB: {
      const copyStore = [...store];
      return copyStore.filter((tab) => tab.key !== action.tabName);
    }
    case StoreActionType.CHANGE_TAB: {
      return store.map((item) => {
        const temp = { ...item };
        if (temp.key === action.tabName) {
          if (action.subTabName) {
            const subTabExist = temp.changedSubTabs?.find(
              (subTab) => subTab === action.subTabName,
            );

            if (action.isChanged && !subTabExist) {
              temp.changedSubTabs?.push(action.subTabName);
              temp.isChanged = true;
            }

            if (!action.isChanged && subTabExist) {
              temp.changedSubTabs = temp.changedSubTabs?.filter(
                (stub) => stub !== action.subTabName,
              );

              if (!temp.changedSubTabs.length) {
                temp.isChanged = false;
              }
            }

            return temp;
          }

          temp.isChanged = action.isChanged;
        }

        return temp;
      });
    }
    case StoreActionType.CLEAR_TABS: {
      return [];
    }
    default: {
      return store;
    }
  }
}
