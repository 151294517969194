import { AnyAction } from 'redux';
import { Map, List } from 'immutable';
import { StoreActionType } from 'constants/storeActionType';

export type StoreRoles = Map<string, List<Role>>;

export type Role = {
  name: string;
  title: string;
  priority: number;
};

const initialState = (): Map<string, List<Role>> => Map({});

interface GetRoles extends AnyAction {
  type: StoreActionType.GET_ROLES;
  domain: string;
  data: Role[];
}

interface ResetRoles extends AnyAction {
  type: StoreActionType.RESET_ROLES;
}

export type StoreRolesActions = GetRoles | ResetRoles;

export default function roles(
  store: StoreRoles = initialState(),
  action: StoreRolesActions,
) {
  switch (action.type) {
    case StoreActionType.GET_ROLES: {
      return store.set(
        action.domain,
        List(action.data.sort((a, b) => b.priority - a.priority)),
      );
    }
    case StoreActionType.RESET_ROLES: {
      return initialState();
    }
    default: {
      return store;
    }
  }
}
