import { toLocaleString } from 'helpers/translator';

export interface IQuickRangeFilter<T> {
  key: T;
  text: string;
}

export const getQuickRangeFiltersColumn1 =
  (): IQuickRangeFilter<TKeysColumn1>[] => [
    {
      key: 'Today',
      text: toLocaleString('call_hist_filter_today'),
    },
    {
      key: 'This week',
      text: toLocaleString('call_hist_filter_this_week'),
    },
    {
      key: 'This month',
      text: toLocaleString('call_hist_filter_this_month'),
    },
    {
      key: 'This year',
      text: toLocaleString('call_hist_filter_this_year'),
    },
    {
      key: 'Today so far',
      text: toLocaleString('call_hist_filter_today_so_far'),
    },
    {
      key: 'Week to date',
      text: toLocaleString('call_hist_filter_week_to_date'),
    },
    {
      key: 'Month to date',
      text: toLocaleString('call_hist_filter_month_to_date'),
    },
    {
      key: 'Year to date',
      text: toLocaleString('call_hist_filter_year_to_date'),
    },
  ];

enum EColumn1 {
  'Today',
  'This week',
  'This month',
  'This year',
  'Today so far',
  'Week to date',
  'Month to date',
  'Year to date',
}

export const getQuickRangeFiltersColumn2 =
  (): IQuickRangeFilter<TKeysColumn2>[] => [
    {
      key: 'Last 15 minutes',
      text: toLocaleString('call_hist_filter_last_15_minutes'),
    },
    {
      key: 'Last 30 minutes',
      text: toLocaleString('call_hist_filter_last_30_minutes'),
    },
    {
      key: 'Last 1 hour',
      text: toLocaleString('call_hist_filter_last_1_hour'),
    },
    {
      key: 'Last 4 hours',
      text: toLocaleString('call_hist_filter_last_4_hours'),
    },
    {
      key: 'Last 12 hours',
      text: toLocaleString('call_hist_filter_last_12_hours'),
    },
    {
      key: 'Last 24 hours',
      text: toLocaleString('call_hist_filter_last_24_hours'),
    },
    {
      key: 'Last 7 days',
      text: toLocaleString('call_hist_filter_last_7_days'),
    },
  ];

enum EColumn2 {
  'Last 15 minutes',
  'Last 30 minutes',
  'Last 1 hour',
  'Last 4 hours',
  'Last 12 hours',
  'Last 24 hours',
  'Last 7 days',
}

export const getQuickRangeFiltersColumn3 =
  (): IQuickRangeFilter<TKeysColumn3>[] => [
    {
      key: 'Last 30 days',
      text: toLocaleString('call_hist_filter_last_30_days'),
    },
    {
      key: 'Last 60 days',
      text: toLocaleString('call_hist_filter_last_60_days'),
    },
    {
      key: 'Last 90 days',
      text: toLocaleString('call_hist_filter_last_90_days'),
    },
    {
      key: 'Last 6 months',
      text: toLocaleString('call_hist_filter_last_6_months'),
    },
    {
      key: 'Last 1 year',
      text: toLocaleString('call_hist_filter_last_1_year'),
    },
    {
      key: 'Last 2 years',
      text: toLocaleString('call_hist_filter_last_2_years'),
    },
    {
      key: 'Last 5 years',
      text: toLocaleString('call_hist_filter_last_5_years'),
    },
  ];

enum EColumn3 {
  'Last 30 days',
  'Last 60 days',
  'Last 90 days',
  'Last 6 months',
  'Last 1 year',
  'Last 2 years',
  'Last 5 years',
}

type TKeysColumn1 = keyof typeof EColumn1;
type TKeysColumn2 = keyof typeof EColumn2;
type TKeysColumn3 = keyof typeof EColumn3;

export type TKeysTimeRange =
  | TKeysColumn1
  | TKeysColumn2
  | TKeysColumn3
  | 'Absolute';

export enum EDefaultTypes {
  TODAY = 'Today',
  ABSOLUTE = 'Absolute',
}
export const maxCountOfTableRecords = 500;
export const getAbsoluteValue = (): IQuickRangeFilter<TKeysTimeRange> => ({
  key: 'Absolute',
  text: toLocaleString('absolute_tab'),
});

export interface ISignalingData {
  packets: {
    message: {
      ssrc: number;
      message_type?: string;
      source?: string;
      indicator?: string[];
      location?: {};
      duration?: number;
      granted_priority?: number;
      granted_ssrc?: number;
      ack?: boolean;
      message_sequence_number?: number;
      user_id?: string;
      granted_party_identity?: string;
    };
    message_class: "MCPT";
    message_type: ESignalingMessageType;
    miliseconds: number;
    position: number;
    received_timestamp: string;
    rtcp_ssrc: number;
  }[];
}

export enum ESignalingMessageType {
  REQUEST = "Request",
  GRANTED = "Granted",
  RELEASE = "Release",
  TAKEN = "Taken",
  QUEUE = "QueuePositionInfo",
  DENY = "Deny",

  /* Specials for PTV */
  END_REQUEST = "EndRequest",
  END_RESPONSE = "EndResponse",
  RECEIVE_MEDIA_REQUEST = "ReceiveMediaRequest",
  RECEIVE_MEDIA_RESPONSE = "ReceiveMediaResponse",
  MEDIA_TRANSMISSION_NOTIFIY = "MediaTransmissionNotifiy",
  MEDIA_RECEPTION_NOTIFIY = "MediaReceptionNotifiy",
}

export const filterItemContent = {
  [ESignalingMessageType.REQUEST]: 'Request',
  [ESignalingMessageType.RELEASE]: 'Release',
  [ESignalingMessageType.GRANTED]: 'Granted',
  [ESignalingMessageType.TAKEN]: 'Taken',
  [ESignalingMessageType.QUEUE]: 'Queue',
  [ESignalingMessageType.DENY]: 'Deny',
  [ESignalingMessageType.END_REQUEST]: 'End request',
  [ESignalingMessageType.END_RESPONSE]: 'End response',
  [ESignalingMessageType.RECEIVE_MEDIA_REQUEST]: 'Receive media request',
  [ESignalingMessageType.RECEIVE_MEDIA_RESPONSE]: 'Receive media response',
  [ESignalingMessageType.MEDIA_TRANSMISSION_NOTIFIY]: 'Media transmission notifiy',
  [ESignalingMessageType.MEDIA_RECEPTION_NOTIFIY]: 'Media reception notifiy',
}

export type TimeMarker = {
  time: number;
  type: ESignalingMessageType;
  duration?: number;
  text?: string;
  class?: string;
  extraText?: string;
  key: string;
};

export type TimeLabel = {
  time: number;
  key: string;
};


export enum EVideoJSEvents {
  CAN_PLAY = 'canplay',
  TIME_UPDATE = 'timeupdate',
  READY = 'ready',
  PLAYING = 'playing',
  PAUSE = 'pause',
  PLAY = 'play'
}