import { AnyAction } from 'redux';
import { List } from 'immutable';
import { Modes } from 'store/actions/domainConfigController';
import { StoreActionType } from 'constants/storeActionType';

export type StoreDomains = List<Domain>;

export type Scheme = {
  type: string;
  id: string;
  title?: string;
  [key: string]: any;
};

export interface ITasstaMapConfig {
  value: {
    name: string;
    title: string;
    mapAndTracking: {
      allowMap: boolean;
      mapboxAccessTokenStar: string;
      mapboxAccessTokenGalaxy: string;
    };
  };
  scheme?: Scheme;
}

export type Config = {
  value?: any;
  scheme?: Scheme;
};

export type Domain = {
  configs?: {
    initial?: Config;
    tassta?: ITasstaMapConfig;
    service_mcdata?: Config;
    service_mcptt?: Config;
    service_mcvideo?: Config;
    ue_mcdata?: Config;
    ue_mcptt?: Config;
  };
  name: string;
  system: boolean;
  title: string;
  usersPriorities?: object;
  server_id: number;
};

export type DomainName = {
  domain: string;
};

interface PushDomains extends AnyAction {
  type: StoreActionType.PUSH_DOMAINS;
  data: Domain[];
}

interface AddDomain extends AnyAction {
  type: StoreActionType.ADD_DOMAIN;
  domain: string;
  serverId: number;
}

interface UpdateDomain extends AnyAction {
  type: StoreActionType.UPDATE_DOMAIN;
  domain: string;
}

interface DeleteDomain extends AnyAction {
  type: StoreActionType.DELETE_DOMAIN;
  domain: string;
}

interface SetUsersPriorities extends AnyAction {
  type: StoreActionType.SET_USERS_PRIORITIES;
  domain: string;
  usersPriorities: object;
}

interface PushConfig extends AnyAction {
  type: StoreActionType.PUSH_CONFIG;
  data: {
    domain: string;
    mode: Modes;
    config: Config | ITasstaMapConfig;
  };
}

interface UpdateConfig extends AnyAction {
  type: StoreActionType.UPDATE_CONFIG;
  data: {
    domain: string;
    mode: Modes;
    config: Config | ITasstaMapConfig;
  };
}

interface ClearSchemeConfigs extends AnyAction {
  type: StoreActionType.CLEAR_CONFIGS;
  data: {
    domain: string;
  };
}

interface ResetDomains extends AnyAction {
  type: StoreActionType.RESET_DOMAINS;
}

export type StoreDomainsActions =
  | PushDomains
  | AddDomain
  | UpdateDomain
  | DeleteDomain
  | SetUsersPriorities
  | PushConfig
  | UpdateConfig
  | ResetDomains
  | ClearSchemeConfigs;

const initialStore = () => List([]);

export default function domains(
  store: StoreDomains = initialStore(),
  action: StoreDomainsActions,
) {
  switch (action.type) {
    case StoreActionType.PUSH_DOMAINS: {
      return List(action.data);
    }

    case StoreActionType.ADD_DOMAIN: {
      return store.push({
        name: action.domain,
        system: false,
        title: action.title,
        server_id: action.serverId,
      });
    }

    case StoreActionType.UPDATE_DOMAIN: {
      return store.map((domain) => {
        const copyDomain = { ...domain };
        copyDomain.system = false;

        if (copyDomain.name === action.domain) {
          copyDomain.name = action.domain;
          copyDomain.system = action.system;
          copyDomain.title = action.title;
        }

        return copyDomain;
      });
    }

    case StoreActionType.SET_USERS_PRIORITIES: {
      return store.map((domain) => {
        const copyDomain = { ...domain };

        if (copyDomain.name === action.domain) {
          copyDomain.usersPriorities = { ...action.usersPriorities };
        }
        return copyDomain;
      });
    }

    case StoreActionType.DELETE_DOMAIN: {
      const index = store.findKey((item) => item.name === action.domain);
      if (index !== undefined) {
        return store.delete(index);
      }
      return store;
    }

    case StoreActionType.PUSH_CONFIG: {
      return store.map((domain) => {
        const copyDomain = { ...domain };

        if (copyDomain.name === action.data.domain) {
          if (copyDomain.configs) {
            if (action.data.mode === 'tassta') {
              copyDomain.configs.tassta = action.data
                .config as ITasstaMapConfig;
            } else {
              copyDomain.configs[action.data.mode] = action.data.config;
            }
          } else {
            copyDomain.configs = {
              [action.data.mode]: action.data.config,
            };
          }
        }
        return copyDomain;
      });
    }

    case StoreActionType.UPDATE_CONFIG: {
      return store.map((domain) => {
        const copyDomain = { ...domain };

        if (copyDomain.name !== action.data.domain) {
          return copyDomain;
        }

        if (copyDomain.configs) {
          copyDomain.configs[action.data.mode]!.value =
            action.data.config.value;
        } else {
          copyDomain.configs = {
            [action.data.mode]: action.data.config,
          };
        }

        return copyDomain;
      });
    }

    case StoreActionType.CLEAR_CONFIGS: {
      return store.map((domain) => {
        const copyDomain = { ...domain };

        if (copyDomain.name !== action.data.domain) {
          return copyDomain;
        }

        if (copyDomain.configs) {
          delete copyDomain.configs;
        }

        return copyDomain;
      });
    }

    case StoreActionType.RESET_DOMAINS: {
      return initialStore();
    }

    default: {
      return store;
    }
  }
}
