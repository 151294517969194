import { IFunctionalAlias } from 'constants/interfaces/IFunctionalAliases';
import { StoreActionType } from 'constants/storeActionType';
import { List, Map } from 'immutable';
import { SetFunctionalAliases } from './actions';

export type StoreFunctionalAliases = Map<string, List<IFunctionalAlias>>;

export type StoreFunctionalAliasesActions = SetFunctionalAliases;

const initialState = (): StoreFunctionalAliases => Map({});

const functionalAliases = (
  store: StoreFunctionalAliases = initialState(),
  action: StoreFunctionalAliasesActions,
) => {
  switch (action.type) {
    case StoreActionType.SET_FUNCTIONAL_ALIASES: {
      return store.set(action.domain, List(action.data));
    }

    default: {
      return store;
    }
  }
};

export default functionalAliases;
