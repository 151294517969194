export interface IWSUserData {
  authorizedServices: string[];
  connectionState: EConnection;
  expiredAt: number;
  userId: string;
}

export enum EConnection {
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED'
}
