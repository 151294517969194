import { AnyAction } from 'redux';
import { StoreActionType } from 'constants/storeActionType';
import { ISignalingData } from 'constants/callHistory/callHistory';
import { RecordPreparingState } from 'constants/commonEnum';
import { TCauseCodes } from 'constants/callHistory/CauseCodes';
import { Moment } from 'moment';

export interface ICallHistoryRangeTimeFilter {
  dateFrom: number;
  dateTo: number;
  quickTitle?: string;
  key?: string;
}

enum CallHistoryField {
  PARTICIPANTS = 'participants',
  CDPNS = 'cdpns',
}

export interface ICallHistory {
  id: string;
  cgpn: string;
  [CallHistoryField.CDPNS]: string[];
  startedAt: string;
  establishedAt: string;
  endedAt: string;
  sessionType: string;
  disposition: TCauseCodes;
  acknowledged: boolean;
  [CallHistoryField.PARTICIPANTS]?: ICallParticipant[];
}

export interface ICallWriterSession {
  endedAt: string;
  mcsSessionId: string;
  sessionIds: string[];
  startedAt: string;
  userId: string;
}

export interface ICallParticipant
  extends Omit<
    ICallHistory,
    CallHistoryField.PARTICIPANTS | CallHistoryField.CDPNS
  > {
  cdpn: string;
  writerSessionId: string | null;
  signalingData: ISignalingData;
  preparingState?: RecordPreparingState;
}

export interface ICallHistoryFilters {
  callType?: string;
  calledParty?: string;
  callingParty?: string;
  disposition?: TCauseCodes;
  duration?: [Moment, Moment];
}

export enum EPrepareMediaStatus {
  PREPARED = 'PREPARED',
  PREPARING = 'PREPARING',
}

export interface IPrepareMediaContentResponse {
  contentSessionId: string;
  files: string[];
  id: string;
  name: string;
  status: EPrepareMediaStatus;
}

export interface GetCallHistory extends AnyAction {
  type: StoreActionType.GET_CALL_HISTORY;
  domain: string;
  data: ICallHistory[];
}

export interface SetCallHistoryFilters extends AnyAction {
  type: StoreActionType.SET_CALL_HISTORY_FILTERS;
  domain: string;
  filters: ICallHistoryFilters | null;
}

export interface SetCallParticipants extends AnyAction {
  type: StoreActionType.SET_CALL_HISTORY_PARTICIPANTS;
  domain: string;
  id: ICallHistory['id'];
  data: ICallParticipant[];
}

export interface SetCallWriterSessions extends AnyAction {
  type: StoreActionType.SET_CALL_WRITER_SESSIONS;
  domain: string;
  id: ICallHistory['id'];
  data: ICallWriterSession[];
}

export interface ResetCallHistory extends AnyAction {
  type: StoreActionType.RESET_CALL_HISTORY;
}

export interface SetSignalingData extends AnyAction {
  type: StoreActionType.SET_SIGNALING_DATA;
  domain: string;
  id: ICallHistory['id'];
  writerSessionId: string;
  signalingData: ISignalingData;
}

export interface SelectWriterSessionId extends AnyAction {
  type: StoreActionType.SELECT_WRITER_SESSION_ID;
  domain: string;
  writerSessionId: string | null;
}

export interface SelectCallDetailsId extends AnyAction {
  type: StoreActionType.SELECT_CALL_DETAILS_ID;
  domain: string;
  callDetailsId: string | null;
}

export interface SelectCallDetailsIdByWriterSessionId extends AnyAction {
  type: StoreActionType.SELECT_CALL_DETAILS_ID_BY_WRITER_SESSION_ID;
  domain: string;
  writerSessionId: string | null;
}

export interface SetCallWriterPreparingState extends AnyAction {
  type: StoreActionType.SET_CALL_WRITER_PREPARING_STATE;
  payload: {
    domain: string;
    state: RecordPreparingState;
    writerSessionId: string;
  };
}

export interface SetCallWriterPreparingProgress extends AnyAction {
  type: StoreActionType.PUSH_CALL_WRITER_PREPARING_PROGRESS;
  payload: {
    domain: string;
    writerSessionId: string;
  };
}

export const setSignalingData = (
  domain: string,
  id: ICallHistory['id'],
  writerSessionId: string,
  signalingData: ISignalingData,
): SetSignalingData => ({
  type: StoreActionType.SET_SIGNALING_DATA,
  domain,
  id,
  writerSessionId,
  signalingData,
});

const selectWriterSessionId = (
  domain: string,
  writerSessionId: string | null,
): SelectWriterSessionId => ({
  type: StoreActionType.SELECT_WRITER_SESSION_ID,
  domain,
  writerSessionId,
});

const selectCallDetailsId = (
  domain: string,
  callDetailsId: string | null,
): SelectCallDetailsId => ({
  type: StoreActionType.SELECT_CALL_DETAILS_ID,
  domain,
  callDetailsId,
});

const selectCallDetailsIdByWriterSessionId = (
  domain: string,
  writerSessionId: string | null,
): SelectCallDetailsIdByWriterSessionId => ({
  type: StoreActionType.SELECT_CALL_DETAILS_ID_BY_WRITER_SESSION_ID,
  domain,
  writerSessionId,
});

const setCallWriterPreparingState = (payload: {
  domain: string;
  state: RecordPreparingState;
  writerSessionId: string;
}): SetCallWriterPreparingState => ({
  type: StoreActionType.SET_CALL_WRITER_PREPARING_STATE,
  payload,
});

const pushCallWriterPreparingProgress = (payload: {
  domain: string;
  writerSessionId: string;
}): SetCallWriterPreparingProgress => ({
  type: StoreActionType.PUSH_CALL_WRITER_PREPARING_PROGRESS,
  payload,
});

export const callHistoryActions = {
  selectWriterSessionId,
  selectCallDetailsId,
  selectCallDetailsIdByWriterSessionId,
  setSignalingData,
  setCallWriterPreparingState,
  pushCallWriterPreparingProgress,
};
