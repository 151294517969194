import {
  BackupEvents,
  BackupStatus,
  EBackupPeriod,
} from 'constants/commonEnum';
import { StoreActionType } from 'constants/storeActionType';
import moment from 'moment';
import { AnyAction } from 'redux';

export interface IDatabase {
  name: string;
  size?: string;
}

export interface IBackupEvent {
  backup: string;
  date?: string;
  event: BackupEvents;
  databases?: string[];
  success?: boolean;
  error?: string;
  author?: string;
}

export interface IBackupManagement {
  id: string;
  name?: string;
  size?: number;
  creationDate?: string;
  databases?: IDatabase[];
  events?: IBackupEvent[];
  status?: BackupStatus;
  author?: string;
  fsSaved?: boolean;
  ftpUploaded?: boolean;
}

export interface IBackupSchedule {
  enabled: boolean;
  time: {
    hour: string;
    minute: string;
  };
  date?: string;
  period: EBackupPeriod;
  name?: string;
  createdAt?: string;
  author?: string;
}

export interface IBackupRetention {
  days: number;
  entities: number;
  name?: string;
  createdAt?: string;
  author?: string;
}

export interface IBackupSubscription {
  userId: string;
  events?: BackupEvents[] | undefined | [];
}

export interface IBackupSubscriptions {
  name?: string;
  subscriptions: IBackupSubscription[];
}
interface GetBackups extends AnyAction {
  type: StoreActionType.GET_BACKUPS;
  response: IBackupManagement[];
}

interface CreateBackup extends AnyAction {
  type: StoreActionType.CREATE_BACKUP;
  response: IBackupManagement;
}

interface RestoreBackup extends AnyAction {
  type: StoreActionType.RESTORE_BACKUP;
  response: IBackupManagement;
}

interface GetDatabases extends AnyAction {
  type: StoreActionType.GET_DATABASES;
  response: string[];
}

interface GetBackup extends AnyAction {
  type: StoreActionType.GET_BACKUP;
  response: IBackupManagement;
}

interface DeleteBackup extends AnyAction {
  type: StoreActionType.DELETE_BACKUP;
  backupId: string;
}

interface GetDeletedBackups extends AnyAction {
  type: StoreActionType.GET_DELETED_BACKUPS;
  response: IBackupManagement[];
}

interface ResetBackups extends AnyAction {
  type: StoreActionType.RESET_BACKUPS;
}

interface SetLoadingBackups extends AnyAction {
  type: StoreActionType.SET_BACKUPS_LOADING;
  loading: boolean;
}

interface GetBackupsSchedule extends AnyAction {
  type: StoreActionType.GET_BACKUPS_SCHEDULE;
  response: IBackupSchedule;
}

interface SetBackupsSchedule extends AnyAction {
  type: StoreActionType.SET_BACKUPS_SCHEDULE;
  response: IBackupSchedule;
}

interface GetBackupsScheduleHistory extends AnyAction {
  type: StoreActionType.GET_BACKUPS_SCHEDULE_HISTORY;
  response: IBackupSchedule[];
}

interface GetBackupsRetention extends AnyAction {
  type: StoreActionType.GET_BACKUPS_RETENTION;
  response: IBackupRetention;
}

interface SetBackupsRetention extends AnyAction {
  type: StoreActionType.SET_BACKUPS_RETENTION;
  response: IBackupRetention;
}
interface GetBackupsRetentionHistory extends AnyAction {
  type: StoreActionType.GET_BACKUPS_RETENTION_HISTORY;
  response: IBackupRetention[];
}

interface GetBackupSubscriptions extends AnyAction {
  type: StoreActionType.GET_BACKUP_SUBSCRIPTIONS;
  response: IBackupSubscriptions;
}

interface SetBackupSubscriptions extends AnyAction {
  type: StoreActionType.SET_BACKUP_SUBSCRIPTIONS;
  response: IBackupSubscriptions;
}
interface DeleteBackupSubscriptions extends AnyAction {
  type: StoreActionType.DELETE_BACKUP_SUBSCRIPTIONS;
}

interface PushBackupUsers extends AnyAction {
  type: StoreActionType.PUSH_BACKUP_USERS;
  users: Map<string, string | undefined>;
}

export type StoreBackupManagementActions =
  | GetBackups
  | CreateBackup
  | RestoreBackup
  | GetDatabases
  | GetBackup
  | DeleteBackup
  | GetDeletedBackups
  | ResetBackups
  | SetLoadingBackups
  | GetBackupsSchedule
  | SetBackupsSchedule
  | GetBackupsScheduleHistory
  | GetBackupsRetention
  | SetBackupsRetention
  | GetBackupsRetentionHistory
  | GetBackupSubscriptions
  | SetBackupSubscriptions
  | DeleteBackupSubscriptions
  | PushBackupUsers;

interface IBackupStore {
  loading: boolean;
  backups: IBackupManagement[];
  deleted: IBackupManagement[];
  databases: string[];
  schedule: IBackupSchedule;
  retention: IBackupRetention;
  schedule_history: IBackupSchedule[];
  retention_history: IBackupRetention[];
  subscriptions: IBackupSubscription[];
  users: Map<string, string | undefined>;
}

const backupManagement = (
  store: IBackupStore = {
    loading: false,
    backups: [],
    deleted: [],
    databases: [],
    schedule: <IBackupSchedule>{},
    retention: <IBackupRetention>{},
    schedule_history: [],
    retention_history: [],
    subscriptions: [],
    users: new Map(),
  },
  action: StoreBackupManagementActions,
): IBackupStore => {
  switch (action.type) {
    case StoreActionType.GET_BACKUPS: {
      return {
        ...store,
        loading: false,
        backups: action.response.sort(
          (a, b) =>
            moment(b.creationDate).unix() - moment(a.creationDate).unix(),
        ),
      };
    }

    case StoreActionType.CREATE_BACKUP: {
      return {
        ...store,
        loading: false,
        backups: [action.response].concat(store.backups),
      };
    }

    case StoreActionType.RESTORE_BACKUP: {
      return {
        ...store,
        loading: false,
      };
    }

    case StoreActionType.GET_DATABASES: {
      return {
        ...store,
        loading: false,
        databases: action.response,
      };
    }

    case StoreActionType.GET_BACKUP: {
      return {
        ...store,
        loading: false,
      };
    }

    case StoreActionType.DELETE_BACKUP: {
      return {
        ...store,
        loading: false,
        backups: store.backups.filter((item) => item.id !== action.backupId),
      };
    }

    case StoreActionType.GET_DELETED_BACKUPS: {
      return {
        ...store,
        loading: false,
        deleted: action.response.sort(
          (a, b) =>
            moment(b.creationDate).unix() - moment(a.creationDate).unix(),
        ),
      };
    }

    case StoreActionType.RESET_BACKUPS: {
      return { ...store, backups: [], deleted: [], databases: [] };
    }

    case StoreActionType.SET_BACKUPS_LOADING: {
      return { ...store, loading: action.loading };
    }

    case StoreActionType.GET_BACKUPS_SCHEDULE: {
      return {
        ...store,
        schedule: action.response,
        loading: false,
      };
    }

    case StoreActionType.SET_BACKUPS_SCHEDULE: {
      return {
        ...store,
        schedule: action.response,
        loading: false,
      };
    }

    case StoreActionType.GET_BACKUPS_SCHEDULE_HISTORY: {
      return {
        ...store,
        schedule_history: action.response,
        loading: false,
      };
    }

    case StoreActionType.GET_BACKUPS_RETENTION: {
      return {
        ...store,
        loading: false,
        retention: action.response,
      };
    }

    case StoreActionType.SET_BACKUPS_RETENTION: {
      return {
        ...store,
        loading: false,
        retention: action.response,
      };
    }
    case StoreActionType.GET_BACKUPS_RETENTION_HISTORY: {
      return {
        ...store,
        loading: false,
        retention_history: action.response,
      };
    }
    case StoreActionType.GET_BACKUP_SUBSCRIPTIONS: {
      return {
        ...store,
        loading: false,
        subscriptions: action.response.subscriptions,
      };
    }
    case StoreActionType.SET_BACKUP_SUBSCRIPTIONS: {
      return {
        ...store,
        loading: false,
        subscriptions: action.response.subscriptions,
      };
    }
    case StoreActionType.DELETE_BACKUP_SUBSCRIPTIONS: {
      return {
        ...store,
        loading: false,
        subscriptions: [],
      };
    }

    case StoreActionType.PUSH_BACKUP_USERS: {
      return {
        ...store,
        users: action.users,
      };
    }

    default: {
      return store;
    }
  }
};

export default backupManagement;
