import { LocationConfig } from 'constants/interfaces/ILocationReports';
import { StoreActionType } from 'constants/storeActionType';
import { List, Map } from 'immutable';
import { AnyAction } from 'redux';

export type StoreLocationConfigs = Map<string, List<LocationConfig>>;
interface GetLocationConfig extends AnyAction {
  type: StoreActionType.GET_LOCATION_CONFIGS;
  domain: string;
  data: LocationConfig[];
}

interface ResetLocationConfig extends AnyAction {
  type: StoreActionType.RESET_LOCATION_CONFIGS;
}

interface DeleteLocationConfig extends AnyAction {
  type: StoreActionType.DELETE_LOCATION_CONFIG;
  domain: string;
  idLocationConfig: number;
}

export type StoreLocationConfigsActions =
  | GetLocationConfig
  | DeleteLocationConfig
  | ResetLocationConfig;

const initialState = (): Map<string, List<LocationConfig>> => Map({});

const locationConfigs = (
  store: StoreLocationConfigs = initialState(),
  action: StoreLocationConfigsActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_LOCATION_CONFIGS: {
      return store.set(action.domain, List(action.data));
    }
    case StoreActionType.DELETE_LOCATION_CONFIG: {
      const section = store.get(action.domain);

      if (section) {
        const index = section.findKey(
          (config) => config.configId === action.idLocationConfig,
        );

        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }

        return store;
      }

      return store;
    }

    case StoreActionType.RESET_LOCATION_CONFIGS: {
      return initialState();
    }

    default: {
      return store;
    }
  }
};

export default locationConfigs;
