import { List, Map } from 'immutable';
import { AnyAction } from 'redux';
import { StoreActionType } from 'constants/storeActionType';

export type StoreStatuses = Map<string, List<Status>>;

export interface Status {
  name: number;
  id?: number;
  shortDescription: string;
  description: string;
  color: {
    color: string;
  };
}

interface GetStatuses extends AnyAction {
  type: StoreActionType.GET_STATUSES;
  domain: string;
  data: Status[];
}

interface CreateStatus extends AnyAction {
  type: StoreActionType.CREATE_STATUS;
  domain: string;
}

interface DeleteStatus extends AnyAction {
  type: StoreActionType.DELETE_STATUS;
  domain: string;
  code: string;
}

interface ResetStatuses extends AnyAction {
  type: StoreActionType.RESET_STATUSES;
}

export type StoreStatusesActions =
  | GetStatuses
  | CreateStatus
  | DeleteStatus
  | ResetStatuses;

const initialState = (): Map<string, List<Status>> => Map({});

const statuses = (
  store: StoreStatuses = initialState(),
  action: StoreStatusesActions,
) => {
  switch (action.type) {
    case StoreActionType.GET_STATUSES: {
      return store.set(action.domain, List(action.data));
    }

    case StoreActionType.CREATE_STATUS: {
      return store.set(action.domain, List(action.data));
    }

    case StoreActionType.DELETE_STATUS: {
      const section = store.get(action.domain);
      if (section) {
        const index = section.findKey((item) => +item.name === +action.code);
        if (index !== -1) {
          return store.deleteIn([action.domain, index]);
        }
        return store;
      }
      return store;
    }

    case StoreActionType.RESET_STATUSES: {
      return initialState();
    }

    default: {
      return store;
    }
  }
};

export default statuses;
