import store from 'store/store';
import URIS from 'configs/deploy';
import JwtDecode from 'jwt-decode';
import { ThunkResult } from 'constants/commonTypes';
import { NotificationTypes } from 'components/Notification/Notification';
import { ApiStatusEnum, ResponseTypes } from 'constants/commonEnum';
import { StoreActionType } from 'constants/storeActionType';
import { BASIC_AUTH_TOKEN } from 'constants/common';
import {
  UserData,
  UsersDataCreate,
  UsersChangeSet,
  UsersNames,
  Geofence,
  DatabsesNames,
  MapBoxSubscription,
  CallHistoryIds,
} from 'constants/commonInterfaces';
import { WPGT } from 'constants/MapBox/MapBox';
import {
  IBackupManagement,
  IBackupRetention,
  IBackupSchedule,
  IBackupSubscription,
  IBackupSubscriptions,
  IDatabase,
} from 'store/backupManagement';
import { toLocaleString } from './translator';

/**
 * Done describing types:
 * v backup
 * v user
 * v geofence
 * v mapbox
 * v callHistory
 * - group
 * - ...
 */

const REFRESH_TIME = 180;

type SendData =
  | UserData
  | UserData[]
  | UsersDataCreate[]
  | UsersNames[]
  | UsersChangeSet
  | Geofence
  | WPGT.GuardTourModel
  | IBackupSchedule
  | IBackupRetention
  | IBackupSubscription
  | IBackupSubscriptions
  | IBackupManagement
  | IDatabase[]
  | DatabsesNames
  | MapBoxSubscription
  | CallHistoryIds
  | any; // TODO: To describe request types and remove 'any'

// TODO: To describe response types and replace 'any' in 'Promise<any>'
interface PrivateClient {
  post<T extends SendData>(
    url: string,
    data?: T,
    config?: ClientConfig,
  ): Promise<any>;
  put<T extends SendData>(
    url: string,
    data?: T,
    config?: ClientConfig,
  ): Promise<any>;
  get<T = any>(url: string, config?: ClientConfig): Promise<T>;
  delete(url: string, config?: ClientConfig): Promise<any>;
}

interface Headers {
  [key: string]: string;
}

interface ClientConfig extends Partial<RequestInit> {
  headers?: Headers;
  responseType?: ResponseTypes;
}

const storeLogOut = (): ThunkResult<void> => (dispatch) => {
  dispatch({
    type: StoreActionType.AUTH,
    data: {
      isAuth: false,
      token: '',
      refreshToken: '',
    },
  });
};

const clearAction = () => {
  localStorage.clear();
  storeLogOut();
  window.location.href = URIS.homepage;
};

const refreshTokens = (newAccess: string, newRefresh: string) => {
  store.dispatch({
    type: StoreActionType.AUTH,
    data: { isAuth: true, token: newAccess, refreshToken: newRefresh },
  });
};

const setNewNotification = (viewType: NotificationTypes, message: string) => {
  store.dispatch({
    type: StoreActionType.SET_NEW_NOTIFICATION,
    message,
    viewType,
  });
};

const refreshTokenRequest = async () => {
  try {
    const state = store.getState();
    const { refreshToken } = state.self;
    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    myHeaders.append('Authorization', `Basic ${BASIC_AUTH_TOKEN}`);

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };

    const response = await publicClient(
      `${URIS.publicIdmsUri}/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
      requestOptions,
    );
    const result = await response.json();

    refreshTokens(result.access_token, result.refresh_token);

    return result.access_token;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('refreshTokenRequest err', error);
    clearAction();
  }
};

const publicClient = (url: string, config?: ClientConfig) => {
  try {
    return fetch(url, config);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('publicClient error: ', error);
    return Promise.reject(error);
  }
};

const privateFetch = async (url: string, config?: ClientConfig) => {
  try {
    const initDefaultConfig = async () => {
      const state = store.getState();
      const { token } = state.self;
      const defaultHeaders: Headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      };

      if (!token) {
        clearAction();
        return { headers: defaultHeaders };
      }

      const { exp }: any = JwtDecode(token);

      if (
        exp - REFRESH_TIME <=
        Number(new Date().getTime().toString().substring(0, 10))
      ) {
        const newToken = await refreshTokenRequest();
        Object.assign(defaultHeaders, { Authorization: `Bearer ${newToken}` });
        return { headers: defaultHeaders };
      } else {
        if (token) {
          Object.assign(defaultHeaders, { Authorization: `Bearer ${token}` });
        }
        return { headers: defaultHeaders };
      }
    };

    const { headers: defaultConfigHeader, ...defaultConfigRest } =
      await initDefaultConfig();
    let headersConfig: Headers | undefined;
    let restConfig: Omit<ClientConfig, 'headers'> | undefined;

    if (config) {
      const { headers, ...rest } = config;
      headersConfig = headers;
      restConfig = rest;
    }

    if (headersConfig?.['Content-Type'].includes('multipart/form-data')) {
      delete headersConfig['Content-Type'];
      delete defaultConfigHeader['Content-Type'];
    }

    const customConfig = {
      headers: { ...defaultConfigHeader, ...headersConfig },
      ...defaultConfigRest,
      ...restConfig,
    };

    const response: Response = await window.fetch(url, customConfig);
    const isPlaybackService = url.includes('playback');

    if (!response.ok) {
      const responseData = await response.text();

      switch (response.status) {
        case ApiStatusEnum.UNAUTHORIZED: {
          clearAction();
          break;
        }
        case ApiStatusEnum.TOKEN_EXPIRED: {
          refreshTokenRequest().then(() => {
            window.location.reload();
          });
          break;
        }
        case ApiStatusEnum.FORBIDDEN: {
          if (config?.method?.toUpperCase() !== 'GET') {
            setNewNotification(
              'error',
              toLocaleString('not_enough_permissions'),
            );
          }
          break;
        }
        case ApiStatusEnum.CONFLICT: {
          if (!isPlaybackService) {
            setNewNotification('error', `Conflict: ${responseData}`);
          }
          break;
        }
        case ApiStatusEnum.SERVER_ERROR: {
          setNewNotification('error', toLocaleString('server_error'));
          break;
        }

        case ApiStatusEnum.NOT_FOUND: {
          setNewNotification(
            'error',
            responseData || toLocaleString('not_found'),
          );
          break;
        }
        default: {
          if (response.statusText !== 'Invalid subscription request') {
            setNewNotification(
              'error',
              response.statusText || toLocaleString('something_wrong'),
            );
          }
          break;
        }
      }

      // eslint-disable-next-line no-console
      console.error('exception error: ', response.statusText);

      if (response.statusText === 'Invalid token specified') {
        // eslint-disable-next-line no-console
        console.error('exception error message: ', response.statusText);

        publicClient(`${URIS.publicLogoutUri}`, {
          method: 'POST',
          headers: {
            Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
          .then(() => {
            clearAction();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('LogOut error: ', err);
          });
      }

      return await Promise.reject(response);
    }

    const contentType = response.headers.get('content-type');

    if (config?.responseType === ResponseTypes.ArrayBuffer) {
      return await response.arrayBuffer();
    }

    if (
      contentType?.includes('application') &&
      !contentType?.includes('json')
    ) {
      return await response.blob();
    }

    if (contentType?.includes('text/plain') || !contentType) {
      return await response.text();
    }

    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('privateFetch error: ', error);
    return Promise.reject(error);
  }
};

const privateClient: PrivateClient = {
  get(url, customConfig?) {
    const config = {
      ...customConfig,
      method: 'GET',
    };
    return privateFetch(url, config);
  },
  post(url, data?, customConfig?) {
    const config = {
      ...customConfig,
      method: 'POST',
      body: customConfig?.headers?.['Content-Type'].includes(
        'multipart/form-data',
      )
        ? (data as FormData)
        : JSON.stringify(data),
    };
    return privateFetch(url, config);
  },
  put(url, data, customConfig?) {
    const config = {
      ...customConfig,
      method: 'PUT',
      body: JSON.stringify(data),
    };
    return privateFetch(url, config);
  },
  delete(url, customConfig?) {
    const config = {
      ...customConfig,
      method: 'DELETE',
    };
    return privateFetch(url, config);
  },
};

export { privateClient, storeLogOut, publicClient };
