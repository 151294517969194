import { AnyAction } from 'redux';
import merge from 'lodash/merge';
import { StoreActionType } from 'constants/storeActionType';

type StoreValues = {
  fleet_values?: string[];
  group_values?: any;
  user_values?: string[];
  userProfileValues: any;
};

const initialState = (): StoreValues => ({
  userProfileValues: {},
});

interface SetFleetValues extends AnyAction {
  type: StoreActionType.SET_FLEET_VALUES;
}

interface SetGroupValues extends AnyAction {
  type: StoreActionType.SET_GROUP_VALUES;
  domain: string;
}

interface SetUserValues extends AnyAction {
  type: StoreActionType.SET_USER_VALUES;
  domain: string;
}

interface SetUserProfileValues extends AnyAction {
  type: StoreActionType.SET_USER_PROFILE_VALUES;
  data: any;
  mode: string;
}

interface UpdateUserProfileData extends AnyAction {
  type: StoreActionType.UPDATE_USER_PROFILE_DATA;
  data: any;
  mode: string;
}

interface ResetValues extends AnyAction {
  type: StoreActionType.RESET_VALUES;
}

export type StoreDefValuesActions =
  | SetFleetValues
  | SetGroupValues
  | SetUserValues
  | SetUserProfileValues
  | UpdateUserProfileData
  | ResetValues;

export default function values(
  store: StoreValues = initialState(),
  action: StoreDefValuesActions,
) {
  switch (action.type) {
    case StoreActionType.SET_FLEET_VALUES: {
      const copy = { ...store };
      copy.fleet_values = action.selectedUsers;
      return copy;
    }
    case StoreActionType.SET_GROUP_VALUES: {
      const copy = { ...store };
      copy.group_values = action.data;
      return copy;
    }
    case StoreActionType.SET_USER_VALUES: {
      const copy = { ...store };
      copy.user_values = action.data;
      return copy;
    }
    case StoreActionType.SET_USER_PROFILE_VALUES: {
      return {
        ...store,
        userProfileValues: {
          ...store.userProfileValues,
          [action.mode]: action.data,
        },
      };
    }
    case StoreActionType.UPDATE_USER_PROFILE_DATA: {
      return {
        ...store,
        userProfileValues: {
          ...store.userProfileValues,
          [action.mode]: merge(
            {},
            store.userProfileValues[action.mode],
            action.data,
          ),
        },
      };
    }
    case StoreActionType.RESET_VALUES: {
      return initialState();
    }
    default: {
      return store;
    }
  }
}
